import React, { Component } from "react";
import Select from "react-select";
import "./style.css";
import opportunitiesAPI from "../../../api/opportunitiesAPI";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: true };
    this.onSelectDriver = this.onSelectDriver.bind(this);
  }

  async onSelectDriver(driver, loadId) {
    this.props.setLoading(true);
    const { value } = driver;
    await opportunitiesAPI.assignDriver({ driver_id: value, load_id: loadId });
    await this.props.getLoads();
    this.setState({ edit: false });
    this.props.setLoading(false);
  }

  renderContent = () => {
    const { children, drivers, loadId, disable } = this.props;

    if (this.state.edit && !children) {
      return (
        <Select
          className="custom-form-control-select-drivers _filters select-custom"
          defaultValue={{ label: "Assign driver", value: "2" }}
          onChange={(driver) => this.onSelectDriver(driver, loadId)}
          options={drivers}
        />
      );
    }
    if (this.state.edit && children && disable) {
      return <div>{children}</div>;
    }
    if (this.state.edit && children) {
      return (
        <div
          className="change-name"
          onClick={() => {
            this.setState({ edit: !this.state.edit });
          }}
        >
          {children}
        </div>
      );
    }
    if (!this.state.edit && children) {
      return (
        <Select
          className="custom-form-control-select-drivers _filters select-custom"
          defaultValue={{ label: "Assign driver", value: "2" }}
          onChange={(driver) => this.onSelectDriver(driver, loadId)}
          options={drivers}
        />
      );
    }
  };

  render() {
    return <>{this.renderContent()}</>;
  }
}

export default CustomSelect;
