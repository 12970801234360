export const sortByPriority = loads => {
  const dispatcherId = JSON.parse(localStorage.getItem("userInfo")).id;
  let dealerLoads = [];
  let dealerRushLoads = [];
  let dispatcherNewLoads = [];
  let loadWithoutPriority = [];
  let dispatcherAssignedLoads = [];
  let otherRushLoads = [];

  loads.forEach(load => {
    if (load.user_id !== dispatcherId) {
      if (load.priority && load.priority.toLowerCase() === "rush") {
        dealerRushLoads.push(load);
      } else {
        dealerLoads.push(load);
      }
    } else if (!load.priority) {
      loadWithoutPriority.push(load);
    } else if (load.priority && load.priority.toLowerCase() === "rush") {
      otherRushLoads.push(load);
    } else if (load.driver_id || load.dispatcher_id) {
      dispatcherAssignedLoads.push(load);
    } else {
      dispatcherNewLoads.push(load);
    }
  });

  const sortedLoads = [
    ...dealerLoads,
    ...dispatcherAssignedLoads,
    ...dispatcherNewLoads
  ].sort((a, b) => Number(a.priority) - Number(b.priority));

  return [...dealerRushLoads, ...otherRushLoads, ...sortedLoads, ...loadWithoutPriority];
};
