import axios from 'axios';

const fetchRandomVehicleImage = async ({
  year,
  make,
  model,
  color,
  type,
  vin,
}) => {
  const apiKey = 'NL5CEwmU4tevxcyuBdivmOFVbAMw9iX9IHvdxrWdBZ9DZ3q6F1h7tQkp'; // Your Pexels API key

  try {
    const response = await axios.get(
      `https://api.pexels.com/v1/search?query=${"car"} ${"vehicle"} ${color || ''} ${year || ''} ${make || ''} ${model || ''} ${type || ''}&per_page=10`,
      {
        headers: {
          Authorization: apiKey,
        },
      }
    );

    const { photos } = response.data;

    // Filter out photos where height is greater than width
    const filteredPhotos = photos.filter(photo => photo.height <= photo.width);

    // If no valid photos found, return null
    if (!filteredPhotos.length) {
      return null;
    }

    // Generate a consistent index based on the VIN
    const vinHash = Array.from(vin).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );
    const photoIndex = vinHash % filteredPhotos.length;

    // Get the selected photo's 'small' image URL
    const selectedPhoto = filteredPhotos[photoIndex].src.small;

    return selectedPhoto;
  } catch (error) {
    console.error('Error fetching vehicle image:', error);
    return null;
  }
};

export { fetchRandomVehicleImage };