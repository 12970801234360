export const getPaymentTerm = (paymentTerm) => {
    switch (paymentTerm) {
      case 0:
        return "";
      case 5:
        return "Billing";
      case 7:
        return "Quick Pay";
      case 11:
        return "Venmo";
      case 13:
        return "Cashapp";
      case 1:
        return "COD";
      case 3:
        return "COP";
      case 9:
        return "Zelle";
      default:
        return "";
    }
  };

  export function pricePerMile (offerPrice, distance) {
    let offerPricePerMiles = 0.0;

    if (!isNaN(distance) && !isNaN(offerPrice) && offerPrice !== 0) {
      offerPricePerMiles = ( parseFloat(offerPrice) / parseFloat(distance) ).toFixed(2);
    } else {
      offerPricePerMiles = NaN;
    }

    return offerPricePerMiles;
  }