import React from "react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

import "../style.css"

const FooterBlock = () => {
    return (
        <React.Fragment>
            <div className="dashboard-content ">
                <div className="footer-wrapper">
                    <div className="footer-info">
                        <div>
                            <img src="assets/images/subscribe-auto.svg" alt="subscribe-auto"/>
                        </div>

                        <div className="footer-content">
                            <p style={{marginBottom: 5}}><b>Dispatcher PROs</b></p>
                            <span><b>2000 Goforit Drivers</b> are looking for dispatchers like you
                            Recruit new transporters and connect for just $30/month</span>
                        </div>
                    </div>


                    <Link to="/subscription#dispatcher-pro">
                        <div className="footer-button-wrap">
                            <Button className="footer-button"
                                    type="button"
                            >
                                SUBSCRIBE NOW
                            </Button>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>

    )
}
export default FooterBlock
