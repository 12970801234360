import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap";
import ClientsAPI from "../../../api/clientsAPI";
import './style.css'

class ClientCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      imageName: '',
      id:"",
      address:"",
      companyName:"",
      image:"",
      phoneNumber:"",
      city:"",
      state:"",
      zip:""
    };
  }

  componentDidMount() {
    const userId = JSON.parse(localStorage.getItem('userInfo')).id;
    this.setState({userId})
  }


  handleChange = async (event) => {
    const {target} = event;

    if (target.name === 'img') {

      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          image: reader.result,
          imageFile: file,
          imageName: file.name,
        });
      };
      reader.onerror = () => {
        this.setState({image: null, imageFile: null});
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({[target.name]: target.value})

    }
  }

  submit = async (event, errors) => {
    const {refreshState} = this.props
    const {id, address, companyName, image, phoneNumber, city, state, zip} = this.state;

    const data = {
      id,
      name: companyName,
      address,
      phone: phoneNumber,
      city,
      state,
      zip
    };

    if(image.length) {
      data.photo = image
    }

    if (errors.length === 0) {
      const res = await ClientsAPI.UpdateClient(data);
      if (res.status === 200) {
        refreshState()
        this.setState({popupOpen: false})
      }
    }
  }

  popupOpen = () => {
    const {
      id,
      name,
      phone,
      photo,
      address,
      city,
      state,
      zip
    } = this.props.data;

    this.setState({
      popupOpen: true,
      id,
      companyName: name,
      phoneNumber: phone,
      photo,
      address,
      city,
      state,
      zip
    })
  }

  render() {

    const {
      popupOpen,
      companyName = '',
      phoneNumber = '',
      address = '',
      userId,
      imageName
    } = this.state;
    const {data} = this.props;

    const fullAddress = [data.address || '', data.city || '', (data.state || '') + " " + (data.zip || '')]
      .map(part => part ? part.trim() : null)
      .filter(part => part).join(', ');

    return (
      <React.Fragment>
        <div className="client-card">
          <div className="client-card-header">
            <div className="client-card-header-background">
              <img src="assets/images/clientCard.jpg" alt="client card"/>
            </div>
            {data.creator_user_id === userId ?
              (<span className="client-card-header-edit" onClick={this.popupOpen}>Edit</span>) : null}
            <div className="client-card-header-logo">
              <img src={data.photo ? data.photo : "assets/images/logo-sm.png"} alt="clients logo"/>
            </div>
          </div>
          <ul className="client-card-list">
            <li>
              <div className="client-card-name">{data.name || '-'}</div>
            </li>
            <li>
              <span className="client-card-text">{fullAddress}</span>
            </li>
            <li><span className="client-card-text">{data.phone || '-'}</span></li>

          </ul>

          <div className="client-card-footer">
            <Link to={`/load/${data.id}/client`}>
              <button className="btn custom-btn">Create load</button>
            </Link>
          </div>
        </div>

        {popupOpen ? (
          <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
               style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">Update the client</h5>
                  <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                </div>
                <div className="modal-body modal-body-clients">

                  <AvForm onSubmit={this.submit}>
                    <div className="row">
                      <div className="col-12">
                        <AvField
                          name="companyName"
                          label="Company name*"
                          required errorMessage="Invalid company name"
                          value={companyName}
                          type="text"
                          validate={{required: {value: true}}} onChange={(e) => this.handleChange(e)}/>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="custom-file">
                            <p style={{ width: 230 }}>Upload company logo</p>
                            <input
                              name="img"
                              type="file"
                              className="custom-file-input"
                              id="fileUpload"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={(e) => this.handleChange(e)}/>
                            <label className="btn btn-secondary" htmlFor="fileUpload">Choose file</label>
                            <span className="custom-file-name">{imageName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <AvField
                          name="phoneNumber"
                          label="Phone number"
                          type="_tel"
                          value={phoneNumber}
                          onChange={(e) => this.handleChange(e)}/>
                      </div>
                      <div className="col-12">
                        <AvField
                          name="address"
                          label="Address*"
                          value={address}
                          required
                          errorMessage="Invalid address name"
                          type="text"
                          validate={{required: {value: true}}}
                          onChange={(e) => this.handleChange(e)}/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-right">
                        <Button className="btn btn-primary w-md custom-btn" type="submit">Update</Button>
                      </div>
                    </div>
                  </AvForm>


                </div>
              </div>
            </div>
          </div>) : null}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ClientCard);
