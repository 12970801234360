import React from "react";

import "../style.css"

const HeaderContent = () => {
    return (
        <React.Fragment>
            <div className="main-content-header page-title-box">
                <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Dispatcher Profile</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default HeaderContent
