import React, { Component } from 'react';
import LoadCardView from '../LoadCardView/LoadCardView';
import "./style.css";

class LoadCardsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupDistance: this.props.pickupDistance || 50,
      pickupLocationSelected: this.props.pickupLocationSelected ||  "",
      deliveryDistance: this.props.deliveryDistance || 50,
      deliveryLocationSelected: this.props.deliveryLocationSelected || "",
      listViewModeOn: this.props.listViewModeOn || false,
      rushOnly: this.props.rushOnly || false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.loadMoreItems();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async loadMoreItems() {
    this.setState({ loading: true });
    console.warn('loading more items...');
    if (this.props.onLoadMoreItems) {
      await this.props.onLoadMoreItems();
    }
    this.setState({ loading: false });
  }

  handleScroll() {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
      this.setState(prevState => ({ page: prevState.page + 1 }), () => {
        this.loadMoreItems();
      });
    }
  }

  render() {
    return (
      <div>
        { 
          this.props.loads && this.props.loads.map((item, i) => {
            return (
              <LoadCardView
                key={i}
                load={item}
                listViewModeOn={this.props.listViewModeOn}
                rushOnly={this.props.rushOnly}
              />
            )
          })
        }
      </div>
    );
  }
}

export default LoadCardsContainer;