import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import {USER_ROLE} from '../utils/usersRole'
import qs from 'qs';

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export default class UsersAPI {
  static async SignIn(email, password, rememberMe) {

    return basicAxios.post(`/auth/login` , qs.stringify({
      email,
      password,
      user_type: USER_ROLE.dispatcher,
      rememberMe,
    }),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error.request.status
    });
  }

  static async SignUp(data) {

    return basicAxios.post(`/auth/registration` , qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)

      if(error.request.status === 403) {
        return error.request.status
      }
    });
  }

  static async UpdateUser(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/users` , qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      if(error.request.status === 403) {
        return error.request.status
      }
    });
  }

  static async ResetUserPass(data) {

    return basicAxios.post(`/auth/resetpassword` ,qs.stringify(data),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      if(error.request.status === 403) {
        return error.request.status
      }
    });
  }

  static async GetLicense(unauthorizedCallback = null) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/license` ,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      if(error.request.status === 403) {
        return error.request.status
      }
      if(unauthorizedCallback && error.request.status === 401) {
        unauthorizedCallback();
        return error.request.status;
      }
    });
  }

  static async GetUser() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/users` ,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      if(error.request.status === 403) {
        return error.request.status
      }
    });
  }

  static async GetTimeZones() {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : ''

    return basicAxios.get(`/commons/time-zone` ,{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
      if(error.request.status === 403) {
        return error.request.status
      }
    })
  }
}


