import React, {useCallback, useEffect, useState} from "react";
import TransportersAPI from "../../../api/transportersAPI";


export default ({ onUpdate }) => {
  const [invites, setInvites] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const { data: { data }} = await TransportersAPI.GetTransporterInvites({})
        setInvites(data)
      } catch (e) {

      }
    })()
  }, [])

  const onRejectInvite = useCallback( (invitationId, index) => async () => {
    try {
      await TransportersAPI.RejectInvitationFromTransporter(invitationId)

      onUpdate();

      const newInvitations = [...invites];
      newInvitations.splice(index, 1);
      setInvites(newInvitations);
    } catch (e){
      console.error(e)
    }
  }, [invites]);

  const onAcceptInvite = useCallback( (invitationId, index) => async () => {
    try {
      await TransportersAPI.AcceptInvitationFromTransporter(invitationId)

      onUpdate();

      const newInvitations = [...invites];
      newInvitations.splice(index, 1);
      setInvites(newInvitations);
    } catch (e){
      console.error(e)
    }
  }, [invites])

  if(invites.length < 1){
    return null;
  }

  const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
  const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

  return (
    <div className="dashboard-content" style={{ marginBottom: 50 }}>
      <div className="people-table people-table-transporter invites-table">
        <div className="people-table-header">
          <div className="people-table-header-title">New drivers want to connect with you</div>
        </div>
        <table>
          <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
            <th>Cell</th>
            <th>Current company</th>
            <th>Email</th>
            <th>Trailer capacity</th>
            <th>Transported cars</th>
            <th>Last month gross $</th>
            <th>Enclosed trailer</th>
            <th><div style={{ display: 'flex', justifyContent: 'center'}}>Confirm/Deny</div></th>
          </tr>
          </thead>
          <tbody>
          {invites.map(({
            truck_photo,
            car_capacity,
            car_count,
            dispatcher_name,
            email,
            enclosed_trailer,
            first_name,
            last_name,
            gross,
            id,
            invitation_id,
            phone
          }, i) => {
            return (
              <tr key={id}>
                <td>
                  <img
                    alt={`${first_name} ${last_name} profile`}
                    style={{ width: 40, height: 40 }}
                    src={truck_photo || "assets/images/default-user.jpg" }
                  />
                </td>
                <td>{first_name} {last_name}</td>
                <td><a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">{phone}</a></td>
                <td>{dispatcher_name}</td>
                <td><a href={`mailto:${email}?subject=Mail from GoForIt Dispatcher&body=Dear ${first_name} ${last_name}, ... ${userName} ${lastName}`} target="_blank" rel="noopener noreferrer">{email}</a></td>
                <td>{car_capacity}</td>
                <td>{car_count}</td>
                <td>{gross || 0}</td>
                <td>{enclosed_trailer === 1 ? 'yes' : 'no'}</td>
                <td>
                  <div className='people-table-btn-group-control' style={{ display: 'flex', justifyContent: 'center'}}>
                    <div className="people-table-btn-group-icon fas fa-check" title="Accept" style={{ color: 'green', marginRight: 10, width: 28}}
                         onClick={onAcceptInvite(invitation_id, i)}
                    />
                    <div className="people-table-btn-group-icon fas fa-times" title="Deny" style={{ color: 'red', width: 28}}
                         onClick={onRejectInvite(invitation_id, i)}/>
                  </div>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
