import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import './style.css'
import {Tooltip} from "reactstrap";
import {bindActionCreators} from "redux";
import {getDashboardData} from "../MainPage/dashboardAction";
import DashboardAPI from "../../../api/dashboardApi";
import TransportersAPI from "../../../api/transportersAPI";
import Select from "react-select";
import GetDrivers from "../../../helpers/GetDrivers"

class MyDriversStat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip_top: false,
      page: 0,
      driversWeek: [],
      driversMonth: [],
      dataWeek: [],
      dataMonth: [],
      total: '',
      limit: 10,
      commentMode: null,
      commentText: '',
      feeMode: null,
      feeText: '',
      transporters: [],
      defaultSelectValue: {label: 'All transporters', value: ''}
    }
  }

  /*
  setState = (dataWeek, dataMonth) => {
    this.setState({
      driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
      dataWeek: dataWeek.data, dataMonth: dataMonth.data
    })
  }
  */

  getData = async (driver_id, week, month) => {
    const dataWeek = await DashboardAPI.GetMyDrivers({
      period: 'week',
      ...(week ? {week}: {}),
      ...(driver_id ? {driver_id} : {})
    })
    const dataMonth = await DashboardAPI.GetMyDrivers({
      period: 'month',
      ...(month ? {month}: {}),
      ...(driver_id ? {driver_id} : {})
    })
    this.setState( {
      driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
      dataWeek: dataWeek.data, dataMonth: dataMonth.data,
      driver_id,
      week,
      month
    })
  }

  async componentDidMount() {
    const dataWeek = await DashboardAPI.GetMyDrivers({period: 'week'})
    const dataMonth = await DashboardAPI.GetMyDrivers({period: 'month'})
    const transporters = await GetDrivers()
    this.setState({
      transporters,
      driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
      dataWeek: dataWeek.data, dataMonth: dataMonth.data
    })
  }

  handlePageChange = async (page) => {
    const {limit} = this.state;
    const data = await DashboardAPI.GetMyDrivers({page: page.selected + 1, limit});
    this.setState({page, drivers: data.data.data.data});
  }


  handleComment = async (id) => {
    const {commentText} = this.state;

    const res = await TransportersAPI.SaveTransporterComment({
      is_my_driver_comment:true, driver: id, comment: commentText
    })
    if (res.status === 200) {
      const dataWeek = await DashboardAPI.GetMyDrivers({period: 'week'});
      const dataMonth = await DashboardAPI.GetMyDrivers({period: 'month'});
      this.setState({commentMode: null,
        driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
        dataWeek: dataWeek.data, dataMonth: dataMonth.data
      })
    } else {
      this.setState({commentMode: null})
    }
  }

  deleteComment = async (id) => {
    const res = await TransportersAPI.DeleteTransporterComment(id, true)
    if (res.status === 200) {
      const dataWeek = await DashboardAPI.GetMyDrivers({period: 'week'});
      const dataMonth = await DashboardAPI.GetMyDrivers({period: 'month'});
      this.setState({commentMode: null,
        driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
        dataWeek: dataWeek.data, dataMonth: dataMonth.data
      })
    }
  }

  handleFee = async (id) => {
    const { feeText } = this.state

    const res = await DashboardAPI.UpdateFee({id, fee: feeText})
    if (res.status === 200) {
      const dataWeek = await DashboardAPI.GetMyDrivers({ period: 'week' })
      const dataMonth = await DashboardAPI.GetMyDrivers({ period: 'month' })
      this.setState({
        feeMode: null,
        driversWeek: dataWeek.data.data, driversMonth: dataMonth.data.data,
        dataWeek: dataWeek.data, dataMonth: dataMonth.data
      })
    } else {
      this.setState({feeMode: null})
    }
  }

  deleteFee = async (id) => {
    this.setState({feeText: '0.00'})
    this.handleFee(id)
  }

  handleDriver = async (value) => {
    this.getData(value.id || null, this.state.week, this.state.month)
  }

  handleChangeWeek = async (value) => {
    this.getData(this.state.driver_id, value, this.state.month)
  }

  handleChangeMonth = async (value) => {
    this.getData(this.state.driver_id, this.state.week, value)
  }

  renderFooterTotalLine (data) {
    return (
      <li className="custom-driver-list-header _footer">
        <span>Sum of all loads</span>
        <span />
        <span />
        <span />
        <span>${data.data_total}</span>
        <span>${data.data_total_fee}</span>
        <span>{data.data_total_count}</span>
      </li>
    )
  }

  render() {
    const { driversWeek = [], driversMonth = [], commentMode, commentText, feeMode, feeText, dataWeek, dataMonth, defaultSelectValue, transporters, week, month } = this.state
    return (
      <AUX>
        <div className="card">
          <div className="card-body">
            <h5 className="mt-0 custom-card-header card-body-title">My drivers 
              {this.props.refreshing ? 
                <span style={{ marginLeft: '3px', marginRight: '3px' }}><i className="fa fa-spinner fa-spin"/></span>
               : null}
              <span className="custom-info" id="TooltipTop4">i</span>
              <div className="people-table-header-filter _mydrivers">
                <ul className="load-table-header header-second">
                  <li>
                    <span className="custom-form-control-wrap">
                      <Select
                        className="custom-form-control-select-drivers _filters"
                        defaultValue={defaultSelectValue}
                        onChange={this.handleDriver}
                        options={transporters}
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <Tooltip placement="top" isOpen={this.state.tooltip_top} target="TooltipTop4"
                       toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                You see the list of your drivers and their status, picked up statuses or no loads status.
                You can see earnings by your transporters and the amount of transported loads.
              </Tooltip>
              <Link to="/transporters">Add more Transporters</Link>
            </h5>

            <ul className="nav nav-tabs nav-tabs-custom nav-tabs-custom-driver" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#week" role="tab">
                <ul className="nav">
                  <li className="nav-arrow">
                    <div className="arrowed">
                      <div className={`arrow-next ${week !== 1 ? 'active' : ''}`} onClick={() => this.handleChangeWeek(1)} />
                    </div>
                  </li>
                  <li className="nav-center">

                      <span className="d-block d-sm-none"><i className="fas fa-home"/></span>
                      <span className="d-none d-sm-block">{week === 1 ? 'Previous Week' : 'Current Week'}</span>

                  </li>
                  <li className="nav-arrow">
                    <div className="arrowed">
                      <div className={`arrow-prev ${week === 1 ? 'active' : ''}`} onClick={() => this.handleChangeWeek(0) } />
                    </div>
                  </li>
                </ul>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#all" role="tab">
                  <ul className="nav">
                    <li className="nav-arrow">
                      <div className="arrowed">
                        <div className={`arrow-next ${month !== 1 ? 'active' : ''}`} onClick={() => this.handleChangeMonth(1)} />
                      </div>
                    </li>
                    <li className="nav-center">

                        <span className="d-block d-sm-none"><i className="far fa-user"/></span>
                        <span className="d-none d-sm-block">{month === 1 ? 'Previous Month' : 'Current Month'}</span>

                    </li>
                    <li className="nav-arrow">
                      <div className="arrowed">
                        <div className={`arrow-prev ${month === 1 ? 'active' : ''}`} onClick={() => this.handleChangeMonth(0) } />
                      </div>
                    </li>
                  </ul>
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane active" id="week" role="tabpanel">
                <ul className="custom-driver-list">
                  <li className="custom-driver-list-header">
                    <span/>
                    <span>Note</span>
                    <span>Name</span>
                    <span style={{ textAlign: 'center' }}>Status</span>
                    <span>Earned</span>
                    <span>Fee</span>
                    <span>Transported Loads</span>
                  </li>
                  {driversWeek.map((item, i) => {
                    return (
                      <li className="custom-driver-list-item" key={i}>
                        <div className="custom-driver-list-item-img">
                          <img src={item.avatar || './assets/images/default-user.jpg'} alt="user img"/>
                        </div>
                        <div className="custom-driver-list-item-note">
                          <div className="people-table-comment">
                            {commentMode === item.id ?
                              (<div className="people-table-comment-form">
                                <input type="text" className="form-control" maxLength="10"
                                       onChange={(e) => this.setState({commentText: e.target.value})}
                                       value={commentMode ? commentText : item.note} />
                                <p onClick={() => this.handleComment(item.id)}>Save</p>
                                <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                              </div>)
                              : (<div className='people-table-comment-control'>
                                <div className={'people-table-comment-text'}>{item.note || '0'}</div>
                                {item.note ? (
                                  <div className='people-table-comment-form-btns _widget'>
                                    <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>{'Edit'}</p>
                                    <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                  </div>
                                ) : <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>{'Add'}</p>}
                              </div>)}
                          </div>
                        </div>
                        <div className="custom-driver-list-item-name">{item.first_name} {item.last_name}</div>
                        <div className="custom-driver-list-item-statusWrap">
                          {item.status === 'assigned' &&
                            (<div className="custom-driver-list-item-status custom-driver-list-item-status-assigned">Assigned</div>)
                          }
                          {item.status === 'not active' &&
                            (<div className="custom-driver-list-item-status custom-driver-list-item-status-notActive">Not active</div>)
                          }
                          {item.status === 'active' &&
                            (<div className="custom-driver-list-item-status custom-driver-list-item-status-active">Active</div>)
                          }
                          {item.status === 'schrodinger' &&
                          (<div className="custom-driver-list-item-status custom-driver-list-item-status-deactivated">Deactivated</div>)
                          }
                        </div>

                        <div className="custom-driver-list-item-price">
                          ${item.total_sum}
                        </div>

                        <div className="custom-driver-list-item-fee">
                          <div className="people-table-comment">
                            {feeMode === item.id ?
                              (<div className="people-table-comment-form">
                                <input  type="number" min={0} max={99} step={1} className="form-control" maxLength="10"
                                       onChange={(e) => this.setState({feeText: e.target.value})}
                                       value={feeMode ? feeText.replace('.00', '') : item.fee.replace('.00', '')} />
                                <p onClick={() => this.handleFee(item.id)}>Save</p>
                                <span onClick={() => this.setState({feeMode: null})}>Cancel</span>
                              </div>)
                              : (<div className='people-table-comment-control'>
                                <div className={'people-table-comment-text'}>
                                  {item.fee.replace('.00', '%') || '0%'}
                                  <span className={'feeStyle'}>{ ' ($' + (item.total_sum_fee) + ') '}</span>
                                </div>
                                {item.fee && item.fee !== '0.00' ? (
                                  <div className='people-table-comment-form-btns _widget'>
                                    <p onClick={() => this.setState({feeMode: item.id, feeText: item.fee})}>{'Edit'}</p>
                                    <span onClick={() => this.deleteFee(item.id)}>{'Delete'}</span>
                                  </div>
                                ) : <p onClick={() => this.setState({feeMode: item.id, feeText: item.fee})}>{'Add'}</p>}
                              </div>)}
                          </div>
                        </div>

                        <div className="custom-driver-list-item-loads">
                          {item.total_count}
                        </div>
                      </li>
                    )
                  })}
                  {this.renderFooterTotalLine(dataWeek)}
                </ul>
                {/*<ReactPaginate*/}
                {/*previousLabel={'previous'}*/}
                {/*nextLabel={'next'}*/}
                {/*breakLabel={'...'}*/}
                {/*marginPagesDisplayed={2}*/}
                {/*containerClassName={'pagination custom-pagination'}*/}
                {/*subContainerClassName={'pages pagination '}*/}
                {/*activeClassName={'active'}*/}
                {/*pageClassName={'page-item'}*/}
                {/*pageLinkClassName={'page-link'}*/}
                {/*previousClassName={'page-item'}*/}
                {/*nextClassName={'page-item'}*/}
                {/*previousLinkClassName={'page-link'}*/}
                {/*nextLinkClassName={'page-link'}*/}
                {/*breakClassName={'page-item disabled'}*/}
                {/*breakLinkClassName={'page-link disabled'}*/}
                {/*onPageChange={this.handlePageChange}*/}
                {/*pageCount={total / limit}*/}
                {/*/>*/}
              </div>
              <div className="tab-pane" id="all" role="tabpanel">
                <ul className="custom-driver-list">
                  <li className="custom-driver-list-header">
                    <span/>
                    <span>Note</span>
                    <span>Name</span>
                    <span style={{ textAlign: 'center' }}>Status</span>
                    <span>Earned</span>
                    <span>Fee</span>
                    <span>Transported Loads</span>
                    {/*<span>Transported Loads (Delivered)</span>*/}
                  </li>
                  {driversMonth.map((item, i) => {
                    return (
                      <li className="custom-driver-list-item" key={i}>
                        <div className="custom-driver-list-item-img">
                          <img src={item.avatar || './assets/images/default-user.jpg'} alt="user img"/>
                        </div>
                        <div className="custom-driver-list-item-note">
                          <div className="people-table-comment">
                            {commentMode === item.id ?
                              (<div className="people-table-comment-form">
                                <input type="text" className="form-control" maxLength="10"
                                  onChange={(e) => this.setState({commentText: e.target.value})}
                                  value={commentMode ? commentText : item.note} />
                                <p onClick={() => this.handleComment(item.id)}>Save</p>
                                <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                              </div>)
                              : (<div className='people-table-comment-control'>
                                  <div className={'people-table-comment-text'}>{item.note || ''}</div>
                                  {item.note ? (
                                    <div className='people-table-comment-form-btns _widget'>
                                      <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>{'Edit'}</p>
                                      <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                    </div>
                                  ) : <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>{'Add'}</p>}
                              </div>)}
                          </div>
                        </div>
                        <div className="custom-driver-list-item-name">{item.first_name} {item.last_name}</div>
                        <div className="custom-driver-list-item-statusWrap">
                          {item.status === 'assigned' &&
                          (<div className="custom-driver-list-item-status custom-driver-list-item-status-assigned">Assigned</div>)
                          }
                          {item.status === 'not active' &&
                          (<div className="custom-driver-list-item-status custom-driver-list-item-status-notActive">Not active</div>)
                          }
                          {item.status === 'active' &&
                          (<div className="custom-driver-list-item-status custom-driver-list-item-status-active">Active</div>)
                          }
                          {item.status === 'schrodinger' &&
                          (<div className="custom-driver-list-item-status custom-driver-list-item-status-deactivated">Deactivated</div>)
                          }
                        </div>
                        <div className="custom-driver-list-item-price">
                          ${item.total_sum}
                        </div>

                        <div className="custom-driver-list-item-fee">
                          <div className="people-table-comment">
                            {feeMode === item.id ?
                              (<div className="people-table-comment-form">
                                <input  type="number" min={0} max={99} step={1} className="form-control" maxLength="10"
                                        onChange={(e) => this.setState({feeText: e.target.value})}
                                        value={feeMode ? feeText.replace('.00', '') : item.fee.replace('.00', '')} />
                                <p onClick={() => this.handleFee(item.id)}>Save</p>
                                <span onClick={() => this.setState({feeMode: null})}>Cancel</span>
                              </div>)
                              : (<div className='people-table-comment-control'>
                                <div className={'people-table-comment-text'}>
                                  {item.fee.replace('.00', '%') || '0%'}
                                  <span className={'feeStyle'}>{ ' ($' + (item.total_sum_fee) + ') '}</span>
                                </div>
                                {item.fee && item.fee !== '0.00' ? (
                                  <div className='people-table-comment-form-btns _widget'>
                                    <p onClick={() => this.setState({feeMode: item.id, feeText: item.fee})}>{'Edit'}</p>
                                    <span onClick={() => this.deleteFee(item.id)}>{'Delete'}</span>
                                  </div>
                                ) : <p onClick={() => this.setState({feeMode: item.id, feeText: item.fee})}>{'Add'}</p>}
                              </div>)}
                          </div>
                        </div>

                        <div className="custom-driver-list-item-loads">
                          {item.total_count}
                        </div>
                      </li>
                    )
                  })}
                  {this.renderFooterTotalLine(dataMonth)}
                </ul>
                {/*<ReactPaginate*/}
                {/*previousLabel={'previous'}*/}
                {/*nextLabel={'next'}*/}
                {/*breakLabel={'...'}*/}
                {/*marginPagesDisplayed={2}*/}
                {/*containerClassName={'pagination custom-pagination'}*/}
                {/*subContainerClassName={'pages pagination '}*/}
                {/*activeClassName={'active'}*/}
                {/*pageClassName={'page-item'}*/}
                {/*pageLinkClassName={'page-link'}*/}
                {/*previousClassName={'page-item'}*/}
                {/*nextClassName={'page-item'}*/}
                {/*previousLinkClassName={'page-link'}*/}
                {/*nextLinkClassName={'page-link'}*/}
                {/*breakClassName={'page-item disabled'}*/}
                {/*breakLinkClassName={'page-link disabled'}*/}
                {/*onPageChange={this.handlePageChange}*/}
                {/*pageCount={total / limit}*/}
                {/*/>*/}

              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
  };
}


const mapDispatchToProps = dipatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
    },
    dipatch
  ),
});

export default connect(mapStatetoProps, mapDispatchToProps)(MyDriversStat);
