import React from "react";
import './style.css';

class Spinner extends React.Component {
    render() {
        const spinnerClass = this.props.useBackgroundSpinner ? 'loader background' : 'loader default';

        return (
            <div className={spinnerClass}>Loading...</div>
        )
    }
}

export default Spinner;
