import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AUX from './hoc/Aux_';
import './App.css'

class App extends Component {

  render() {
    let layout = null;

    layout = (
        <Switch>
          <Route path="/" component={mainbuilder} />
        </Switch>
      );
    return (
      <AUX>
        {layout}
      </AUX>
    );
  }
}
const mapStatetoProps = state => {
  return {
    topbar: state.ui_red.topbar,
    footer: state.ui_red.footer,
    sidebar: state.ui_red.sidebar,
    loginpage: state.ui_red.loginpage
  };
}

export default withRouter(connect(mapStatetoProps)(App));
