import React, {Component} from 'react';
import AUX from '../../../../hoc/Aux_';
import CanvasJSReact from './canvasjs.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PieChart extends Component {


  render() {

    const {statistic = {}} = this.props

    const values = []
    const sum =  Object.values(statistic).reduce((sum, current) => sum + current, 0);

    Object.values(statistic).forEach((item, i) => {
      if(item > 0) {
        values.push({
          y: Math.round((item / sum) * 100),
          color: window.getComputedStyle(document.documentElement).getPropertyValue(`--cart-background-color-${i + 1}`),
          indexLabelFontColor: item ? '#fff' : window.getComputedStyle(document.documentElement).getPropertyValue(`--cart-background-color-${i + 1}`),
        })
      }
    })



    const options = {
      animationEnabled: true,
      exportFileName: "",
      exportEnabled: true,
      width: 220,
      height: 220,
      data: [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "<strong>{y}</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        dataPoints: values,
      },
      ]
    }
    return (
      <AUX>
        <CanvasJSChart options={options}
        />
      </AUX>
    );
  }
}

export default PieChart;
