/* eslint-disable no-undef */
import React, { Component } from "react";
import moment from "moment";
import GoogleMap from "../GoogleMap";
import { onMapLoad } from "./helpers";
import LoadsAPI from "../../../api/loadsAPI";
import TransportersAPI from "../../../api/transportersAPI";
import OpportunitiesAPI from "../../../api/opportunitiesAPI";
import { withSnackbar } from "notistack";
import { showNotification } from "../../../helpers/showNotification";
import './progress.css'; // For custom styles

let socket;
class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      loads: [],
      transporters: [],
      mapReloader: 0,
      markers: [],
      loading: true,
      showOffers: props.showOffers === null || props.showOffers === undefined ? true : props.showOffers,
      showLoads: props.showLoads === null || props.showLoads === undefined ? true : props.showLoads,
      showOpportunities: props.showOpportunities === null || props.showOpportunities === undefined ? true : props.showOpportunities,
      pickupDriverId: props.pickupDriverId,
      pickupPlaceId: props.pickupPlaceId,
      deliveryDriverId: props.deliveryDriverId,
      deliveryPlaceId: props.deliveryPlaceId,
      rushOnly: props.rushOnly === null || props.rushOnly === undefined ? null : props.rushOnly,
      pickupDistance: props.pickupDistance,
      deliveryDistance: props.deliveryDistance,
      opportunities: [],
      mountCallback: props.mountCallback,
    };
    this.onSendOffer = this.onSendOffer.bind(this);
    this.onConfirmOffer = this.onConfirmOffer.bind(this);
    this.onRejectOffer = this.onRejectOffer.bind(this);
    this.renderDate = this.renderDate.bind(this);
    this.deleteOffer = this.deleteOffer.bind(this);
  }

  async fetchDataDefault() {
    const { pickupDriverId, deliveryDriverId, pickupPlaceId, deliveryPlaceId, rushOnly, pickupDistance, deliveryDistance } = this.state;
    return await this.fetchData(pickupDriverId, deliveryDriverId, pickupPlaceId, deliveryPlaceId, rushOnly, pickupDistance, deliveryDistance);
  }

  async fetchData(pickupDriverId = null, deliveryDriverId = null, pickupPlaceId = null, deliveryPlaceId = null, rushOnly = null, pickupDistance = null, deliveryDistance = null) {
    this.setState({ loading: true });
    var { data: pickupLoadGeo } = await LoadsAPI.GetLoadsGeo({ driver_id: pickupDriverId }); 
    var { data: deliveryLoadGeo } = await LoadsAPI.GetLoadsGeo({ driver_id: deliveryDriverId });
    var { data: transporterGeo } = await TransportersAPI.GetGeo();
    var { data: opportunities } = await OpportunitiesAPI.list({
      pickup_driver_id: pickupDriverId, 
      pickup_place_id: pickupPlaceId,
      delivery_driver_id: deliveryDriverId, 
      delivery_place_id: deliveryPlaceId,
      pickup_distance: pickupDistance, 
      delivery_distance: deliveryDistance, 
      priority: rushOnly ? "rush" : null 
    });

    if (pickupDriverId || deliveryDriverId) {
      transporterGeo.data = transporterGeo.data.filter(transporterGeoItem => (transporterGeoItem.tooltip.id === pickupDriverId || transporterGeoItem.tooltip.id === deliveryDriverId));
      if (!pickupDistance && !deliveryDistance) {
        opportunities = opportunities.filter(opportunityItem => opportunityItem.driver_data && (opportunityItem.driver_data.id === pickupDriverId || opportunityItem.driver_data.id === deliveryDriverId));
      }
    }

    const carsRequests = opportunities.map((load) => {
      return LoadsAPI.GetLoadsCars(load.id);
    });

    const offerRequest = opportunities.map((load) => {
      return OpportunitiesAPI.getOffer(load.id);
    });

    const cars = await Promise.all(carsRequests);
    const offers = await Promise.allSettled(offerRequest);

    const opportunitiesWithCars = opportunities.map((load, index) => {
      return {
        ...load,
        cars: cars[index].data.data,
        offers: offers[index].value && offers[index].value.data,
      };
    });

    this.setState({
      rushOnly,
      loading: false,
      ...(pickupLoadGeo ? { loads: pickupLoadGeo.data } : {}),
      ...(deliveryLoadGeo ? { loads: deliveryLoadGeo.data } : {}),
      ...(transporterGeo ? { transporters: transporterGeo.data } : {}),
      ...(opportunities ? { opportunities: opportunitiesWithCars } : {}),
    }, (state) => {
      this.mapRef.current.onScriptLoad();
    });
  }

  componentDidMount() {
    if (!socket) {
      socket = new WebSocket(
        process.env.REACT_APP_SOCKET_SERVER_URL,
        JSON.parse(localStorage.getItem("userInfo"))
          ? JSON.parse(localStorage.getItem("userInfo")).auth_key
          : ""
      );

      socket.onopen = function (evt) {
        console.log(">>> LOG: socket onopen");
      };
      socket.onclose = function (evt) {
        console.log(">>> LOG: socket onclose");
      };
    }

    this.fetchDataDefault();
    this.state.mountCallback && this.state.mountCallback();
  }

  async onSendOffer(offerData) {
    this.setState({ loading: true });
    await OpportunitiesAPI.createOffer(offerData);
    this.fetchDataDefault();
  }

  async onConfirmOffer(offer_id, load_id) {
    this.setState({ loading: true });
    const _showNotification = (variant, message) => {
      showNotification(message, variant, this.props);
    }
    await OpportunitiesAPI.confirmOffer(offer_id, load_id, _showNotification);
    this.fetchDataDefault();
  }

  async onRejectOffer(offer_id) {
    this.setState({ loading: true });
    await OpportunitiesAPI.rejectOffer(offer_id);
    this.fetchDataDefault();
  }

  componentWillUnmount() {
    if (socket) {
      socket.close();
    }
  }

  renderDate(date) {
    if (!date) {
      return "-";
    }
    return moment(date).format("YYYY-MM-DD");
  }

  async deleteOffer(offerId) {
    this.setState({ loading: true });
    await OpportunitiesAPI.deleteOffer(offerId);
    this.fetchDataDefault();
  }

  render() {

    return (
      <div>
        {this.state.loading && (
          <div className="progress-bar"></div>
        )}
        <GoogleMap
          id={this.props.internalId ? this.props.internalId : "myMap"}
          ref={this.mapRef}
          options={{ center: { lat: 41, lng: -95 }, zoom: 5 }}
          key={this.state.loads.length}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          libraries="geometry,drawing,places"
          language="en"
          region="US"
          dataLength={this.state.loads.length}
          onMapLoad={onMapLoad(
            this.state,
            socket,
            this.onSendOffer,
            this.onConfirmOffer,
            this.onRejectOffer,
            this.renderDate,
            this.deleteOffer
          )}
          onSendOffer={this.onSendOffer}
        />
      </div>
    );
  }
}

export default withSnackbar(Map);
