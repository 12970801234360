import React, {useCallback, useEffect, useState} from "react";
import {Card, Input, Rate, Select, Upload} from "antd";
import {Button} from "reactstrap";
import "../style.css"
import { languageList } from "../../../../helpers/DispatcherSelectValues";

const {Option} = Select;

const selectLanguages = languageList.map(item => <Option value={item.value} key={item.value}>{item.label}</Option>);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ProfileInfo = ({onSaveChanges, userData}) => {
    const [isEdit, setIsEdit] = useState(false)
    const [name, setName] = useState('');
    const [companyInfo, setCompanyInfo] = useState({
        language: [],
        name: "",
        phone: "",
        email: "",
        dispatcher: "",
        photo: null,
        rate: null
    })

    const onChangeLanguages = (checkedValues) => {
        setCompanyInfo({...companyInfo, language: checkedValues})
    }

    const onChangeName = (value) => {
        setCompanyInfo({...companyInfo, name: value.currentTarget.value})
    }

    const onChangePhone = (value) => {
        setCompanyInfo({...companyInfo, phone: value.currentTarget.value})
    }

    const onChangeEmail = (value) => {
        setCompanyInfo({...companyInfo, email: value.currentTarget.value})
    }

    const onChangeDispatcherName = (value) => {
        setName(value.currentTarget.value)
    }

    const onEdit = () => {
        setIsEdit(true)
    }

    const onSaveProfileInfo = async () => {
        const {phone, photo, email, dispatcher, language } = companyInfo
        const data = {
            name: companyInfo.name,
            photo,
            phone,
            email,
            language: language && language.length > 0 ? language : '',
            fax: dispatcher,
        }
        setIsEdit(false)

        const [first_name = '', last_name = ''] = name.split(' ');
        await onSaveChanges({ first_name, last_name, company: {...userData.company, ...data }})
    }

    const onChangeImage = useCallback((image) => {
        setCompanyInfo({
            ...companyInfo,
            photo: image
        });
    }, [companyInfo])

    useEffect(() => {
        const { first_name, last_name } = userData;
        const { name, phone, email, fax, photo, language } = userData.company ? userData.company : {};

        setName(`${first_name} ${last_name}`);
        setCompanyInfo({
            ...companyInfo,
            language,
            name,
            phone,
            email,
            dispatcher: fax,
            rate: userData.rate,
            photo,
        })
    }, [userData])

    return (
        <React.Fragment>
            <div className="dashboard-content mb-15">
                <div className="profile-header">
                    <div className="profile-info">
                        <div className="profile-stars">
                            {
                                isEdit ? (
                                  <Upload
                                    name='photo'
                                    beforeUpload={
                                        file => {
                                            getBase64(file).then((string) => {
                                                onChangeImage(string)
                                            })
                                            return false;
                                        }
                                    }>
                                      <Card
                                        style={{cursor: 'pointer'}}
                                        loading={false}
                                        className="profile-card"
                                        cover={<img alt="profile"
                                                    src={companyInfo.photo || "assets/images/default-user.jpg"}/>}
                                      />
                                  </Upload>
                                ) : (
                                  <Card
                                    loading={false}
                                    className="profile-card"
                                    cover={<img alt="profile"
                                                src={companyInfo.photo || "assets/images/default-user.jpg"}/>}
                                  />
                                )
                            }
                            <Rate disabled value={companyInfo.rate}/>
                        </div>
                        {
                            isEdit
                                ?
                                <div className="profile-info-wrap">
                                    <div>
                                        <div className="profile-subtitle">
                                            <Input placeholder="Dispatcher name"
                                                   onChange={onChangeDispatcherName}
                                                   value={name}
                                            />
                                        </div>

                                        <div className="profile-info-wrap">
                                            <div className="dispatcher-form-title">
                                                <i><img src="assets/images/blue_phone.svg" alt="performance"/></i>
                                            </div>
                                            <div className="profile-contacts">
                                                <Input placeholder="Phone number"
                                                       onChange={onChangePhone}
                                                       value={companyInfo.phone}
                                                />
                                            </div>
                                        </div>

                                        <div className="profile-info-wrap">
                                            <div className="dispatcher-form-title">
                                                <i><img src="assets/images/blue_email.svg" alt="performance"/></i>
                                            </div>
                                            <div className="profile-contacts">
                                                <Input placeholder="E-mail"
                                                       value={companyInfo.email}
                                                       onChange={onChangeEmail}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <Input placeholder="Company Name"
                                                   value={companyInfo.name}
                                                   onChange={onChangeName}
                                            />
                                        </div>
                                    </div>

                                    <div className="profile-edit-wrap">
                                        <Select
                                          mode="multiple"
                                          showArrow
                                          size="large"
                                          maxTagCount="responsive"
                                          className="form-custom-select form-group"
                                          placeholder="Spoken languages"
                                          listHeight={320}
                                          value={companyInfo.language && companyInfo.language.filter(item => item.trim())}
                                          onChange={onChangeLanguages}
                                        >
                                            {selectLanguages}
                                        </Select>
                                    </div>
                                </div>

                                :

                                <div>
                                    <div className="profile-subtitle">
                                        {name}
                                    </div>

                                    <div className="profile-info-wrap">
                                        <div className="dispatcher-form-title">
                                            <i><img src="assets/images/blue_phone.svg" alt="performance"/></i>
                                        </div>
                                        <div className="profile-contacts">
                                            <span>{companyInfo.phone}</span>
                                        </div>
                                    </div>

                                    <div className="profile-info-wrap">
                                        <div className="dispatcher-form-title">
                                            <i><img src="assets/images/blue_email.svg" alt="performance"/></i>
                                        </div>
                                        <div className="profile-contacts">
                                            <span>{companyInfo.email}</span>
                                        </div>
                                    </div>

                                    <div className="profile-dispatcher">
                                        {companyInfo.name}
                                    </div>

                                    <div className="profile-language-wrap">
                                        {companyInfo.language ? companyInfo.language.join(', ') : ''}
                                    </div>
                                </div>
                        }
                    </div>


                    <div>
                        {
                            isEdit
                                ?

                                <Button className="btn btn-primary w-md custom-btn"
                                        onClick={onSaveProfileInfo}
                                        type="button">Save</Button>
                                :

                                <Button className="btn btn-primary w-md custom-btn"
                                        onClick={onEdit}
                                        type="button">Edit</Button>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
export default ProfileInfo
