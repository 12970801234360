import axios from 'axios';
import config from '../config';

const basicAxios = axios.create({baseURL: config.serverUrl});


export default class VehicleAPI {

  static async GetVINDecoded(vin) {
    return basicAxios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`, {}).then((response) => {
      return response.data && response.data.Count > 0 && response.data.Results[0].ErrorCode === "0" ? this.DecodeVINData(response.data.Results[0]) : null;
    }).catch((error) => {
      console.error('GetVINDecoded error', error)
    });
  }

  static DecodeVINData(vinData) {
    var regExp = /\(([^)]+)\)/;
    var fullBody = vinData.BodyClass && vinData.BodyClass.split("/").length > 0 ? vinData.BodyClass.split("/")[0] : vinData.BodyClass
    var bodyMatches = regExp.exec(fullBody);

    return {
      make: vinData.Make ? vinData.Make : vinData.NCSAMake,
      model: ((vinData.Model ? vinData.Model : vinData.NCSAModel) + " " + vinData.Series).trim(),
      body: bodyMatches && bodyMatches.length > 0 ? bodyMatches[1] : fullBody,
      year: vinData.ModelYear,
      vin: vinData.VIN
    }
  }
}



