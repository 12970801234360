
const carTypes = [
  {value: 'hatchback', label: 'Hatchback'},
  {value: 'sedan', label: 'Sedan'},
  {value: 'mpv', label: 'MPV'},
  {value: 'suv', label: 'SUV'},
  {value: 'van', label: 'Van'},
  {value: 'pickup', label: 'Pickup'},
  {value: 'motorcycle', label: 'Motorcycle'},
  {value: 'coupe', label: 'Coupe'},
  {value: 'convertible', label: 'Convertible'},
  {value: 'wagon', label: 'Wagon'},
  {value: 'truck', label: 'Truck'},
  {value: 'bus', label: 'Bus'}
];

export default carTypes
