import NotificationsAPI from "../api/notificationsAPI";
import { addDays } from '../utils/DateParser';

const NOTIFICATION_EXPIRATION_DAYS = 7
const NOTIFICATION_CLEANUP_DELAY_MILLISECONDS = 3000

export class NotificationCleaner {
    activeCount = 0;
    expiredCount = 0;
    expiredNotifications = [];
    activeNotifications = [];
    clearNotificationsTherad = null;

    push(data) {
        if (this.clearNotificationsTherad) {
            clearInterval(this.clearNotificationsTherad)
        }

        this.expiredNotifications = []
        this.activeNotifications = [];
        const expireDate = addDays(Date.now(), -NOTIFICATION_EXPIRATION_DAYS)
        this.activeCount = 0;
        this.expiredCount = 0;

        data.forEach(element => {
            if (element && element.date_add) {
                const messageDate = new Date(element.date_add.replace(' ', 'T'))
                const active = messageDate > expireDate

                if (isNaN(messageDate) || active) {
                    this.activeCount++;
                    this.activeNotifications.push(element)
                } else {
                    this.expiredCount++;
                    this.expiredNotifications.push(element)
                }
            }
        });

        if (this.expiredCount > 0) {
            this.clearNotificationsTherad = setInterval(() => {
                const notificationToRemove = this.expiredNotifications.pop()
                if (notificationToRemove) {
                    NotificationsAPI.PutNotifications({id: notificationToRemove.id})
                    console.warn("[NotificationCleaner] expired notification", notificationToRemove.id, notificationToRemove.date_add)
                } else {
                    clearInterval(this.clearNotificationsTherad)
                }
            }, NOTIFICATION_CLEANUP_DELAY_MILLISECONDS);
        }

        console.log("[NotificationCleaner] notifications", this.activeCount, this.expiredCount)

        return this.activeNotifications; 
    }
}

export default new NotificationCleaner();