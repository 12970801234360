import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from "qs";


const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export default class NotificationsAPI {

  static async getNotificationsData(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/notification`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
    });
  }

  static async PutNotifications(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/dispatchers/notification`,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error', error)
    });
  }
}
