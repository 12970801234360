import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import 'antd/dist/antd.css';
import  UIreducer from './store/reducers/reducer';
import dashboardReducer from "./containers/CustomComponents/MainPage/dashboardReducer"
import { composeWithDevTools } from 'redux-devtools-extension';

import { Provider } from 'react-redux';
import thunk from "redux-thunk";
import { SnackbarProvider } from "notistack";

const rootReducer = combineReducers({
    ui_red:UIreducer,
    dashboardReducer
});


const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const app = (
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        <Provider store={store} >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </SnackbarProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
