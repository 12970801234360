import React, {useEffect, useState} from "react";
import {Button} from "reactstrap";
import {InputNumber, Select} from "antd";

import {
    cargoType,
    servicesOfferType,
    statesCollumForAll,
    trailerType
} from "../../../../helpers/DispatcherSelectValues";

import "../style.css"


const {Option} = Select;
const selectStates = statesCollumForAll.map(item => <Option key={item}>{item}</Option>);
const trailerTypes = trailerType.map(item => <Option key={item}>{item}</Option>);
const cargoTypes = cargoType.map(item => <Option key={item}>{item}</Option>);
const servicesOfferTypes = servicesOfferType.map(item => <Option key={item}>{item}</Option>);

const Performance = ({alert, onSaveChanges, userData}) => {
    const [state, setState] = useState({
        drivers_needed: null,
        drivers_have: null,
        states: [],
        trailer_type: [],
        cargo_type: null,
        services_offered: null
    })
    const [cancelState, setCancelState] = useState({
        drivers_needed: null,
        drivers_have: null,
        states: [],
        trailer_type: [],
        cargo_type: null,
        services_offered: null
    })

    const onChangeDriversNeed = (value) => {
        setState({...state, drivers_needed: value})
    }

    const onChangeDriversHave = (value) => {
        setState({...state, drivers_have: value})
    }

    const handleChangeStates = (value) => {
        if (value.length === 50) {
            return setState({...state, states: []})
        }
        if (value.includes("ALL")) {
            return setState({...state, states: statesCollumForAll})
        }
        setState({...state, states: value})
    }

    const handleChangeTrailer = (value) => {
        setState({...state, trailer_type: value})
    }

    const handleChangeCargo = (value) => {
        setState({...state, cargo_type: value})
    }

    const handleChangeOffer = (value) => {
        setState({...state, services_offered: value})
    }

    const handleSubmit = async () => {
        const {drivers_needed, drivers_have, states, trailer_type, cargo_type, services_offered} = state

        const data = {
            drivers_have: parseInt(drivers_have) || 0,
            drivers_needed: parseInt(drivers_needed) || 0,
            states: states,
            trailer_type: trailer_type,
            cargo_type: cargo_type,
            services_offered: services_offered
        }

        await onSaveChanges({company: {...userData.company, ...data}})
    }

    useEffect(() => {
        const {
            drivers_needed, drivers_have, states,
            trailer_type, cargo_type, services_offered
        } = userData.company ? userData.company : {}

        setState({
            ...state,
            drivers_needed: drivers_needed,
            drivers_have: drivers_have,
            states: states,
            trailer_type: trailer_type,
            cargo_type: cargo_type,
            services_offered: services_offered
        })
        setCancelState({
            ...cancelState,
            drivers_needed: drivers_needed,
            drivers_have: drivers_have,
            states: states,
            trailer_type: trailer_type,
            cargo_type: cargo_type,
            services_offered: services_offered
        })
    }, [setState, setCancelState])

    // const { avg_gross_week, avg_gross_month } = userData.company;

    return (
        <React.Fragment>
            <div className="dashboard-content mb-15">

                <div className="dispatcher-info">
                    <div className="dispatcher-title d-f">
                        <div>
                            <i><img src="assets/images/performance.svg" alt="performance"/></i>
                        </div>
                        <div>
                            <span>My Performance</span>
                        </div>
                    </div>

                    {/*<div className="dispatcher-subtitle">*/}
                    {/*    My Average Gross: <b>W ${avg_gross_week || 0}/M ${avg_gross_month || 0}</b>*/}
                    {/*</div>*/}

                    <div className="dispatcher-form">
                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/drivers.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>How many drivers are you looking for?</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12">
                                    <InputNumber size="large"
                                                 min={0}
                                                 style={{width: "100%"}}
                                                 max={100000}
                                                 value={state.drivers_needed}
                                                 onChange={onChangeDriversNeed}/>
                                </div>
                            </div>
                        </div>

                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/drivers.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>How many drivers you have now?</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12">
                                    <InputNumber size="large"
                                                 min={0}
                                                 style={{width: "100%"}}
                                                 max={100000}
                                                 value={state.drivers_have}
                                                 onChange={onChangeDriversHave}/>
                                </div>
                            </div>
                        </div>

                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/location.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>Select states you like to dispatch?</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12 form-select-wrap">
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        size="large"
                                        maxTagCount="responsive"
                                        className="form-custom-select form-group"
                                        placeholder="Please select states"
                                        listHeight={320}
                                        value={state.states || []}
                                        onChange={handleChangeStates}
                                    >
                                        {selectStates}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/trailer.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>Select trailer type you like to dispatch</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12 form-select-wrap">
                                    <Select
                                        showArrow
                                        size="large"
                                        className="form-custom-select form-group"
                                        placeholder="Please select trailer type"
                                        value={state.trailer_type}
                                        onChange={handleChangeTrailer}
                                    >
                                        {trailerTypes}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/trailer.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>Cargo types you dispatch</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12 form-select-wrap">
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        size="large"
                                        className="form-custom-select form-group"
                                        placeholder="Please select cargo types"
                                        value={state.cargo_type  || []}
                                        onChange={handleChangeCargo}
                                    >
                                        {cargoTypes}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="dispatcher-form-item">
                            <div className="dispatcher-title-wrap">
                                <div className="dispatcher-form-title">
                                    <i>
                                        <img src="assets/images/offer.svg"
                                             alt="performance"/>
                                    </i>
                                </div>
                                <div>
                                    <span>Services Offered</span>
                                </div>
                            </div>
                            <div className="dispatcher-input-wrap">
                                <div className="col-12 form-select-wrap">
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        size="large"
                                        className="form-custom-select form-group"
                                        placeholder="Please select services offered"
                                        value={state.services_offered  || []}
                                        onChange={handleChangeOffer}
                                    >
                                        {servicesOfferTypes}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dispatcher-info-footer m-b-20">
                        <div className="row">
                            <div className="col-4"/>

                            <div className="col-sm-8 text-right">

                                <Button className="btn btn-primary w-md custom-btn"
                                        onClick={handleSubmit}
                                        type="button"
                                >
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </div>


                    {
                        alert
                            ?
                            (<div className="custom-alert"
                                  role="alert">
                                You successfully changed your profile
                            </div>)
                            :
                            null
                    }
                </div>
            </div>
        </React.Fragment>

    )
}
export default Performance
