import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

import LoadsStat from '../LoadsStat/LoadsStat'
import CompletedLoadsStat from '../CompletedLoadsStat/CompletedLoadsStat'
import TopClientsStat from '../TopClientsStat/TopClientsStat'
import MyDriversStat from '../MyDriversStat/MyDriversStat'

import DashboardAPI from '../../../api/dashboardApi'
import './style.css'

import { getDashboardData } from "./dashboardAction"
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import SubscriptionContext from "../../../components/Layout/SubscriptionContext"
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { checkTrial, showAddTransporter } from '../../../helpers/CheckSubscription';

class MainPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dispatcherProStatus: undefined,
      awaitForRender: false,
      refreshingDashboard: false,
      popupOpen: false,
      bubbleOpen: false,
      subscriptionStatus: '',
    };
  }

  async componentDidMount() {
    const { getDashboardData } = this.props
    // const data = await DashboardAPI.GetDashboardData()
    // if (data && data.data) {
    //   localStorage.setItem('dashboardData', JSON.stringify(data.data.data))
    //   await getDashboardData(data.data.data)
    // }

    const dashboardData = localStorage.getItem('dashboardData')
    if (dashboardData) {
      await getDashboardData(JSON.parse(dashboardData))
    }

    this.setState({ refreshingDashboard: true }, () => {
      console.debug("[MainPage] componentDidMount", this.state.refreshingDashboard);
      DashboardAPI.GetDashboardData().then(async (res) => {
        console.debug("[MainPage] GetDashboardData...");
        if (res && res.data) {
          localStorage.setItem('dashboardData', JSON.stringify(res.data.data))
          await getDashboardData(res.data.data)
          console.debug("[MainPage] GetDashboardData ", false);
          this.setState({ refreshingDashboard: false });
        }
      }).catch(err => {
        console.error("[MainPage] GetDashboardData", err);
      });
    });

    // const subscriptionData = await SubscriptionAPI.GetSubscribeData();
    // if (subscriptionData.data.status === 500 && subscriptionData.data.data) {
    //   this.setState({
    //     subscriptionStatus: subscriptionData.data.data.status,
    //   });
    // }

    SubscriptionAPI.GetSubscribeData().then(res => {
      if (res.data.status === 500 && res.data.data) {
        this.setState({
          subscriptionStatus: res.data.data.status,
        });
      }
    }).catch(err => {
      console.error("[MainPage] GetSubscribeData", err);
    });

    // try {
    //   const subscription = await SubscriptionAPI.GetSubscribeDataPro();
    //   this.setState({ dispatcherProStatus: subscription.data.data.status })
    // } catch (e) {
    //   this.setState({ dispatcherProStatus: 'error' })
    // }

    SubscriptionAPI.GetSubscribeDataPro().then(res => {
      this.setState({ dispatcherProStatus: res.data.data.status })
    }).catch(err => {
      this.setState({ dispatcherProStatus: 'error' })
      console.error("[MainPage] GetSubscribeDataPro", err);
    });

    this.setState({
      awaitForRender: true,
      popupOpen: await checkTrial(),
      bubbleOpen: showAddTransporter(),
    })
  }

  render() {
    const { awaitForRender, popupOpen, bubbleOpen, subscriptionStatus } = this.state
    const { dashboardData } = this.props

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <SubscriptionContext.Provider value={subscriptionStatus}>
              <CustomSideBar page={'dashboard'} />
            </SubscriptionContext.Provider>
            <CustomTopBar />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleOpen} 
              speechMode={"addTransporter"} 
              closeCallBack={() => 
                this.setState({
                  bubbleOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left main-content-header-left-dashboard">
                    <div>
                      <div className="main-content-header-title page-title">Dashboard</div>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Welcome to GOFORIT for Dispatchers</li>
                      </ol>
                    </div>
                    <a className="btn custom-btn" href='/load'>Create load</a>
                  </div>
                  <div className="main-content-header-right">
                    <ul className="main-content-header-statistic">
                      <li className="main-content-header-statistic-item">
                        <div className="main-content-header-statistic-item-value">{dashboardData.licenseCount}</div>
                        <div className="main-content-header-statistic-item-title">License</div>
                        <div className="main-content-header-statistic-item-sup">
                          <span> your current plan </span>

                          { this.state.dispatcherProStatus  === 'active' && (
                            <span style={{ display: 'block', marginTop: 3 }}>
                              Dispatcher Pro:
                              <span style={{ color: 'green', marginLeft: 3}}>
                                Active
                              </span>
                            </span>
                          )}
                        </div>
                      </li>
                      <li className="main-content-header-statistic-item">
                        <div className="main-content-header-statistic-item-value">{dashboardData.activeDriverCount}</div>
                        <div className="main-content-header-statistic-item-title">Drivers</div>
                        <div className="main-content-header-statistic-item-sup">in your database <br /> (Active)</div>
                      </li>
                      <li className="main-content-header-statistic-item">
                        <div className="main-content-header-statistic-item-value">{dashboardData.assignedDriverCount}</div>
                        <div className="main-content-header-statistic-item-title">Drivers</div>
                        <div className="main-content-header-statistic-item-sup">assigned to loads</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="dashboard-content dashboard-content-main">
                  {awaitForRender ? (
                    <React.Fragment>
                      <LoadsStat refreshing={this.state.refreshingDashboard}/>
                      <CompletedLoadsStat refreshing={this.state.refreshingDashboard}/>
                      <TopClientsStat refreshing={this.state.refreshingDashboard}/>
                      <MyDriversStat refreshing={this.state.refreshingDashboard}/>
                    </React.Fragment>
                    )
                    : (<React.Fragment>
                        <div className="custom-spinner-row">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </React.Fragment>)
                  }
                </div>

              </div>
            </div>


            {popupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Welcome</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                    </div>
                    <div className="modal-body modal-body-welcome">
                      <div className="modal-body-welcome-img">
                        <img src="/assets/images/welcome-popup-img.png" alt=""/>
                      </div>
                      <div className="modal-body-welcome-text">
                        Now you have trial period for 30 days and can use the application without limitations. You can add transporters for free on the Subscription page. After trial period expires payment info will be required
                      </div>

                    </div>
                  </div>
                </div>
              </div>) : null}


          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
    loginpage: state.ui_red.loginpage
  };
}


const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
      UpdateLogin: () => ({type: actionTypes.LOGINPAGE, value: true}),
      UpdateLoginAgain: () => ({type: actionTypes.LOGINPAGE, value: false})
    },
    dispatch
  ),


});




export default connect(mapStatetoProps, mapDispatchToProps)(MainPage);
