import config from "../config";
import axios from "axios";
import CryptoJS from "crypto-js";

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

const params = () => {
  const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : ''
  return {
    auth_string: config.security_string,
    auth_crc: convertText,
    app_ver: config.app_ver,
    auth_key: auth_key
  }
}

export default class OpportunitiesAPI {

  static async GetOffersData(parameters) {
    return basicAxios.get(`/opportunities`, {
      params: {
        ...params(),
        ...parameters
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.error('[OpportunitiesAPI] - GetOffersData', error);
      throw error
    });
  }

  static list(parameters = {}) {
    return basicAxios.get(`/opportunities`, {
      params: {
        ...params(),
        ...parameters
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static getOffer(load_id) {
    return basicAxios.get(`/opportunities/${load_id}/offers`,  {
      params: {
        ...params(),
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async createOffer (data) {
    const { pick_up_date, delivery_date, price, load_id } = data;
    return basicAxios.put(`/opportunities/${load_id}/create-offer-by-dispatcher`, { pick_up_date, delivery_date, hauling: price },{
      params: {
        ...params(),
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async assignDriver (data) {
    const { load_id, driver_id } = data;
    return basicAxios.put(`/opportunities/${load_id}/assign-driver-by-dispatcher/${driver_id}`, null, {
      params: {
        ...params(),
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async confirmOffer (offer_id, load_id, _showNotification) {
    const MESSAGE = `You have accepted load "${load_id}" please assign a driver `;

    return basicAxios.put(`/opportunities/${offer_id}/confirm-offer-by-dispatcher`, null,{
      params: {
        ...params(),
      }
    }).then((response) => {
      if (_showNotification) _showNotification( 'success', MESSAGE);
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async rejectOffer (offer_id) {
    return basicAxios.put(`/opportunities/${offer_id}/reject-offer-by-dispatcher`, null, {
      params: {
        ...params(),
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async deleteOffer (offer_id) {
    return basicAxios.delete(`/opportunities/${offer_id}`, {
      params: {
        ...params(),
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }

  static async getPlaces (criteria) {
    return basicAxios.get(`/commons/geo-locations`, {
      params: {
        ...params(),
        criteria
      }
    }).then((response) => {
      return response.data
    }).catch((error) => {
      throw error
    })
  }
}
