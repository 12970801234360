import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';
import './style.css'
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap"
import UsersAPI from '../../../api/userApi'

class ForgetPass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resetPass: false,
    };
  }

  handleSubmit = async (event, errors, values) => {
    const {email} = values;

    if (errors.length === 0) {
      const res = await UsersAPI.ResetUserPass({email, user_type: 4,})
      const {status = null} = res
      if (status === 200) {
        this.setState({resetPass: true})
      }

    }
  }

  render() {

    const {resetPass} = this.state

    return (
      <AUX>
        <div className="wrapper-page">
          <div className="custom-login custom-forget-pass card overflow-hidden account-card mx-3">
            <div className="custom-login-header">
              <div className="logo logo-admin">
                <img src="assets/images/shortLogo.png" alt="logo"/>
              </div>
              <h4>Reset password</h4>
            </div>

            <div className="account-card-content">

              <AvForm onSubmit={this.handleSubmit}>
                <AvField
                  name="email"
                  label="Email"
                  type="email"
                  errorMessage="Invalid Email"
                  maxLength="100"
                  validate={{required: {value: true}, email: {value: true}}}/>

                <div className="form-group row m-t-20">
                  <div className="col-sm-6">
                    <Link to={"/login"} style={{lineHeight: '35px'}}>Back to login</Link>
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button className="btn btn-primary w-md custom-btn" type="submit">Reset password</Button>
                  </div>
                </div>
              </AvForm>

              {resetPass ?
                <div className="m-t-40  text-center" role="alert">Reset completed. Check your email!</div>
                : ''}
            </div>

          </div>

          <div className="m-t-40 text-center custom-login-text">
            <p>Or you can create new <Link to="/signup" className="font-500 font-14"> Register now </Link></p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ForgetPass);
