import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from 'qs';

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export default class ClientsAPI {

  static async GetClientsData(pagination, onlyLoadClients) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dealers` , {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        is_load_clients: onlyLoadClients,
        ...pagination
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async CreateClient(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.post(`/dealers` ,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return error
    });
  }

  static async UpdateClient(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`/dealers/${data.id}` ,qs.stringify(data), {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }


  static async GetSearchClientsData(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dealers/search`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...data
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return {}
    });
  }

  static async GetClient(id) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dealers/${id}`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

}


