import React, { Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';

import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

import SubscriptionContext, { isSubscriptionFailed } from "../../../components/Layout/SubscriptionContext";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import SubscriptionAPI, { SUBSCRIPTION_DAYS_MONTH, SUBSCRIPTION_PRICE, SUBSCRIPTION_PRO_PRICE } from "../../../api/subscriptionAPI";

import './style.css'
import config from "../../../config";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

const REFRESH_PRICE_INTERVAL = 10000;
class Subscription extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      popupProOpen: false,
      errorMessage: '',
      driversCount: 0,
      currentDrivers: 0,
      price: SUBSCRIPTION_PRICE,
      subscribeStatus: 'new',
      subscribeProStatus: 'new',
      subscriptionStart: '',
      subscriptionEnd: '',
      upgradePrice: '',
      warningMsg: '',
      disabled: false,
      buttonPayStatus: '',
      subscriptionStatus: ''
    };
  }

  async getSubscriptionProData() {
    const data = await SubscriptionAPI.GetSubscribeDataPro();
    console.log('[GetSubscribeDataPro]', data);

    if(data.data.status === 200){
      this.setState({
        subscribeProStatus: data.data.data.status === 'active' ? 'old' : 'new',
        proData: data.data.data
      })
    }
  }


  formatPriceInCents = (priceInCents) => {
  // Convert cents to dollars
  const dollars = Math.floor(priceInCents / 100);
  
  // Calculate remaining cents
  const cents = priceInCents % 100;
  
  // Format the price with two decimal places and handle zero cents
  const formattedCents = cents === 0 ? '00' : (cents < 10 ? '0' : '') + cents;

  return `${dollars}.${formattedCents}`;
}

  calculateUpgradePrice = async (upgradeDrivers) => {
    const { subscribeStatus, currentDrivers, driversCount } = this.state;

    if (upgradeDrivers === null || upgradeDrivers === undefined) {
      upgradeDrivers = driversCount;
    }

    if (currentDrivers >= upgradeDrivers || subscribeStatus !== 'old') {
      return this.setState({upgradePrice: 0});
    }

    if (subscribeStatus === 'new') {
      const upgradePrice = upgradeDrivers * SUBSCRIPTION_PRICE * 100;
      this.setState({upgradePrice});
      return upgradePrice;
    } else if (subscribeStatus === 'cancel') {
      this.setState({upgradePrice: 0});
      return 0;
    }

    return SubscriptionAPI.UpdateSubscribe({amount: upgradeDrivers, preview: true}).then((result) => {
      const {data} = result.data;   
      const upgradePrice = data.upcoming_invoice && data.upcoming_invoice.amount_due ? data.upcoming_invoice.amount_due : 0;
      console.warn('[calculateUpgradePrice]', upgradePrice, upgradeDrivers, currentDrivers);
      this.setState({upgradePrice});
      return upgradePrice;
    }, (error) => {
      console.error('[calculateUpgradePrice]', error, upgradeDrivers, currentDrivers);
      this.setState({upgradePrice: 'N/A'});
      return NaN;
    }).catch(error => {
      console.error('[calculateUpgradePrice]', error, upgradeDrivers, currentDrivers);
      this.setState({upgradePrice: 'N/A'});
      return NaN;
    })
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getSubscriptionProData();

    const data = await SubscriptionAPI.GetSubscribeData();
    console.log("[GetSubscribeData]", data);

    if (data.data.status === 200) {
      this.setState({
        driversCount: data.data.data.current_amount,
        currentDrivers: data.data.data.current_amount,
        subscribeStatus: 'old',
        subscriptionStart: data.data.data.current_period_start_date,
        subscriptionEnd: data.data.data.current_period_end_date,
        errorMsg: '',
        buttonPayStatus: '',
        subscriptionStatus: data.data.data.status,
      })
    } else {
      if (data.data.status === 500 && data.data.data) {
        this.setState({
          driversCount: data.data.data.current_amount,
          currentDrivers: data.data.data.current_amount,
          subscribeStatus: 'old',
          subscriptionStart: data.data.data.current_period_start_date,
          subscriptionEnd: data.data.data.current_period_end_date,
          errorMsg: '',
          warningMsg: 'Your current subscription was paused due to non-payment',
          disabled: isSubscriptionFailed(data.data.data.status) ? false : true,
          buttonPayStatus: '',
          subscriptionStatus: data.data.data.status,
        })
      } else {
        this.setState({
          subscribeStatus: 'new',
          buttonPayStatus: '',
          subscriptionStatus: ''
        })
      }
    }

    this.intervalId = setInterval(() => this.calculateUpgradePrice(), REFRESH_PRICE_INTERVAL);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      // Clean up interval when component unmounts
      clearInterval(this.intervalId);
    }
  }

  // ERROR
  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  // CLICK ON POPUP BTN PAY
  handleSubmit = async (evt) => {
    evt.preventDefault();
    const { driversCount, subscribeStatus } = this.state
    this.setState({ buttonPayStatus: 'disabled' } )

    if (this.props.stripe) {

      const timeOut = (subscribeStatus, subscriptionStatus = null, warningMsg = null) => {
        clearTimeout(this.timeOutPopup)
        this.timeOutPopup = setTimeout(() => this.setState({
          popupOpen: false,
          driversCount: driversCount,
          currentDrivers: driversCount,
          subscriptionStatus: subscriptionStatus ? subscriptionStatus : '',
          subscribeStatus: subscribeStatus,
          paymentStatus: '',
          buttonPayStatus: '',
          ...( warningMsg || warningMsg === '' ? { warningMsg } : { } )
        }), 2500)
      }

      if (subscribeStatus === 'new') {
        const data = await this.props.stripe.createToken()
        if (data.token) {
          await SubscriptionAPI.Subscribe({token: data.token.id, amount: driversCount}).then((result) => {
            const {data} = result;
            if (result.status === 200) {
              if (data.status !== 500) {
                this.setState({paymentStatus: 'success', warningMsg: '', errorMsg: ''})
                timeOut('old')
              } else {
                this.setState({
                  paymentStatus: 'rejected',
                  errorMsg: result.message,
                  warningMsg: 'The transaction is pending, contact your financial institution to finalize payment',
                  disabled: true,
                });
                timeOut('old', 'incomplete')
              }
            } else {
              this.setState({
                paymentStatus: 'rejected',
                errorMsg: result.message,
                warningMsg: 'The transaction did not go through',
              });
              timeOut('new', 'incomplete')
            }
          }, (error) => {
            this.setState({paymentStatus: 'rejected', warningMsg: 'The transaction did not go through', errorMsg: error.response})
            timeOut('new', 'incomplete')
          }).catch(error => {
            this.setState({paymentStatus: 'rejected', warningMsg: 'The transaction did not go through', errorMsg: error.message})
            timeOut('new', 'incomplete')
          })
        } else {
          this.setState({paymentStatus: 'rejected', buttonPayStatus: '', warningMsg: 'The transaction did not go through'})
        }
      } else if (subscribeStatus === 'old') {
        await SubscriptionAPI.UpdateSubscribe({amount: driversCount}).then((result) => {
          const {data, status, message} = result;   
          if (status === 200 && data.status !== 500) {
            this.setState({paymentStatus: 'success', warningMsg: '', errorMessage: ''})
            timeOut('old')
          } else {
            this.setState({paymentStatus: 'rejected', errorMsg: message, warningMsg: "The transaction did not go through"});
            timeOut('old', 'incomplete')
          }
        }, (error) => {
          this.setState({paymentStatus: 'rejected', errorMsg: error.response, warningMsg: 'The transaction did not go through'});
          timeOut('old', 'incomplete')
        }).catch(error => {
          this.setState({paymentStatus: 'rejected', errorMsg: error.message, warningMsg: 'The transaction did not go through'})
          timeOut('old', 'incomplete')
        })
      } else if (subscribeStatus === 'cancel') {
        await SubscriptionAPI.DeleteSubscribe().then(() => {
          this.setState({paymentStatus: 'success', warningMsg: '', errorMessage: ''});
          timeOut('old')
        }, () => {
          this.setState({paymentStatus: 'rejected', warningMsg: 'The cancelling did not go through'})
          timeOut('old')
        }).catch(error => {
          this.setState({paymentStatus: 'rejected', warningMsg: 'The cancelling did not go through', errorMsg: error.message})
          timeOut('old')
        })
      }
    } else {
      console.warn("Stripe.js hasn't loaded yet.")
      this.setState({ buttonPayStatus: '' })
    }
  };

  handleSubmitPro = async (evt) => {
    evt.preventDefault();
    const { subscribeProStatus } = this.state
    this.setState({ buttonPayProStatus: 'disabled' } )

    if (this.props.stripe) {

      const timeOut = (subscribeProStatus, subscriptionStatus = null, warningMsg = null) => {
        clearTimeout(this.timeOutPopup)
        this.timeOutPopup = setTimeout(() => this.setState({
          popupProOpen: false,
          subscribeProStatus,
          paymentProStatus: '',
          buttonPayProStatus: '',
          subscriptionStatus: subscriptionStatus ? subscriptionStatus : '',
          ...( warningMsg || warningMsg === '' ? { warningMsg } : {} )
        }), 2500)
      }

      if (subscribeProStatus === 'new') {
        const data = await this.props.stripe.createToken()
        if (data.token) {
          await SubscriptionAPI.SubscribePro({token: data.token.id}).then((result) => {
            const {data} = result;
            if (result.status === 200) {
              if (data.status !== 500) {
                this.setState({paymentProStatus: 'success', warningMsg: '', errorMessage: ''})
                timeOut('old')
              } else {
                this.setState({
                  paymentProStatus: 'rejected',
                  errorMsg: result.message,
                  warningMsg: 'The transaction is pending, contact your financial institution to finalize payment',
                  disabled: true,
                });
                timeOut('old', 'incomplete')
              }
            } else {
              this.setState({
                paymentProStatus: 'rejected',
                errorMsg: result.message,
                warningMsg: 'The transaction did not go through',
              });
              timeOut('new', 'incomplete')
            }
          }, (error) => {
            this.setState({paymentProStatus: 'rejected', errorMsg: error.response, warningMsg: 'The transaction did not go through'})
            timeOut('new', 'incomplete')
          }).catch(error => {
            this.setState({paymentProStatus: 'rejected', errorMsg: error.message, warningMsg: 'The transaction did not go through'})
            timeOut('new', 'incomplete')
          })
        } else {
          this.setState({paymentProStatus: 'rejected', buttonPayProStatus: '', warningMsg: 'The transaction did not go through'})
        }
      } else {
        await SubscriptionAPI.DeleteSubscribePro().then(() => {
          this.setState({paymentProStatus: 'success', warningMsg: '', errorMessage: ''});
          timeOut('old')
        }, () => {
          this.setState({paymentProStatus: 'rejected', warningMsg: 'The cancelling did not go through'})
          timeOut('old', 'incomplete')
        }).catch(error => {
          this.setState({paymentProStatus: 'rejected', errorMsg: error.message, warningMsg: 'The cancelling did not go through'})
          timeOut('old', 'incomplete')
        })
      }
    } else {
      console.warn("Stripe.js hasn't loaded yet.")
      this.setState({ buttonPayStatus: '' })
    }
  }

  submitHandler = () => {
    const {driversCount} = this.state;

    if (+driversCount === 0) {
      this.setState({
        popupOpen: true,
        subscribeStatus: 'cancel',
        buttonPayStatus: ''
      })
    }
    else {
      this.setState({
        popupOpen: true,
        buttonPayStatus: ''
      }, () => {
        this.calculateUpgradePrice(driversCount);
      })
    }
  };

  driversDecrement = () => {
    const {driversCount, disabled} = this.state;

    if (disabled) return;

    const upgradeDriersCount = driversCount > 0 ? driversCount - 1 : 0;

    this.setState({driversCount: upgradeDriersCount}, () => {
      this.calculateUpgradePrice(upgradeDriersCount);
    });
  };

  driversIncrement = () => {
    const {disabled} = this.state;

    if (disabled) return;

    const upgradeDriersCount = +this.state.driversCount + 1 > 10000 ? 10000 : +this.state.driversCount + 1;

    this.setState({
      driversCount: upgradeDriersCount
    }, () => {
      this.calculateUpgradePrice(upgradeDriersCount);
    });
  };

  subscribePro = () => {
    this.setState({
      popupProOpen: true
    })
  };

  changeDrivers = async (e) =>{
    let { value } = e.target;
    let numberPattern = /\d+/g ;

    if(!value.length || value === '0') {
      value = '0'
    } else {
      value = +value.match(numberPattern)[0]
    }

    const upgradeDriersCount = e.target && e.target.value && e.target.value > 10000 ? 10000 : value;

    this.setState({
      driversCount: upgradeDriersCount
    }, () => {
      this.calculateUpgradePrice(upgradeDriersCount);
    });
  };


  render() {
    const {
      popupOpen,
      popupProOpen,
      driversCount,
      currentDrivers,
      price,
      subscribeStatus,
      subscribeProStatus,
      subscriptionEnd,
      upgradePrice,
      paymentStatus,
      errorMsg,
      warningMsg,
      disabled,
      proData,
      subscriptionStatus
    } = this.state;

    const dateEnd = (subscriptionEnd + '').split(' ')
    const subscriptionEndFormated = dateEnd[0]
    const subscriptionDate = new Date()
    subscriptionDate.setDate(new Date().getDate() + SUBSCRIPTION_DAYS_MONTH)
    const pickUpDate = new Date(subscriptionDate)
    const pickUpDateYear = subscriptionDate.getFullYear()
    const pickUpDateMonth = pickUpDate.getMonth() + 1 >= 1 && pickUpDate.getMonth() + 1 < 10 ? `0${pickUpDate.getMonth() + 1}` : pickUpDate.getMonth() + 1;
    const pickUpDateDay = pickUpDate.getDate() >= 1 && pickUpDate.getDate() < 10 ? `0${pickUpDate.getDate()}` : pickUpDate.getDate();

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <SubscriptionContext.Provider value={subscriptionStatus}>
              <CustomSideBar page={'subscription'}/>
            </SubscriptionContext.Provider>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Subscription</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Subscription</li>
                    </ol>
                  </div>
                </div>
                <div className="dashboard-content">

                  <div id="dispatcher-pro" className="dispatcher-pro">
                    <div className="dispatcher-pro-title">DISPATCHER PRO - Find new drivers, market your dispatching skills</div>
                    {
                      subscribeProStatus === 'new' && (
                        <div className="dispatcher-pro-content">
                          <p>
                            With <strong>${SUBSCRIPTION_PRO_PRICE}</strong> a month you can connect with <strong>2000+</strong> drivers. 
                            Owner operators are always looking for the best dispatchers. Become available and promote your services. 
                            Read our <a href="https://goforitapp.com/terms-and-conditions/" style={{fontWeight: '400'}}> terms and conditions</a>.
                          </p>
                        </div>
                      )
                    }
                    {
                      subscribeProStatus !== 'new' && (
                        <div className="dispatcher-pro-content">
                          <p>
                            You are currently subscribed to <strong>Dispatcher pro</strong> plan. if you need to cancel the plan please press “Cancel” below. Please note your plan will stay active till the end of billing period. All sales are final
                          </p>
                        </div>
                      )
                    }

                    <div className="dispatcher-pro-cta" onClick={this.subscribePro}>
                      {subscribeProStatus === 'new' ? 'Subscribe' : 'Cancel'}
                    </div>
                  </div>

                  {/*<div className="subscription-info">*/}
                  {/*  <p>As a first time user you are automatically granted with 30 day free trial period after which payment is required to keep service.*/}
                  {/*    Payment is securely processed through safe online payment processing system STRIPE.*/}
                  {/*    Our license program enables dispatchers to send invites and add transporters on the dashboard.*/}
                  {/*    Dispatchers can add any number of transporters to the purchased plan. Monthly subscription fee for adding a new driver is <span>$45/month</span></p>*/}
                  {/*  <div className="subscription-info-cancel"*/}
                  {/*       onClick={this.cancelSubscription}>Cancel payments*/}
                  {/*  </div>*/}
                  {/*</div>*/}


                  <div className="user-plan">
                    <div className="user-plan-row">
                      <div className="user-plan-col">
                        <div className="user-plan-info">
                        <div className="user-plan-view-title">DRIVER PLAN - Dispatch your drivers</div>
                          <p className='p-t-10'>Dispatcher license period - 1 month. <br/>
                            Pay monthly for only required number of drivers.*
                          </p>
                          <ul className="user-plan-info-list">
                            <li><i className="fas fa-check"/>Create unlimited loads quantity</li>
                            <li><i className="fas fa-check"/>Assign loads to your drivers</li>
                            <li><i className="fas fa-check"/>See status of your loads in real time</li>
                          </ul>

                          <div className="user-plan-info-drivers">
                            <p>How many drivers do you need to add?</p>
                            <div className="user-plan-info-drivers-count">
                              <div className="input-group">
                                <div
                                  className="input-group-append count-minus"
                                  style={disabled ? {cursor: 'default'} : {}}
                                  onClick={this.driversDecrement}>
                                  <span className="input-group-text">
                                    <i className="mdi mdi-minus"/>
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  min="0"
                                  className="form-control"
                                  maxLength="10000"
                                  disabled={disabled}
                                  onChange={(e)=>this.changeDrivers(e)}
                                  value={Number(driversCount).toString()}/>
                                <span className='hideControl'/>
                                <div
                                  className="input-group-append count-plus"
                                  style={disabled ? {cursor: 'default'} : {}}
                                  onClick={this.driversIncrement}>
                                  <span className="input-group-text">
                                    <i className="mdi mdi-plus"/>
                                  </span>
                                </div>
                              </div>
                              {currentDrivers === +driversCount ?
                                <button
                                  onClick={isSubscriptionFailed(subscriptionStatus) ? this.submitHandler : null}
                                  className={isSubscriptionFailed(subscriptionStatus) ? "btn custom-btn" : "btn custom-btn custom-btn-blocked"}
                                  disabled={disabled}
                                  style={{marginLeft: '10px'}}>{isSubscriptionFailed(subscriptionStatus) ? "Resume" : "Current" 
                                  // + " " + currentDrivers + " / " + driversCount
                                  }</button> : null}
                              {currentDrivers < +driversCount ?
                                <button
                                  onClick={this.submitHandler}
                                  disabled={disabled}
                                  className="btn custom-btn"
                                  style={{marginLeft: '10px'}}>Upgrade</button> : null}
                              {currentDrivers > +driversCount ?
                                <button
                                  onClick={this.submitHandler}
                                  disabled={disabled}
                                  className="btn custom-btn"
                                  style={{marginLeft: '10px'}}>{parseInt(driversCount) === 0 ? "Cancel" : "Downgrade"}</button> : null}
                            </div>
                          </div>
                          {((warningMsg || errorMsg) && subscribeStatus) &&
                          (<div className="user-plan-info-sup error">{warningMsg ? warningMsg : errorMsg}. Please, check your balance or <Link to="/settings">update your card</Link> information.</div>)
                          }
                          <div className="user-plan-info-warn">
                            To <strong>cancel</strong> type 0 and then press <strong style={{color: '#696969'}}>Cancel</strong>
                          </div>
                          <div className="user-plan-info-sup">
                            *All sales are final. We do not provide refunds for already paid licenses.
                            You can cancel your subscription plan anytime. However your license remains active till the end of billing period
                          </div>
                        </div>
                      </div>
                      <div className="user-plan-col">
                        <div className="user-plan-view">
                          <div className="user-plan-view-title">GOFORIT MONTHLY PLAN</div>
                          <ul className="user-plan-view-list">
                            <li><p>Your monthly payment<br/> ${SUBSCRIPTION_PRICE} x {driversCount} drivers x 1 month</p><span>${this.formatPriceInCents(driversCount * price * 100)}</span></li>
                            <li><p>Tax</p><span>$0.00</span></li>
                            <li><p>Today's total</p><span>${currentDrivers >= driversCount ? this.formatPriceInCents(0) : this.formatPriceInCents(upgradePrice) || this.formatPriceInCents(driversCount * price * 100)}</span></li>
                          </ul>
                        </div>
                        <div className="user-plan-view-list">
                            <p>All new users are granted {SUBSCRIPTION_DAYS_MONTH} day free trial period.</p>
                            <p>Payments are securely processed by Stripe &copy;</p>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-40 text-center font-14">
                    <p>Have a question? <a href='https://goforitapp.com/support/' className="font-500"> Support</a></p>
                  </div>
                </div>
              </div>
            </div>

            {popupProOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Payment</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupProOpen: false})}>×</button>
                    </div>
                    <div className="modal-body">

                      <div className="stripeForm">
                        <form onSubmit={(e) => this.handleSubmitPro(e)}>
                          {subscribeProStatus === 'new' && (<label>
                            <CardElement
                              onChange={this.handleChange}
                              {...createOptions()}
                            />
                          </label>)}

                          <div className="error" role="alert">
                            {this.state.errorMessage}
                          </div>

                          {subscribeProStatus === 'new' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>Your are purchasing Dispatcher PRO license </p>
                                </div>
                                <div className="payment-content-item">
                                  <p>The license will be valid until</p>
                                  <span><b> {`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b></span>
                                </div>
                                <div className="payment-content-item">
                                  <p>Your monthly payment is</p>
                                  <span><b>${SUBSCRIPTION_PRO_PRICE}</b></span>
                                </div>
                                <div className="payment-content-footer">
                                  <button disabled={this.state.buttonPayStatus} className={this.state.buttonPayStatus === 'disabled' ? 'custom-btn-blocked' : ''}>Pay</button>
                                </div>
                              </div>
                              {(errorMsg) && (<div className="error" role="alert">{errorMsg}</div>)}
                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your payment was successful</div>)}
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your payment was unsuccessful</div>)}
                            </React.Fragment>
                          )}

                          {/*Cancel*/}
                          {subscribeProStatus !== 'new' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are canceling the monthly subscription.
                                    Starting <span><b>{proData.current_period_end_date}</b></span> you will have no Dispatcher Pro feature.
                                    To be able to use the app with all the Dispathcer PRO features, you will need to purchase a license</p>

                                </div>
                                <div className="payment-content-footer">
                                  <button>Confirm</button>
                                </div>
                              </div>
                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your cancel was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your cancel was unsuccessful</div>)
                              }

                            </React.Fragment>)}

                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>) : null}



            {popupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Payment</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                    </div>
                    <div className="modal-body">

                      <div className="stripeForm">
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                          {subscribeStatus === 'new' && (<label>
                            <CardElement
                              onChange={this.handleChange}
                              {...createOptions()}
                            />
                          </label>)}

                          <div className="error" role="alert">
                            {this.state.errorMessage}
                          </div>

                          {subscribeStatus === 'new' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>Your are purchasing an additional license for </p>
                                  <span><b>{driversCount}</b> driver/s</span>
                                </div>
                                <div className="payment-content-item">
                                  <p>The license will be valid until</p>
                                  <span><b> {`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b></span>
                                </div>
                                <div className="payment-content-item">
                                  <p>Your monthly payment is</p>
                                  <span><b>${driversCount * SUBSCRIPTION_PRICE}</b></span>
                                </div>
                                <div className="payment-content-footer">
                                  <p>You can buy additional licenses whenever you need by</p>
                                  <button disabled={this.state.buttonPayStatus} className={this.state.buttonPayStatus === 'disabled' ? 'custom-btn-blocked' : ''}>Pay</button>
                                </div>
                              </div>
                              {errorMsg && (<div className="error" role="alert">{errorMsg}</div>)}
                              {/*<p className="stripeFormText">*/}
                              {/*You are purchasing licenses for <b>{driversCount}</b> drivers.*/}
                              {/*This license will be valid until<b> {`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b>.*/}
                              {/*Your monthly payment is <b>{driversCount * 45}$</b>. You can buy additional licenses whenever you need by*/}
                              {/*</p>*/}
                              {/*<button>Pay</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your payment was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your payment was unsuccessful</div>)
                              }
                            </React.Fragment>
                          )}

                          {/*upgrade*/}
                          {(subscribeStatus === 'old' && (driversCount > currentDrivers || isSubscriptionFailed(subscriptionStatus))) && (                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>Your are purchasing an additional license for </p>
                                  <span><b>{driversCount}</b> driver/s</span>
                                </div>
                                <div className="payment-content-item">
                                  <p>The license will be valid until</p>
                                  <span><b>{`${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`}</b></span>
                                </div>
                                <div className="payment-content-item">
                                  <p>Your today’s payment is</p>
                                  <span><b>{this.formatPriceInCents(upgradePrice) || this.formatPriceInCents(driversCount * price * 100)}$</b></span>
                                </div>
                                <div className="payment-content-footer">
                                  <p>Your monthly payment will be calculated based on the total amount of licenses</p>
                                  <button disabled={this.state.buttonPayStatus} className={this.state.buttonPayStatus === 'disabled' ? 'custom-btn-blocked' : ''}>Pay</button>
                                </div>
                              </div>

                              {/*<p className="stripeFormText">*/}
                              {/*You are purchasing an additional license for <b>{driversCount}</b> driver/s.*/}
                              {/*The license will be valid until <b>{subscriptionEndFormated}</b>.*/}
                              {/*Your today’s payment is <b>{upgradePrice}$</b>.*/}
                              {/*Your monthly payment will be calculated based on the total amount of licenses.*/}
                              {/*</p>*/}
                              {/*<button>Pay</button>*/}

                              {errorMsg && (<div className="error" role="alert">{errorMsg}</div>)}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your change was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your change was unsuccessful</div>)
                              }

                            </React.Fragment>
                          )}

                          {/*downgrade*/}
                          {subscribeStatus === 'old' && driversCount !== 0 && driversCount < currentDrivers && (
                            <React.Fragment>
                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are downgrading the license by decreasing the number of available drivers in your account.
                                    The plan will be updated on <span><b>{subscriptionEndFormated}</b></span> automatically,
                                    you can use your current amount of drivers until the end of the billing period</p>

                                </div>
                                <div className="payment-content-footer">
                                  {/*<p>Your monthly payment will be calculated based on the total amount of licenses</p>*/}
                                  <button>Confirm</button>
                                </div>
                              </div>


                              {/*<p className="stripeFormText">*/}
                              {/*You are downgrading the license by decreasing the number of available drivers in your account.*/}
                              {/*The plan will be updated on <b>{subscriptionEndFormated}</b> automatically,*/}
                              {/*you can use your current amount of drivers until the end of the billing period.*/}
                              {/*</p>*/}
                              {/*<button>Confirm</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your change was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">{errorMsg} Your change was unsuccessful</div>)
                              }

                            </React.Fragment>)}

                          {/*Cancel*/}
                          {subscribeStatus === 'cancel' && (
                            <React.Fragment>

                              <div className="payment-content">
                                <div className="payment-content-item">
                                  <p>You are canceling the monthly subscription.
                                    Starting <span><b>{subscriptionEndFormated}</b></span> you will have no ability to
                                    activate drivers or assign loads.
                                    To be able to use the app with all the features, you will need to purchase a license</p>

                                </div>
                                <div className="payment-content-footer">
                                  {/*<p>Your monthly payment will be calculated based on the total amount of licenses</p>*/}
                                  <button>Confirm</button>
                                </div>
                              </div>

                              {/*<p className="stripeFormText">*/}
                              {/*You are canceling the monthly subscription.*/}
                              {/*Starting <b>{subscriptionEndFormated}</b> you will have no ability to activate drivers or assign loads.*/}
                              {/*To be able to use the app with all the features, you will need to purchase a license.*/}
                              {/*</p>*/}
                              {/*<button>Confirm</button>*/}

                              {paymentStatus === 'success' &&
                              (<div className="custom-alert" role="alert">Your cancel was successful</div>)
                              }
                              {paymentStatus === 'rejected' &&
                              (<div className="custom-alert" role="alert">Your cancel was unsuccessful</div>)
                              }

                            </React.Fragment>)}

                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>) : null}
          </div>
        </main>
      </AUX>
    );
  }
}


const SubscriptionInjected = injectStripe(Subscription)


const Headline = () => {

  return (
    <div>
      <StripeProvider apiKey={config.stripeAPI}>
        <Elements>
          <SubscriptionInjected/>
        </Elements>
      </StripeProvider>
    </div>
  )
};
export default Headline;
