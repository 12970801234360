import actionType from './dashboardActionType';

const { GET_DASHBOARD_DATA } = actionType;

export const getDashboardData = data => (dispatch) => {
  dispatch({
    type: GET_DASHBOARD_DATA,
    data: data
  });
};
