import React, { Component } from 'react';
import "./style.css";
import RouteLineView from '../RouteLineView/RouteLineView';
import RouteMap from '../RouteMap/RouteMap';
import DateInput from '../DateInput/DateInput';
import PriceInput from '../PriceInput/PriceInput';
import { timeAgo, formatInputDate, createLocalTimeDate} from '../../../helpers/DateUtils';
import { pluralizeMiles } from '../../../helpers/GeoUtils';
import { googleSearch, googleMapSearch, phoneCall, mailTo, googleMapRoute } from '../../../helpers/LinkUtils';
import formatAddress from "../../../utils/AddressParser";
import { BackgroundCheckStatus } from '../../../helpers/BackgroundCheck';
import { getPaymentTerm, pricePerMile } from '../../../helpers/PaymentUtils';
import { fetchRandomVehicleImage } from '../../../api/imageApi';
import { Button } from "reactstrap";
import OpportunitiesAPI from "../../../api/opportunitiesAPI";

class LoadCardView extends Component {
  constructor(props) {
    super(props);

    // Call the helper function to initialize the state
    const offerValues = this.calculateOfferValues(this.props.load);

    this.state = {
      load: this.props.load || {},
      offerData: props.load.offerData || null, 
      rushOnly: this.props.rushOnly || false,
      collapsed: this.props.collapsed || true,
      ...offerValues,
    };
  }

  calculateOfferValues = (load) => {
    let cancelOffer = false;
    let offerAccepted = false;

    let pick_up_date = load.pick_up_date ? load.pick_up_date.split(' ')[0] : formatInputDate();
    let delivery_date = load.delivery_date ? load.delivery_date.split(' ')[0] : formatInputDate();
    let hauling = load.hauling ? Math.floor(parseFloat(load.hauling)) : 0;
    let offered_hauling = 0;
    let revenue = 0;
    let needConfirmation = false;
    let offerDeclined = false;
    let offerCreated = true;
  
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  
    if (load.offerData && load.offerData.id) {
      offerAccepted = load.offerData.confirmed === 1;
      cancelOffer = load.offerData.author_id === userInfo.id && load.offerData.confirmed === 0;
  
      needConfirmation = load.offerData.dispatcher_id === userInfo.id && load.offerData.confirmed === 0 && load.offerData.planned_driver_id;
      offerDeclined = load.offerData.confirmed === -1;
  
      pick_up_date = load.offerData.pick_up_date ? load.offerData.pick_up_date.split(' ')[0] : pick_up_date;
      delivery_date = load.offerData.delivery_date ? load.offerData.delivery_date.split(' ')[0] : delivery_date;
      offered_hauling = load.offerData.hauling ? Math.floor(parseFloat(load.offerData.hauling)) : 0;
      revenue = offered_hauling - hauling;
      hauling = offered_hauling;
      offerCreated = false;
    }
  
    const termsRequired = needConfirmation ? false : !(cancelOffer ? true : (offerAccepted ? true : (load.termsRequired === undefined || load.termsRequired === null ? false : (load.termsRequired === "true" || load.termsRequired === true))));
    const allowSubmit = offerDeclined || needConfirmation || offerAccepted ? false : (cancelOffer || offerCreated ? true : false);
    const submitOperation = cancelOffer ? "Cancel Offer" : (offerAccepted ? "Offer Accepted" : (offerDeclined ? "Offer Declined" : "Send Offer"));

    return { cancelOffer, pick_up_date, delivery_date, hauling, offered_hauling, revenue, termsRequired, submitOperation, allowSubmit, offerAccepted, needConfirmation, offerDeclined };
  }

  loadDetails = (originShortAddress, firstCarYear, firstCarMake, firstCarModel, firstCarType, brokerPresent, brokerTitle, destinationShortAddress, secondCar, pluCarsCount, distanceResult, originAddress, destinationAddress, routePolyline, loadIdTitle) => {
    const { collapsed, load } = this.state;

    return (
      <div>
        
        { collapsed ? null : (
          <div className='easy-text subtitle-text'>
            Origin and Destination
          </div>
        )}
        

        {/* Top section with left and right columns */}
        <div className="details-container">
          {/* Left column */}
          <div className="details-left-column">
            <RouteLineView 
              dotSize={12} 
              lineHeight={50}
            />
          </div>
  
          {/* Right column */}
          <div className="details-right-column">
          { collapsed ? (
            <div className="details-right-top">
              <div className="details-top-row">{originShortAddress}</div>
              <div className="details-top-row-icon"><img src="assets/images/car-icon.svg" alt="" /></div>
              <div className="details-top-row">{(firstCarYear || ' ---- ')}&nbsp;<span className="dimmed-text link" onClick={() => googleSearch((firstCarYear || '') + " " + (firstCarMake || '') + " " + (firstCarModel || '') + " " + (firstCarType || ''))}>{(firstCarMake || 'n/a')} {(firstCarModel || 'n/a')} {(firstCarType || '')}</span></div>
              <div className="details-top-row-icon"><img src="assets/images/company-icon.svg" alt="" /></div>
              <div className="details-top-row">{ brokerPresent ? <span className="dimmed-text link" onClick={() => googleSearch(brokerTitle)}><strong>{brokerTitle}</strong></span> : "N/A"}</div>
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row">{originShortAddress}</div>
            </div>
          )}
          { collapsed ? (
            <div className="details-right-top">
              <div className="details-top-row">{destinationShortAddress}</div>
              <div className="details-top-row-icon" style={{ minHeight: '24px', minWidth: '24px' }}>{load.carData.length > 1 ? (<img src="assets/images/car-icon.svg" alt="" />) : (null)}</div>
              <div className="details-top-row">{load.carData.length > 1 ? (this.getOtherVehicleDetails(secondCar, pluCarsCount)) : (null)}</div>
              <div className="details-top-row-icon"><img src="assets/images/number-icon.svg" alt="" /></div>
              <div className="details-top-row">Load: {loadIdTitle}</div>
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row">{destinationShortAddress}</div>
            </div>
          )}
          </div>
        </div>
  

        {/* Bottom row spans below both columns */}
        <div className="details-bottom-row dimmed-text distance-text">
          {distanceResult}
        </div>

        { collapsed ? null : (
          <div style={{ marginTop: '26px', cursor: 'pointer' }} onClick={() => googleMapRoute(originAddress, destinationAddress)}>
            <RouteMap 
              origin={originShortAddress}
              destination={destinationShortAddress}
              routePolyline={routePolyline}
              width={330}
              height={330}
            />
          </div>
        )}
      </div>
    );
  };

  getOtherVehicleDetails = (secondCar, pluCarsCount) => {
    const secondCarYear = secondCar.year;
    const secondCarMake = secondCar.car_maker_name;
    const secondCarModel = secondCar.car_model_name;
    const secondCarType = secondCar.car_type;

    const pluCarsText = pluCarsCount > 0 ? `+${pluCarsCount} more` : '';

    return (
      <span>
        {(secondCarYear || ' ---- ')}&nbsp;<span className="dimmed-text link" onClick={() => googleSearch((secondCarYear || '') + " " + (secondCarMake || '') + " " + (secondCarModel || '') + " " + (secondCarType || ''))}>{(secondCarMake || 'n/a')} {(secondCarModel || 'n/a')} {(secondCarType || '')}</span> {pluCarsText}
      </span>
    )
  }

  loadExpandedDetails = (brokerCompany, brokerName, brokerAddress, brokerPhone, brokerEmail) => {
    const { collapsed, load } = this.state;

    const carData = load.carData || [];
    
    return (
      <div>
        
        { collapsed ? null : (
          <div className='easy-text subtitle-text'>
            Broker
          </div>
        )}
        
        {/* Top section with left and right columns */}
        <div className="details-container">
  
          {/* Right column */}
          <div className="details-right-column">
          { collapsed ? (
            <div className="details-right-top">
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/company-icon.svg" alt="" /></div>
              <div className="details-top-row"><span className="dimmed-text link" onClick={() => googleSearch(brokerCompany)}>{brokerCompany}</span></div>
            </div>
          )}
          { collapsed ? (
            <div className="details-right-top">
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/person-icon.svg" alt="" /></div>
              <div className="details-top-row">{brokerName}</div>
            </div>
          )}
          { collapsed ? (
            <div className="details-right-top">
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/location-icon.svg" alt="" /></div>
              <div className="details-top-row"><span className="normal-text link" onClick={() => googleMapSearch(brokerAddress)}>{brokerAddress}</span></div>
            </div>
          )}
          { collapsed ? (
            <div className="details-right-top">
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/phone-icon.svg" alt="" /></div>
              <div className="details-top-row"><span className="normal-text link" onClick={() => phoneCall(brokerPhone)}>{brokerPhone ? brokerPhone : 'N/A'}</span></div>
            </div>
          )}
          { collapsed ? (
            <div className="details-right-top">
            </div>
          ) : (
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/email-icon.svg" alt="" /></div>
              <div className="details-top-row"><span className="normal-text link" onClick={() => mailTo(brokerEmail, brokerName)}>{brokerEmail ? brokerEmail : 'N/A'}</span></div>
            </div>
          )}
          </div>
        </div>

        {this.displayVehicles(carData.length)}

      </div>
    );
  };

  displayVehicles = (count) => {
    const containerClass = count > 2 ? 'vehicles-scrollable-container' : 'vehicles-non-scrollable-container';
  
    return (
      <div className={containerClass}>
        {Array.from({ length: count }, (_, index) => (
          <div key={index} className="details-container">
            {this.vehicleDetails(index + 1, this.state.load.carData[index])}
          </div>
        ))}
      </div>
    );
  };

  vehicleDetails = (i, carData) => { 
    const { carImageSelected } = this.state;

    const currentCar = carData || {};
    const currentCarYear = currentCar.year;
    const currentCarMake = currentCar.car_maker_name;
    const currentCarModel = currentCar.car_model_name;
    const currentCarType = currentCar.car_type;
    const currentCarVin = currentCar.vin;

    const carImage = currentCar.mainPhoto || (currentCar.otherPhoto && currentCar.otherPhoto.length > 0 ? currentCar.otherPhoto[0] : null) || carImageSelected || null;

    if (carImage === null) {
      fetchRandomVehicleImage({
        year: currentCarYear,
        make: currentCarMake,
        model: currentCarModel,
        color: undefined,
        type: currentCarType,
        vin: currentCarVin,
      }).then((url) => {
        this.setState({ carImageSelected: url });
      });
    }

    return (
      <div className="vehicle-container">
        <div className="vehicle-left-column">
          <div className="easy-text subtitle-text vehicle-row">Vehicle {i}</div>
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/car-icon.svg" alt="" /></div>
              <div className="details-top-row">{(currentCarYear || ' ---- ')}&nbsp;<span className="dimmed-text link" onClick={() => googleSearch((currentCarYear || '') + " " + (currentCarMake || '') + " " + (currentCarModel || '') + " " + (currentCarType || ''))}>{(currentCarMake || 'n/a')} {(currentCarModel || 'n/a')} {(currentCarType || '')}</span></div>
            </div>
            <div className="details-right-top">
              <div className="details-top-row-icon"><img src="assets/images/sharp-icon.svg" alt="" /></div>
              <div className="details-top-row">VIN&nbsp;<span className="easy-text">{currentCarVin || 'N/A'}</span></div>
            </div>
        </div>
        <div className="vehicle-right-column">
          <img src={carImage || "assets/images/placeholder-icon.svg"} alt="Placeholder" className="vehicle-image" />
        </div>
      </div>
    );
  }

  getRevenue = (load, offer) => {
    if (!load || !offer) {
      return 0;
    }

    const loadHauling = parseFloat(load.hauling);
    const offerHauling = parseFloat(offer.hauling);

    if (isNaN(loadHauling) || isNaN(offerHauling)) {
      return 0;
    }

    return offerHauling - loadHauling;
  }

  handleChange = async (event) => {
    const {target} = event;

    if (target.name === 'img') {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          image: reader.result,
          imageFile: file,
          imageName: file.name,
        });
      };
      reader.onerror = () => {
        this.setState({image: null, imageFile: null});
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({[target.name]: ( target.name === "termsRequired" ? target.value === "true" || target.value === true : target.value ), revenue: target.name === "hauling" ? this.getRevenue(this.state.load, { hauling: target.value }) : this.state.revenue})
    }
  }

  onAccept = async (event) => {
    const { offerData, load } = this.state;

    this.setState({offerAccepted: true});

    const offerResponse = await OpportunitiesAPI.confirmOffer(offerData.id, load.id, null).catch((e) => {
      if (e.message === "Request failed with status code 403") {
        this.setState({submitOperation: "Offer Already Confirmed", allowSubmit: false, needConfirmation: false });

        setTimeout(() => {
          this.setState({ submitOperation: "Accepted", allowSubmit: true, offerAccepted: false, needConfirmation: true });
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Accept Failed", allowSubmit: true});
      }
    });

    if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
      this.setState({submitOperation: "Offer Accepted", allowSubmit: false, offerAccepted: true, needConfirmation: false });

      setTimeout(() => {
        this.setState({allowSubmit: false, cancelOffer: false, termsRequired: this.state.termsRequired});
      }, 2000);
    } else {
      this.setState({submitOperation: "Offer Accept Failed", allowSubmit: false});
    }
  }

  onDecline = async (event) => {
    const { offerData } = this.state;

    this.setState({offerDeclined: true});

    const offerResponse = await OpportunitiesAPI.rejectOffer(offerData.id).catch((e) => {
      if (e.message === "Request failed with status code 403") {
        this.setState({submitOperation: "Offer Already Declined", allowSubmit: false, needConfirmation: false });

        setTimeout(() => {
          this.setState({ submitOperation: "Declined", allowSubmit: true, cancelOffer: false, needConfirmation: true });
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Decline Failed", allowSubmit: true});
      }
    });

    if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
      this.setState({submitOperation: "Offer Declined", allowSubmit: false, offerDeclined : true, needConfirmation: false });

      setTimeout(() => {
        this.setState({allowSubmit: false, cancelOffer: false, termsRequired: this.state.termsRequired});
      }, 2000);
    } else {
      this.setState({submitOperation: "Offer Decline Failed", allowSubmit: false});
    }
  }

  submit = async (event) => {
    const {hauling, load, cancelOffer, offerData, needConfirmation, pick_up_date, delivery_date} = this.state;

    this.setState({allowSubmit: false});

    if (needConfirmation) {
      return;
    }
    
    if (cancelOffer) {
      const offerResponse = await OpportunitiesAPI.deleteOffer(offerData.id).catch((e) => {
        if (e.message === "Request failed with status code 403") {
          this.setState({submitOperation: "Offer Already Cancelled", allowSubmit: false });

          setTimeout(() => {
            this.setState({ submitOperation: "Send Offer", allowSubmit: true, cancelOffer: false });
          }, 2000);
        } else {
          this.setState({submitOperation: "Offer Cancel Failed", allowSubmit: true});
        }
      });

      if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
        this.setState({submitOperation: "Offer Cancelled", allowSubmit: false });

        setTimeout(() => {
          load.offerData = null;
          const offerValues = this.calculateOfferValues(load);
          this.setState({load: load, offerData: null, ...offerValues, allowSubmit: true, cancelOffer: false, termsRequired: this.state.termsRequired});
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Cancel Failed", allowSubmit: true});
      }

      return;
    }

    // if (errors.length === 0) {
      try {
        const offerResponse = await OpportunitiesAPI.createOffer({pick_up_date, delivery_date, price: hauling, load_id: load.id}).catch((e) => {
          if (e.message === "Request failed with status code 403") {
            this.setState({submitOperation: "Offer Already Sent", allowSubmit: false });

            setTimeout(() => {
              this.setState({ submitOperation: "Cancel Offer", allowSubmit: true, cancelOffer: true });
            }, 2000);
          } else {
            this.setState({submitOperation: "Offer Send Failed", allowSubmit: true});
          }
        });

        if (offerResponse.status === 200 && offerResponse.data) {
          this.setState({submitOperation: "Offer Sent", allowSubmit: false, cancelOffer: true});

          setTimeout(() => {
            this.setState({ submitOperation: "Cancel Offer", allowSubmit: true, cancelOffer: true });
          }, 2000);

        } else {
          this.setState({submitOperation: "Offer Send Failed", allowSubmit: true});
        }
        return;
      } catch (e) {
        console.error("submitOffer", e);
      }
    // }
  }

  offerForm = () => {
    const { load, hauling, pick_up_date, delivery_date } = this.state;

    load.creator_data = load.creator_data || {};
    const userName = load.creator_data.first_name || '';
    const lastName = load.creator_data.last_name || '';
    const fullName = `${userName} ${lastName}`.trim();

    const pickupDate = pick_up_date ? createLocalTimeDate(pick_up_date) : createLocalTimeDate();
    const deliveryDate = delivery_date ? createLocalTimeDate(delivery_date) : createLocalTimeDate();

    const offerPrice = hauling;
    var offerPricePerMiles = pricePerMile(offerPrice, load.miles);

    const isDisabled = (this.state.isLoading || !this.state.allowSubmit || this.state.cancelOffer) ? true : false;
    const isChecked = !this.state.termsRequired || isDisabled || this.state.needConfirmation;

    return (
      <div>        
        <div className='easy-text subtitle-text'>
          Offer
        </div>

        <div className="offer-form-container">
      <div className="offer-form-row">
        <label className="offer-label easy-text">Contact Name</label>
        <input
          id={"contact_name@" + load.id}
          type="text"
          className="offer-input"
          defaultValue={fullName ||''}
          disabled={fullName || isDisabled}
          placeholder="Enter contact name" 
        />
      </div>

      <div className="offer-form-row offer-form-row-two-columns">
        <div className="offer-form-column">
          <DateInput 
            id={"pick_up_date@" + load.id} 
            name="pick_up_date" 
            label="Pickup Date" 
            labelClass="offer-label easy-text"
            iconSrc="assets/images/datepicker-icon.svg"
            onDateChange={this.handleChange}
            inputValue={pickupDate}
            disabled={isDisabled}
            afterDate={createLocalTimeDate()}
          />
        </div>
        <div className="offer-form-column">
          <DateInput 
            id={"delivery_date@" + load.id} 
            name="delivery_date" 
            label="Delivery Date" 
            labelClass="offer-label easy-text"
            iconSrc="assets/images/datepicker-icon.svg"
            onDateChange={this.handleChange}
            inputValue={deliveryDate}
            disabled={isDisabled}
            afterDate={this.state.pick_up_date ? createLocalTimeDate(this.state.pick_up_date) : pickupDate}
          />
        </div>
      </div>

      <div className="offer-form-row">
        <PriceInput
          label="Price"
          name="hauling"
          iconSrc={load.priority === 'rush' ? "assets/images/rush.svg" : undefined}
          currencySymbol="$"
          inputValue={offerPrice}
          textValue={`$${offerPricePerMiles}/mi`}
          tipText="To place a bid, change the price"
          onInputChange={this.handleChange}
          labelClass="offer-label easy-text"
          textColor={load.priority === 'rush' ? "#4382F7" : undefined}
          disabled={isDisabled}
        />
      </div>

      <div className="offer-form-row offer-form-checkbox-row">
        <input 
          type="checkbox"
          id={"terms@" + load.id}
          name="termsRequired"
          value={isChecked} 
          checked={isChecked}
          className="offer-checkbox"
          onChange={this.handleChange}
          disabled={isDisabled}
        />
        <label htmlFor="terms" className="offer-checkbox-label">
          I have read and agreed with provided <a href="https://goforitapp.com/terms-and-conditions/">Terms and Conditions</a>
        </label>
      </div>

      <div className="offer-form-row">
        {this.state.needConfirmation ? (
          <div className="offer-button-container">
            <Button 
              id={"accept@" + load.id} 
              type="submit"
              disabled={this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined}
              className="offer-submit-btn-accept"
              onClick={this.onAccept}
            >
              Accept
            </Button>
            <Button
              id={"decline@" + load.id} 
              type="submit"
              disabled={this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined}
              className="offer-submit-btn-decline"
              onClick={this.onDecline}
            >
              Decline
            </Button>
          </div>
        ) : (
          <Button 
            id={"submit@" + load.id} 
            type="submit"
            className="offer-submit-btn"
            disabled={ this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined || !this.state.allowSubmit }
            onClick={this.submit}
            >
              { this.state.submitOperation }
          </Button>
        )}
      </div>
      
    </div>

      </div>
    );
  }

  expandButton = () => {
    const { collapsed } = this.state;

    return (
      <div className="fixed-column">
          <button 
            className="icon-button" 
            onClick={() => this.setState({ collapsed: !collapsed })}
          >
            <i 
              className={this.state.collapsed ? "ti ti-angle-down arrow-icon" : "ti ti-angle-up arrow-icon"} 
              style={{ fontSmooth: true, fontWeight: 'bold', fontSize: '18px', WebkitFontSmoothing: 'antialiased' }} 
            />
          </button>
        </div>
    );
  }

  rushOnly = () => {
    const { load } = this.state;

    return load.priority === 'rush' ? (
      <div className="rush-fixed-column">
          <img src="assets/images/rush.svg" alt="" />
        </div>
    ) : (
      <div className="rush-fixed-column">
          <div style={{ minWidth: '24px' }}></div>
        </div>
    );
  }

  paymentType = () => {
    const { load } = this.state;
    const paymentTerm = getPaymentTerm(load.is_cod);
    return (
      <div className={load.priority === 'rush' ? "payment-fixed-column rush-color" : "payment-fixed-column"}>
        <div className="payment-box">{paymentTerm}</div>
      </div>
    );
  }

  offerDetails = () => {
    const { load, hauling } = this.state;
    const offerPrice = hauling; // load.offer ???
    var offerPricePerMiles = pricePerMile(offerPrice, load.miles);

    return (
      <div className="four-column-container">
        {this.paymentType()}
        {this.rushOnly()}
        <div className={load.priority === 'rush' ? "flexible-column rush-color" : "flexible-column"}>
          <div className="flexible-row top-row" style={{ minWidth: '138px' }}>${Math.round(offerPrice)}</div>
          <div className="flexible-row bottom-row">${offerPricePerMiles}/mi</div>
        </div>
        {this.expandButton()}
      </div>
    );
  }

  timeAgoLine = () => {
    const { load } = this.state;
    const timeAgoResult = timeAgo(load.date_update);

    return (
      <div className='time-ago-row'>
        <span className='easy-text time-ago-text'>
          {timeAgoResult}
        </span>
      </div>
    );
  }

  offerTitle = (loadIdTitle, brokerTitle) => {
    return (
      <div className="title">{loadIdTitle} {brokerTitle}</div>
    );
  }

  render() {
    const { collapsed, load } = this.state;
    
    const loadIdTitle = load.load_id ? load.load_id : 'N/A';

    const routePolyline = load.route_polyline;
    const distanceResult = pluralizeMiles(load.miles);
    const originShortAddress = `${load.origin_data.city}, ${load.origin_data.state} ${load.origin_data.zip}`;
    const destinationShortAddress = `${load.destination_data.city}, ${load.destination_data.state} ${load.destination_data.zip}`;
    
    const firstCar = load.carData[0] || {};
    const firstCarYear = firstCar.year;
    const firstCarMake = firstCar.car_maker_name;
    const firstCarModel = firstCar.car_model_name;
    const firstCarType = firstCar.car_type;

    // load.carData.push({ year: '2020', car_maker_name: 'Ford', car_model_name: 'Edge', car_type: 'SE AWD' });

    const secondCar = load.carData[1] || {};    
    const pluCarsCount = load.carData.length - 2;
   
    if (load.origin_data === null) {
      load.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const originTitle = formatAddress(load.origin_data.address);
    const originAddress = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? load.origin_data.address : originTitle;

    if (load.destination_data === null) {
      load.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const destinationTitle = formatAddress(load.destination_data.address);
    const destinationAddress = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? load.destination_data.address : destinationTitle;

    const brokerPresent = load.creator_data ? true : false;
    const brokerCompany = brokerPresent ? 
      (load.creator_data.company ? load.creator_data.company : "-")
      : '-';
    const brokerName = brokerPresent ? 
      (load.creator_data.first_name && load.creator_data.last_name ?
      (load.creator_data.first_name + " " + load.creator_data.last_name).trim() : "-")
      : '-';
    const brokerTitle = brokerPresent && (load.creator_data.company ? load.creator_data.company : brokerName);

    const brokerAddress = brokerPresent ? load.creator_data.address : '';
    const brokerPhone = brokerPresent ? load.creator_data.phone : '';
    const brokerEmail = brokerPresent ? load.creator_data.email : '';

    return collapsed ? (
      <div className="board-container">
        <div className={`board-center-column ${collapsed ? 'collapsed' : 'expanded'}`} style={{ }}>
          {/* First row */}
          {this.timeAgoLine()}

          {/* Next section, placed below the first row */}
          <div className='main-details-container'>
            <div className='load-details-container'>
              {this.loadDetails( originShortAddress, firstCarYear, firstCarMake, firstCarModel, firstCarType, brokerPresent, brokerTitle, destinationShortAddress, secondCar, pluCarsCount, distanceResult, originAddress, destinationAddress, routePolyline, loadIdTitle )}
            </div>
            <div className='offer-details-container'>
              {this.offerDetails()}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="board-container" >
        <div className={`board-center-column ${collapsed ? 'collapsed' : 'expanded'}`} style={{ }}>
          <div className="board-full-first-row">
            {this.offerTitle(loadIdTitle, brokerTitle)}
            {load.priority === "rush" ? this.rushOnly() : null}
            {this.paymentType()}
            <div className="board-full-flex-grow">
              {this.timeAgoLine()}
            </div>
            {this.expandButton()}
          </div>
          <div className="board-full-second-row">
            <div className='load-details-container'>
              {this.loadDetails( originShortAddress, firstCarYear, firstCarMake, firstCarModel, firstCarType, brokerPresent, brokerTitle, destinationShortAddress, secondCar, pluCarsCount, distanceResult, originAddress, destinationAddress, routePolyline, loadIdTitle )}
            </div>
            <div className='load-details-container' style={{ alignItems: 'flex-start' }}>
              {this.loadExpandedDetails( brokerCompany, brokerName, brokerAddress, brokerPhone, brokerEmail )}
            </div>
            <div className='offer-details-container' style={{ maxWidth: '542px' }}>
              {this.offerForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadCardView;