export const trailerType = ["Enclosed", "Open"]
export const cargoType = ["Cars", "Dry van", "Fridge", "Flatbed"]
export const servicesOfferType = ["Dispatch", "Billing", "MC/DOT", "Insurance"]

export const languageList = [
    { label: "English", value: "EN"},
    { label: "Spanish", value: "ES"},
    { label: "Russian", value: "RU"},
    { label: "Turkish", value: "TK"},
    { label: "Arabic", value: "AR"},
    { label: "Georgian", value: "GE"},
    { label: "Armenian", value: "HY"},
    { label: "Ukrainian",  value: "UA"},
    { label: "Bulgarian", value: "BG"}
]

export const statesCollumForAll = [
    "ALL",
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]
