import React from 'react';

const SubscriptionContext = React.createContext();

export function isSubscriptionFailed(subscriptionStatus) {
    return (isSubscriptionTerminated(subscriptionStatus) || isSubscriptionSuspended(subscriptionStatus)) && !isSubscriptionActive(subscriptionStatus)
}

export function isSubscriptionTerminated(subscriptionStatus) {
    return subscriptionStatus === 'canceled' || subscriptionStatus === 'unpaid' || subscriptionStatus === 'incomplete_expired'
}

export function isSubscriptionSuspended(subscriptionStatus) {
    return subscriptionStatus === 'past_due' || subscriptionStatus === 'incomplete'
}

export function isSubscriptionActive(subscriptionStatus) {
    return subscriptionStatus === 'trialing' || subscriptionStatus === 'active' || subscriptionStatus === '' || subscriptionStatus === null
}

export default SubscriptionContext;