import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import './style.css'
import SubscriptionAPI, { SUBSCRIPTION_PRICE } from "../../../api/subscriptionAPI";

class UserPlan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      driversCount: 0,
      currentDrivers: 0,
      price: SUBSCRIPTION_PRICE,
      subscribeStatus: 'new',
      upgradePrice: null
    };
  }

  async componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    }

    const data = await SubscriptionAPI.GetSubscribeData()
    if (data.data.status === 200) {

      this.setState({
        driversCount: data.data.data.current_amount,
        currentDrivers: data.data.data.current_amount,
        subscribeStatus: 'old',
        subscriptionStart: data.data.data.current_period_start_date,
        subscriptionEnd: data.data.data.current_period_end_date
      })
    } else {
      console.warn('Subscription - ERROR', data.data)
    }
  }

  driversDecrement = () => {
    const {driversCount} = this.state

    driversCount > 0 ?
      this.setState({driversCount: this.state.driversCount - 1}) :
      this.setState({driversCount: 0})
  }

  driversIncrement = () => {
    this.setState({driversCount: +this.state.driversCount + 1 > 10000 ? 10000 : +this.state.driversCount + 1})
  }

  submitHandler = () => {
    const {openPopup} = this.props
    const {driversCount, subscribeStatus, currentDrivers, subscriptionStart, subscriptionEnd} = this.state

    openPopup(true, driversCount, subscribeStatus, currentDrivers, subscriptionStart, subscriptionEnd)
  }

  render() {

    const {dashboard = false, dashboardText = false} = this.props;
    const {driversCount, currentDrivers, upgradePrice, price } = this.state

    return (
      <AUX>

        <div className="user-plan">
          {dashboard ? null :
            (<div className="user-plan-logo">
              <img src="assets/images/shortLogo.png" alt="logo"/>
            </div>)}
          <div className="user-plan-row">
            <div className="user-plan-col">
              <div className="user-plan-info">
                <h4>Our flexible plans</h4>
                <p>Pay by month only for active drivers, <br/>
                  and cancel at any time*.
                </p>
                <ul className="user-plan-info-list">
                  <li><i className="fas fa-check"/>Create unlimited loads quantity</li>
                  <li><i className="fas fa-check"/>Assign loads to your drivers</li>
                  <li><i className="fas fa-check"/>See status of your loads in real time</li>
                </ul>
                <div className="user-plan-info-drivers">
                  <p>How many drivers do you need to add?</p>
                  <div className="user-plan-info-drivers-count">
                    <div className="input-group">
                      <div
                        className="input-group-append count-minus"
                        onClick={this.driversDecrement}>
                          <span className="input-group-text">
                            <i className="mdi mdi-minus"/>
                          </span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        maxLength="10000"
                        onChange={(e) => this.setState({driversCount: e.target.value > 10000 ? 10000 : e.target.value})}
                        value={driversCount}/>
                      <div
                        className="input-group-append count-plus"
                        onClick={this.driversIncrement}>
                          <span className="input-group-text">
                            <i className="mdi mdi-plus"/>
                          </span>
                      </div>
                    </div>
                    {dashboard && currentDrivers === driversCount ?
                      <button className="btn custom-btn custom-btn-blocked" style={{marginLeft: '10px'}}>Upgrade</button> : null}
                    {dashboard && currentDrivers < driversCount ?
                      <button onClick={this.submitHandler} className="btn custom-btn" style={{marginLeft: '10px'}}>Upgrade</button> : null}
                    {dashboard && currentDrivers > driversCount ?
                      <button onClick={this.submitHandler} className="btn custom-btn" style={{marginLeft: '10px'}}>Downgrade</button> : null}
                  </div>
                </div>
                {dashboardText ?
                  <div className="user-plan-info-sup">
                    *All sales are final. We do not provide refunds for already paid licenses.
                    You can cancel your subscription plan anytime. However your license remains active till the end of billing period
                  </div>
                  :
                  <div className="user-plan-info-sup">You may be able to choose another option during month</div>
                }

              </div>
            </div>
            <div className="user-plan-col">
              <div className="user-plan-view">
                <div className="user-plan-view-title">Goforit monthly plan</div>

                <ul className="user-plan-view-list">
                  <li><p>Your monthly payment ${SUBSCRIPTION_PRICE} x {driversCount} drivers x <br/> 1 month</p><span>${driversCount * price}</span></li>
                  <li><p>Tax</p><span>$0</span></li>
                  <li><p>Today's total</p><span>${currentDrivers >= driversCount ? 0 : upgradePrice || driversCount * price}</span></li>
                </ul>
              </div>
            </div>
          </div>

          {/*<div className="user-plan-submit">*/}
          {/*<button*/}
          {/*onClick={this.submitHandler}*/}
          {/*className="btn btn-lg btn-block custom-btn">*/}
          {/*Submit*/}
          {/*</button>*/}
          {/*</div>*/}


        </div>

        <div className="m-t-40 text-center font-14">
          <p>Have a question? <a href='https://goforitapp.com/support/' className="font-500"> Support</a></p>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(UserPlan);
