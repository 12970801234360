import axios from 'axios';
import config from '../config';

const basicAxios = axios.create({baseURL: config.serverUrl});


export default class MapAPI {

  static async GetCoordinatesByValues(data) {

    const values = Object.values(data)
    const searchString = encodeURI(values.map((item) => item.replace(/ /g, '+',)).join(' '))

    return basicAxios.get(`https://nominatim.openstreetmap.org/search?q=${searchString}&limit=1&format=geojson`, {}).then((response) => {
      return response.data.features.length ? response.data.features[0].bbox : response.data.features
    }).catch((error) => {
      console.log('error', error)
    });
  }

  static async GetDirections(data) {
    return { 
      miles: null,
      origin: {
        lat: null,
        lon: null,
      },
      destination: {
        lat: null,
        lon: null,
      }
    };
  }
}



