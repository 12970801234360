import React, { Component } from "react";
import uuidv4 from "uuid";

class ReleaseFiles extends Component {
    render() {
        return (
            <div className="form-group">
                <label htmlFor="">Attach release</label>
                <div className="load-new-model">
                    <div className="load-new-model-header">
                        {(this.props.attachedFiles && this.props.attachedFiles.length > 0) && this.props.attachedFiles.map((item, i) => {
                            return (
                                <div
                                    key={uuidv4()}
                                    className={"load-new-file-header-item"}
                                >
                                    <div onClick={async (e) => this.props.downloadFile(item, i)}>
                                        {item.name ? item.name : item.fileName}
                                    </div>
                                    <span onClick={async (e) => this.props.removeFile(item, i)}/>
                                </div>
                            )
                        })}

                        <div
                            className="main-content-header-right"
                        > 
                            <label 
                                className={ "load-new-model-add btn custom-btn" }
                                htmlFor="addFile" 
                                style={{ color: 'white', margin: '0px', padding: '3px 0px 3px 0px', width: '100px' }}
                            >
                                <input type="file" onChange={async (e) => this.props.addFile(e)} id="addFile"/>
                                Add File
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReleaseFiles;