import React, {useEffect, useState} from "react";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";

import UsersAPI from "../../../api/userApi";

import config from "../../../config";
import AUX from "../../../hoc/Aux_";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import ProfileInfo from "./components/ProfileInfo";
import FooterBlock from "./components/FooterBlock";
import Performance from "./components/Performance";
import HeaderContent from "./components/HeaderContent";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import SubscriptionContext from "../../../components/Layout/SubscriptionContext"

import "./style.css"
import {identity} from "rxjs";

const Dispatcher = () => {
    const [isAlert, setIsAlert] = useState(false)
    const [state, setState] = useState(null)
    const [subscriptionStatus, setSubscriptionStatus] = useState(null)

    SubscriptionAPI.GetSubscribeData().then(subscriptionData => {
        if (subscriptionData.data.status === 500 && subscriptionData.data.data) {
            setSubscriptionStatus(subscriptionData.data.data.status); 
          }
    });

    const saveChanges = async (data) => {
        if (data) {
            const res = await UsersAPI.UpdateUser(data)

            if (res.status === 200) {
                let userData = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}
                userData = {...userData, ...data}
                localStorage.setItem("userInfo", JSON.stringify(userData))
                setIsAlert(true)
            }
        } else {
            setIsAlert(false)
        }
    };

    useEffect(() => {
        isAlert && setTimeout(() => setIsAlert(false), 5000)
    }, [isAlert])

    useEffect(() => {
        const userData = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};

        try {
            userData.company.services_offered = JSON.parse(userData.company.services_offered).filter(identity);
        } catch (e) {}

        try{
            userData.company.states = JSON.parse(userData.company.states).filter(identity);
        } catch (e) {}

        try{
            userData.company.trailer_type = JSON.parse(userData.company.trailer_type).filter(identity);
        } catch (e) {}

        try{
            userData.company.cargo_type = JSON.parse(userData.company.cargo_type).filter(identity);
        } catch (e) {}

        try{
            userData.company.language = JSON.parse(userData.company.language).filter(identity);
        } catch (e) {}

        setState(userData)

        return () => {
            setState(null)
        }
    }, [setState])

    if (!state) {
        return null
    }

    return (
        <AUX>
            <main>
                <div id="wrapper">
                    <SubscriptionContext.Provider value={subscriptionStatus}>
                        <CustomSideBar page={"dispatcher"}/>
                    </SubscriptionContext.Provider>
                    <CustomTopBar/>
                    <div className="content-page">
                        <div className="content">
                            <HeaderContent/>
                            <ProfileInfo onSaveChanges={saveChanges}
                                         userData={state}
                            />
                            <Performance alert={isAlert}
                                         userData={state}
                                         onSaveChanges={saveChanges}
                            />
                            <FooterBlock/>
                        </div>
                    </div>
                </div>
            </main>
        </AUX>
    )
}


const DispatcherInjected = injectStripe(Dispatcher)

const Headline = () => {

    return (
        <div>
            <StripeProvider apiKey={config.stripeAPI}>
                <Elements>
                    <DispatcherInjected/>
                </Elements>
            </StripeProvider>
        </div>
    )

};
export default Headline;
