import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';
import './style.css'

class ConfirmEmail extends Component {

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    }
  }

  render() {
    return (
      <AUX>
        <div className="wrapper-page">
          <div className="custom-login card overflow-hidden account-card mx-3 custom-confirm-email">
            <div className="custom-login-header">
              <div className="logo logo-admin">
                <img src="assets/images/shortLogo.png" alt="logo"/>
              </div>
            </div>

            <div className="confirm-email-content">
              <h4>Confirm email</h4>
              <p>The confirmation link was sent to your email box.<br/>
                It may take several minutes. Please, press the link in the email, to complete your registration.
              </p>
            </div>

          </div>

          <div className="m-t-40 text-center custom-login-text">
            <p>Already have an account? <Link to="login" className="font-500"> Login</Link></p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ConfirmEmail);
