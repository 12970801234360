import actionType from './dashboardActionType';

const { GET_DASHBOARD_DATA } = actionType;

const initialState={
  dashboardData: {}
}

const dashboardReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_DASHBOARD_DATA:
      return{
        ...state,
        dashboardData: action.data
      };
    default :
      return state;
  }
}

export default dashboardReducer;
