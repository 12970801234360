const downloadCsv = (csvString = '', filename = 'exported-loads') => {
  const blob = new Blob([csvString])
  if (window.navigator.msSaveOrOpenBlob){
    window.navigator.msSaveBlob(blob, filename + '.csv')
  }
  else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, {
      type: 'text/plain'
    })
    a.download = filename + '.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export default downloadCsv