import React, { Component } from 'react';
import "./style.css";

class LoadViewSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapModeSelected: this.props.mapViewModeOn || false,
    };
  }

  render() {
    const { mapModeSelected } = this.state;

    return (
      <div className="board-table-btn-group-control" style={{height: '100%' }}>
        <div className="board-table-btn-group-toggle" style={{height: '100%' }}>
          <label htmlFor={"item.id"} title={ "Switcher of load payment status" } style={{height: '100%' }}>
            <input
              type="checkbox"
              id={"item.id"}
              checked={!mapModeSelected}
              onChange={(e) => this.setState({ mapModeSelected: (!mapModeSelected)}, () => this.props.onToggleMapMode && this.props.onToggleMapMode(!mapModeSelected))}
              style={{height: '100%' }}
            />
            <span className="board-table-btn-group-toggle-icon">
              {mapModeSelected ? 
                (<span className="fas fa-map"  style={{height: '100%', color: 'white' }}/>) : 
                (<span className="fas fa-list"  style={{height: '100%', color: 'white' }}/>)}
            </span>
            <span className="board-table-btn-group-toggle-active" style={{height: '100%' }} >
              <span className="fas fa-map"/>
            </span>
            <span className="board-table-btn-group-toggle-notActive" style={{height: '100%'}} >
              <span className="fas fa-list"/>
            </span>
          </label>
        </div>
      </div>
    );
  }
}

export default LoadViewSelector;