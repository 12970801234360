import React, { useState } from 'react';
import './PriceInput.css'; // For custom styles

const PriceInput = ({
  id = 'price-input',
  name = 'hauling',
  label = 'Price',
  iconSrc,
  currencySymbol = '$',
  inputValue,
  textValue = '',
  tipText = '',
  onInputChange,
  labelClass = 'price-input-label',
  containerClass = 'price-input-container',
  inputClass = 'price-input',
  iconClass = 'price-input-icon',
  textClass = 'price-input-text',
  tipClass = 'price-input-tip',
  textColor = 'black',
  disabled = false
}) => {

  const [selectedPrice, setSelectedPrice] = useState(inputValue || 0);

  const handlePriceChange = (e) => {
    const price = e.target.value;
    let newPrice = parseFloat(price);

    if (isNaN(newPrice) || newPrice < 0) {
      newPrice = 0;
    }

    setSelectedPrice(newPrice);

    if (onInputChange) {
      onInputChange(e);
    }
  };

  return (
    <div className={containerClass}>
      <label className={labelClass}>{label}</label>
      <div className={`price-input-wrapper ${disabled ? 'price-input-wrapper-disabled' : ''}`}>
        {iconSrc && <img src={iconSrc} alt="Icon" className={iconClass} />}
        <span className="currency-symbol" style={{ color: textColor }}>{currencySymbol}</span>
        <input
          id={id}
          name={name}
          type="text"
          disabled={disabled}
          className={inputClass}
          // defaultValue={inputValue}
          value={selectedPrice}
          onChange={(e) => handlePriceChange(e)}
          style={{ color: textColor }}
        />
        <span className={textClass} style={{ color: textColor }}>{textValue}</span>
      </div>
      <p className={tipClass}>{tipText}</p>
    </div>
  );
};

export default PriceInput;