import axios from "axios";
import config from "../config";
import CryptoJS from "crypto-js";

const basicAxios = axios.create({ baseURL: config.serverUrl });
const cryptoText = CryptoJS.MD5(
  config.security_string + config.security_token,
  config.security_token
);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export default class DocumentsApi {
  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static async sendFiles(file) {
    const { name } = file;
    const base64 = await this.getBase64(file);
    const formattedBase64 = base64.split(",")[1];
    const bodyFormData = new FormData();
    bodyFormData.append("file_name", name);
    bodyFormData.append("file", formattedBase64);

    const auth_key = JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).auth_key
      : "";

    return basicAxios
      .post(`/documents`, bodyFormData, {
        params: {
          auth_string: config.security_string,
          auth_crc: convertText,
          app_ver: config.app_ver,
          auth_key: auth_key,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return error;
      });
  }

  static async list() {
    const auth_key = JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).auth_key
      : "";

    return basicAxios
      .get(`/documents`, {
        params: {
          auth_string: config.security_string,
          auth_crc: convertText,
          app_ver: config.app_ver,
          auth_key: auth_key,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static async delete(id) {
    const auth_key = JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).auth_key
      : "";

    return basicAxios
      .delete(`/documents/${id}`, {
        params: {
          auth_string: config.security_string,
          auth_crc: convertText,
          app_ver: config.app_ver,
          auth_key: auth_key,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
}
