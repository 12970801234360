import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberInput = ({ value, onChange }) => {
  const [phoneNumberInFocus, setPhoneNumberInFocus] = useState(false);
  const [phoneNumberClicked, setPhoneNumberClicked] = useState(false);

  const isPhoneNumberValid = () => {
    return !phoneNumberClicked || value.length > 9;
  };

  const inputStyle = {
    width: '100%',
    height: 'calc(2.25rem + 2px)',
    paddingRight: 5,
    borderColor: isPhoneNumberValid() ? undefined : '#dc3545',
    backgroundColor: phoneNumberInFocus ? 'transparent' : 'var(--inputBackgroundColor)',
  };

  return (
    <div>
      <label htmlFor="phone-number-input" style={{ color: isPhoneNumberValid() ? undefined : '#dc3545', fontSize: '14px' }}>Phone number*</label>
      <PhoneInput
        id="phone-number-input"
        buttonStyle={{
          borderLeftColor: 'transparent',
          borderTopColor: 'transparent',
          borderBottomColor: 'transparent',
          backgroundColor: 'transparent',
          color: '#000',
          margin: '4px',
        }}
        dropdownStyle={{ zIndex: 1000, height: '160px' }}
        inputClass={isPhoneNumberValid() ? 'is-touched is-dirty av-valid form-control' : 'is-touched is-pristine av-invalid is-invalid form-control'}
        inputStyle={inputStyle}
        country={'us'}
        name="phone"
        label="Phone number*"
        required
        errorMessage="Invalid phone"
        value={value}
        onChange={onChange}
        onFocus={() => { setPhoneNumberInFocus(true); setPhoneNumberClicked(true); }}
        onBlur={() => { setPhoneNumberInFocus(false); }}
      />
      <div className="invalid-feedback" style={isPhoneNumberValid() ? { display: "none" } : { display: "block", paddingBottom: 16 }}>
        Invalid phone
      </div>
    </div>
  );
};

export default PhoneNumberInput;