import React, {Component} from 'react';


import 'font-awesome/css/font-awesome.min.css';

import "react-datepicker/dist/react-datepicker.css";

import CustomLogin from '../CustomComponents/Login/CustomLogin'
import ForgetPass from '../CustomComponents/ForgetPass/ForgetPass'
import CustomSignup from '../CustomComponents/Signup/CustomSignup'
import ConfirmEmail from '../CustomComponents/ConfirmEmail/ConfirmEmail'
// eslint-disable-next-line no-unused-vars
import UserPlan from '../CustomComponents/UserPlan/UserPlan'
import MainPage from '../CustomComponents/MainPage/MainPage'
import Help from '../CustomComponents/Help/Help'
import Clients from '../CustomComponents/Clients/Clients'
import Subscription from '../CustomComponents/Subscription/Subscription'
import HistoryPage from '../CustomComponents/History/History'
import Transporters from '../CustomComponents/Transporters/Transporters'
import Loads from '../CustomComponents/Loads/Loads'
import Load from '../CustomComponents/Load/Load'
import UpdateLoad from '../CustomComponents/UpdateLoad/Load'
import Settings from '../CustomComponents/Settings/Settings'
import ViewLoad from '../CustomComponents/ViewLoad/Load'

import { Route, Switch, Redirect } from 'react-router-dom';
import Dispatcher from "../CustomComponents/Dispatcher/Dispatcher";
import Loadboard from '../CustomComponents/Loadboard/Loadboard';


const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => (auth.authenticate() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: props.location},
        }}
      />
    ))
    }
  />
);

const auth = {

  isAuthenticate: false,
  authenticate() {
    window.screenY = 0;

    if (JSON.parse(localStorage.getItem('userInfo'))) {
      this.isAuthenticate = true;
    }

    return this.isAuthenticate;
  },
};

class mainbuilder extends Component {
  render() {
    return (
      <Switch>

        <Route path="/forgetPass" component={ForgetPass}/>
        <Route path="/login" component={CustomLogin}/>
        <Route path="/login/" component={CustomLogin}/>
        <Route path="/signup" component={CustomSignup}/>
        <Route path="/confirmEmail" component={ConfirmEmail}/>
        <PrivateRoute exact path="/dashboard" component={MainPage}/>
        <PrivateRoute exact path="/help" component={Help}/>
        <PrivateRoute exact path="/clients" component={Clients}/>
        <PrivateRoute exact path="/subscription" component={Subscription}/>
        <PrivateRoute exact path="/transporters" component={Transporters}/>
        <PrivateRoute exact path="/history" component={HistoryPage}/>
        <PrivateRoute path="/loadboard" component={Loadboard}/>
        <PrivateRoute path="/loads" component={Loads}/>
        <PrivateRoute path="/load/:id/view" component={ViewLoad}/>
        <PrivateRoute path="/load/:id/client" component={Load}/>
        <PrivateRoute path="/load/:id" component={UpdateLoad}/>
        <PrivateRoute path="/load" component={Load}/>
        <PrivateRoute exact path="/settings" component={Settings}/>
        <PrivateRoute exact path="/profile" component={Dispatcher}/>
        <Route path="/" component={CustomLogin}/>

      </Switch>
    );
  }
}

export default mainbuilder;
