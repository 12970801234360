import config from '../config';

export function googleSearch(query) {
    window.open("https://google.com/search?q=" + encodeURIComponent(query));
}

export function googleMapSearch(query) {
    window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(query));
}

export function googleMapRoute(origin, destination) {
    window.open("https://www.google.com/maps/dir/" + encodeURIComponent(origin) + "/" + encodeURIComponent(destination));
}

export function phoneCall(number) {
    window.open("tel:" + number, "_self");
}

export function mailTo(email, clientName) {
    const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
    const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

    window.open(`mailto:${email}?subject=Mail from GoForIt Dispatcher&body=Dear ${clientName}, ... ${userName} ${lastName}`, "_self");
}

export function openOnlineBol(loadId) {
    const userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''

    window.open(`${config.serverUrl}/commons/show-photo/${loadId}/${userId}`);
}

export function openTutorialVideo() {
    window.open(`https://www.youtube.com/watch?v=anUQR1KUXWY`);
}

export function openAddTransportersVideo() {
    window.open(`https://www.youtube.com/watch?v=anUQR1KUXWY&t=48s`);
}