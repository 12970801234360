import React from 'react';

const RouteLineView = ({ 
  dotSize = 10, 
  originColor = '#8A98AB', 
  destinationColor = '#1FA84D', 
  lineColor = '#8A98AB' 
}) => {
  return (
    <div style={styles.container}>
      <div style={{ ...styles.dot, width: dotSize, height: dotSize, backgroundColor: originColor }}></div>
      <div style={{ ...styles.line, borderLeft: `2px dashed ${lineColor}` }}></div>
      <div style={{ ...styles.dot, width: dotSize, height: dotSize, backgroundColor: destinationColor }}></div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensure dots and line occupy the full height
    height: '100%', // Occupy all available height
    minHeight: '50px', // Minimum height to prevent collapsing
  },
  dot: {
    borderRadius: '50%',
    flexShrink: 0, // Prevent the dot from shrinking
  },
  line: {
    flexGrow: 1, // Line should grow to fill the space between the dots
    width: '2px',
  },
};

export default RouteLineView;