import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css'; // You can customize CSS here
import { formatInputDate } from '../../../helpers/DateUtils';

const DateInput = ({
  id = 'date-input',
  name = 'date-input',
  label = 'Select Date',
  inputValue = new Date(),
  onDateChange,
  labelClass = 'date-input-label',
  inputClass = 'date-input',
  iconClass = 'date-input-icon',
  iconSrc = null,
  disabled = false,
  afterDate = new Date()
}) => {
  // Initialize selectedDate with the corrected inputValue
  const [selectedDate, setSelectedDate] = useState(inputValue < afterDate ? afterDate : inputValue);

  useEffect(() => {
    if (selectedDate < afterDate) {
      setSelectedDate(afterDate);

      if (onDateChange) {
        onDateChange({ target: { name, value: formatInputDate(afterDate) } });
      }
    }
  }, [afterDate]);

  const handleDateChange = (selectedDate) => {
    let date = selectedDate;

    if (!date) {
      date = new Date();
    } else if (date < afterDate) {
      date = afterDate;
    }

    setSelectedDate(date);

    if (onDateChange) {
      onDateChange({ target: { name, value: formatInputDate(date) } });
    }
  };

  return (
    <div className="date-input-wrapper">
      <label className={labelClass}>{label}</label>
      <div className={`date-input-icon-wrapper${disabled ? '-disabled' : ''}`}>
        <DatePicker
          id={id}
          name={name}
          selected={selectedDate}
          onChange={handleDateChange}
          className={inputClass}
          dateFormat="MM/dd/yyyy"
          disabled={disabled} // Disable the DatePicker when disabled is true
          minDate={afterDate || new Date()} // Disable past dates
        />
        {iconSrc && (
          <img src={iconSrc} alt="Icon" className={iconClass} />
        )}
      </div>
    </div>
  );
};

export default DateInput;