/* eslint-disable no-undef */

import MarkerWithLabel from "markerwithlabel";
import MarkerClusterer from "@googlemaps/markerclustererplus";

// check if 2 Markers at the same position and add tiny distinction between
const unstackMarkers = (marker, otherMarkers) => {
  const positionKey = marker.startPoint ? "startPoint" : "position";

  let {
    lat,
    lon
  } = marker[positionKey];
  const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
  if (otherMarkers.length !== 0) {
    for (let i = 0; i < otherMarkers.length; i++) {
      const existingMarker = otherMarkers[i];
      const pos = existingMarker.getPosition();

      if (latLng.equals(pos)) {
        const a = 360.0 / otherMarkers.length;
        marker[positionKey].lat =
          pos.lat() + -0.00004 * Math.cos(((+a * (i + 1)) / 180) * Math.PI); // Х
        marker[positionKey].lon =
          pos.lng() + -0.00004 * Math.sin(((+a * (i + 1)) / 180) * Math.PI); // Y
      }
    }
  }
};

const getContent = (load, newDistance) => {
  const name =
    load.tooltip.first_name && load.tooltip.last_name ?
    `${load.tooltip.first_name} ${load.tooltip.last_name}` :
    "Invite not accepted";
  const style = `custom-marker-status custom-marker-status-${load.tooltip.status}`;

  let milesBlock = `<div class="custom-marker-blue">
                      Miles: <b>${parseFloat(load.tooltip.miles)}</b>
                      <div class="car">Cars: ${load.tooltip.car_count}</div>
                    </div>`;

  if (newDistance && newDistance > 0) {
    milesBlock = `<div class="custom-marker-blue">
                    Miles: <b>${parseFloat(load.tooltip.miles)}</b>
                </div>
                <div class="custom-marker-red">
                    New Miles: <b>${newDistance}</b>
                    <div class="car">Cars: ${load.tooltip.car_count}</div>
                </div>`;
  }

  return `<div class="custom-marker">
            <div class="custom-marker-name">${name ? name.replace(/ /g, "\u00A0") : "N/A"}</div>
            <div class="custom-marker-code">${load.tooltip.load_id ? load.tooltip.load_id.replace(/ /g, "\u00A0") : '-'}</div>
            <div class="${style}">${load.tooltip.status}</div>
            <div class="custom-marker-direction">${load.tooltip.origin.address}
              <br/>${load.tooltip.destination.address}
            </div>
            <div class="custom-marker-blue">Price: <b>$${load.tooltip.hauling}</b></div>
            ${milesBlock}
          </div>`;
};

const getOpportunityContent = (
  load,
  onSendOffer,
  onConfirmOffer,
  onRejectOffer,
  renderDate,
  deleteOffer
) => {
  const id = `info-window-${load.tooltip.id}-${+new Date()}`;

  window.handleOpportunitySubmit = async () => {
    const container = document.getElementById(id);
    const form = container.querySelector("form");
    const offerData = {
      load_id: form.elements[0].value,
      pick_up_date: form.elements[1].value,
      delivery_date: form.elements[2].value,
      price: form.elements[3].value,
    };
    await onSendOffer(offerData);
    return false;
  };

  window.onConfirmOfferByDispatcher = () => {
    onConfirmOffer(offerForDriver.id, load.load_id);
  };

  window.onReject = () => {
    deleteOffer(offer.id);
  };

  window.onRejectOfferByDispatcher = function () {
    onRejectOffer(offerForDriver.id);
  };

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const offer =
    load.offers &&
    load.offers.find(
      (offer) =>
      offer.author_id === userInfo.id || offer.dispatcher_id === userInfo.id
    );

  const isOfferSent =
    offer && offer.author_id === userInfo.id && offer.confirmed === 0;

  const isOfferAccepted = offer && offer.confirmed === 1;

  const offerForDriver =
    load.offers &&
    load.offers.find(
      (offer) => offer.dispatcher_id === userInfo.id && offer.planned_driver_id
    );

  let renderPopupFooter = () => {
    if (isOfferAccepted) {
      return `
        <div>
            <div class="opportunity-row">
                <div class="opportunity-row-item date">${renderDate(
                  offer.pick_up_date
                )} - ${renderDate(offer.delivery_date)}</div>
                <div class="opportunity-row-item price">$${window.Math.floor(
                  offer.hauling
                )}</div>
            </div>
        </div>
      `;
    } else if (!isOfferSent && !offerForDriver) {
      return `
       <form class="opportunities-form" onsubmit="window.handleOpportunitySubmit(); return false;">
         <div class="opportunity-form-row">
            <input name="offer_id" value="${load.id}" style="display: none">
            <input type="date" name="pick_up_date" value="${load.pick_up_date.substr(
              0,
              10
            )}">                
            <input type="date" name="delivery_date" value="${load.delivery_date.substr(
              0,
              10
            )}">   
         </div>
         <div class="opportunity-form-row">
            <input type="text" name="price" placeholder="0$" value="${window.Math.floor(
              load.hauling
            )}">                
            <button type="submit">Send Offer</button>
         </div>             
       </form>
    `;
    } else if (!isOfferSent && offerForDriver) {
      return `
            <div class="opportunity-row">
                <div class="opportunity-row-item date">${renderDate(
                  load.pick_up_date
                )} - ${renderDate(load.delivery_date)}</div>
                <div class="opportunity-row-item price">$${window.Math.floor(
                  load.hauling
                )}</div>
            </div>
            <div class="opportunity-row buttons-container">
              <button onclick="onConfirmOfferByDispatcher()" class="accept-button">Accept</button>
              <button onclick="onRejectOfferByDispatcher()" class="secondary-button">Decline</button>
            </div>
      `;
    } else if (isOfferSent) {
      return `
        <div>
            <div class="opportunity-row">
                <div class="opportunity-row-item date">${renderDate(
                  offer.pick_up_date
                )} - ${renderDate(offer.delivery_date)}</div>
                <div class="opportunity-row-item price">$${window.Math.floor(
                  offer.hauling
                )}</div>
            </div>
            <div class="opportunity-row buttons-container">
              <button disabled>Offer sent</button>
              <button onclick="window.onReject()" class="secondary-button">Cancel</button>
            </div>
        </div>
      `;
    }
  };

  const {
    creator_data,
    origin_data,
    destination_data,
    miles,
    car_count,
    cars,
    load_id,
  } = load;

  const phoneNumber = creator_data.phone ? creator_data.phone : "-";

  return `<div class="opportunities-marker" id="${id}">
             <div class="opportunity-name"><b>${creator_data.company ? creator_data.company.replace(/ /g, "\u00A0") : 'N/A'}</b></div>
             ${
               isOfferAccepted
                 ? `<div class="opportunity-phone"><b>Phone:&nbsp;${phoneNumber ? phoneNumber.replace(/ /g, "\u00A0") : '-'}</b></div>`
                 : ""
             }
             <div class="custom-marker-code" style="margin-bottom: 15px">Load&nbsp;${load_id ? load_id.replace(/ /g, "\u00A0") : "-"}</div>
             <div style="display: flex; flex-direction: row">
                <i class="far fa-dot-circle opportunity-dot"></i> <div class="custom-marker-code">${
                  origin_data.address
                }</div>
             </div>
             <div class="opportunity-dot-spacer"></div>
             <div style="display: flex; flex-direction: row">
                <i class="far fa-circle opportunity-dot"></i> <div class="custom-marker-code">${
                  destination_data.address
                }</div>
             </div>
             <div class="opportunity-dot-spacer"></div>
             <div class="opportunities-cars-header">
                  <div class="opportunity-blue">
                     <b>${miles}</b> miles
                  </div>
                  <div class="opportunity-blue">
                     <b>${car_count}</b> cars
                  </div>
             </div>
             <div class="opportunities-cars">
                ${cars.map(
                  (car) =>
                    `<p>2018 <span class="opportunity-blue">${
                      car.car_maker_name
                    }</span>, ${car.car_model_name}${
                      car.car_type ? `, ${car.car_type}` : ""
                    }</p>`
                )}
             </div>
             ${renderPopupFooter()} 
          </div>`;
};

const getContentCar = (load) => {
  const name =
    load.tooltip.first_name && load.tooltip.last_name ?
    `${load.tooltip.first_name} ${load.tooltip.last_name}` :
    "Invite not accepted";
  const loads = load.tooltip.loads || [];

  const loadsBlock = loads.reduce((acc, el) => {
    if (!el.id) {
      return acc;
    }

    return (
      acc +
      `<div class="custom-marker-blue">Load:&nbsp;<b>${
        el.load_name || "Unassigned"
      }</b><br><div>Cars: ${el.car_count}</div></div>`
    );
  }, "");

  return `<div class="custom-marker">
            <div class="custom-marker-name">${name ? name : 'N/A'}</div>
            <div class="custom-marker-code">Phone:&nbsp;${load.tooltip.phone ? load.tooltip.phone.replace(/ /g, "\u00A0") : '-'}</div>
            ${loadsBlock}
          </div>`;
};

const getTransporterMarker = (data, map) => {
  const lat = parseFloat(data.position.lat);
  const lng = parseFloat(data.position.lon);

  if (!lat || !lng) return;
  const position = {
    lat: parseFloat(data.position.lat),
    lng: parseFloat(data.position.lon),
  };

  let MWithLabel = MarkerWithLabel(google.maps);

  return new MWithLabel({
    position: position,
    map: map,
    icon: {
      url: data.tooltip.avatar,
      size: new google.maps.Size(64, 64),
      // anchor: new google.maps.Point(0, 32),
      class: "custom-marker-icon",
    },

    driver_id: data.tooltip.id,

    labelContent: "", // data.tooltip.avatar ? '' : data.tooltip.last_name.charAt(0) + '.' + data.tooltip.first_name.charAt(0)+ '.',
    labelAnchor: new google.maps.Point(9, 46),
    labelClass: "labels",
    labelInBackground: false,
    duration: 1000,
    easing: "easeOutSine",
  });
};

const getOpportunityMarker = (data, map) => {
  const start = {
    lat: parseFloat(data.startPoint.lat),
    lng: parseFloat(data.startPoint.lon),
  };

  const userId = JSON.parse(localStorage.getItem("userInfo")).id;

  const isDispatched = data.offers && data.offers.find((offer) => offer.confirmed === 1);

  const offerForDriver =
    data.offers &&
    data.offers.find(
      (offer) =>
      offer.dispatcher_id === userId &&
      offer.planned_driver_id &&
      offer.confirmed !== 1
    );

  let MWithLabel = MarkerWithLabel(google.maps);

  const carCount =
    data.car_count > 5 ? "opportunity" : `opportunity_${data.car_count}`;

  const marker = new MWithLabel({
    position: start,
    map: map,
    icon: {
      url: isDispatched ?
        `../assets/images/map/load_purple_car.svg` : `../assets/images/map/${carCount}.svg`,
      size: new google.maps.Size(64, 64),
      class: "custom-marker-icon",
    },
    labelContent: data.tooltip.priority || "",
    labelAnchor: new google.maps.Point(46, 20),
    labelClass: data.tooltip.priority ? "label-map-priority" : "",
    labelInBackground: true,
    duration: 1000,
    easing: "easeOutSine",
  });

  if (offerForDriver) {
    marker.setAnimation(google.maps.Animation.BOUNCE);
  }

  return marker;
};

const getMarker = (data, map) => {
  const start = {
    lat: parseFloat(data.startPoint.lat),
    lng: parseFloat(data.startPoint.lon),
  };
  const status = data.tooltip.status;
  const statuses_svg = {
    new: "load_red_car.svg",
    assigned: "load_yellow_car.svg",
    picked: "load_black_car.svg",
  };

  let MWithLabel = MarkerWithLabel(google.maps);

  return new MWithLabel({
    position: start,
    map: map,
    icon: {
      url: "../assets/images/map/" + statuses_svg[status],
      size: new google.maps.Size(64, 64),
      class: "custom-marker-icon",
    },
    labelContent: data.tooltip.priority || "",
    labelAnchor: new google.maps.Point(46, 20),
    labelClass: data.tooltip.priority ? "label-map-priority" : "",
    labelInBackground: true,
    duration: 1000,
    easing: "easeOutSine",
  });
};

export const onMapLoad = (
  state,
  socket,
  onSendOffer,
  onConfirmOffer,
  onRejectOffer,
  renderDate,
  deleteOffer
) => {
  return (map) => {
    const {
      loads,
      opportunities,
      transporters,
      showOffers,
      showLoads,
      showOpportunities,
    } = state;
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      draggable: true,
    });
    const infoWind = new google.maps.InfoWindow({
      content: ""
    });
    const infoWindow = new google.maps.InfoWindow({
      content: ""
    });
    const infoWindowTransporter = new google.maps.InfoWindow({
      content: ""
    });

    let activeTransporterId = null;

    let markerCluster = getMarkerCluster([]);

    const transporterMarkers = [];
    let loadMarkers = [];
    let markers = state.markers;

    let isRouteVisible = {};
    let infoWindows = {};
    let totalDistance = 0;
    let currentMarker = null;
    let lastMarker = null;

    directionsRenderer.addListener("directions_changed", function () {
      const directions = directionsRenderer.getDirections();
      computeTotalDistance(directions);
      if (directions.request.waypoints) {
        const location = directions.request.waypoints[0].location;
        const start = {
          lat: parseFloat(location.lat()),
          lng: parseFloat(location.lng()),
        };
        infoWind.close();
        infoWind.setPosition(start);
        infoWind.setContent(getContent(currentMarker, totalDistance));
        infoWind.open(map);
      }
    });

    function getMarkerCluster(markers) {
      return new MarkerClusterer(map, markers, {
        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 10,
      });
    }

    ///////////////////////////////////////////// LOADS

    function transformMarkerPosition(marker) {
      let {
        startPoint: {
          lat,
          lon
        },
      } = marker;

      const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lon));
      if (markers.length !== 0) {
        for (let i = 0; i < markers.length; i++) {
          const existingMarker = markers[i],
            pos = existingMarker.getPosition();

          if (latLng.equals(pos)) {
            const a = 360.0 / markers.length;
            marker.startPoint.lat =
              pos.lat() + -0.00004 * Math.cos(((+a * (i + 1)) / 180) * Math.PI); // Х
            marker.startPoint.lon =
              pos.lng() + -0.00004 * Math.sin(((+a * (i + 1)) / 180) * Math.PI); // Y
          }
        }
      }
    }

    if (showLoads === true) {
      loads.forEach((load) => {
        if (load.tooltip.status === 'dispatched') {
          return;
        }

        isRouteVisible = {
          ...isRouteVisible,
          [load.tooltip.id]: false
        };
        infoWindows[load.tooltip.id] = new google.maps.InfoWindow({
          content: "",
        });

        transformMarkerPosition(load);

        const googleMarker = getMarker(load, map);

        markerCluster.addMarker(googleMarker);

        markers.push(googleMarker);

        unstackMarkers(load, loadMarkers);

        if (!googleMarker) {
          return null;
        }

        attachClickToLoadMarker(googleMarker, load);
        loadMarkers.push(googleMarker);
      });
    }

    const opportunityAdapter = (load) => {
      return {
        ...load,
        tooltip: {
          id: load.id,
          destination: load.destination_data,
          origin: load.origin_data,
        },
        startPoint: load.origin_data,
        endPoint: load.destination_data,
      };
    };

    const dispatcherId = JSON.parse(localStorage.getItem("userInfo")).id;

    const unconfirmedOpportunities = [];

      opportunities.forEach((item) => {
        if (showOffers === true && (showOpportunities || !item.offers || !item.offers.length)) {
          unconfirmedOpportunities.push(item);
          return;
        }

        const unconfirmedOffers = item.offers ? item.offers.filter(
          (offer) => showOffers === true ? (offer.confirmed >= 0 && item.user_id !== dispatcherId) : (offer.confirmed > 0 && item.user_id !== dispatcherId)
        ) : [];

        if (unconfirmedOffers.length) {
          unconfirmedOpportunities.push(item);
        }
      });

    unconfirmedOpportunities.forEach((opportunity) => {
      const load = opportunityAdapter(opportunity);

      transformMarkerPosition(load);

      const googleMarker = getOpportunityMarker(load, map);

      markerCluster.addMarker(googleMarker);

      markers.push(googleMarker);

      isRouteVisible = {
        ...isRouteVisible,
        [load.tooltip.id]: false
      };
      infoWindows[load.tooltip.id] = new google.maps.InfoWindow({
        content: "",
      });

      unstackMarkers(load, loadMarkers);

      if (!googleMarker) {
        return null;
      }

      attachClickToOpportunityMarker(googleMarker, load);
      loadMarkers.push(googleMarker);
    });

    loadMarkers.forEach((marker) => marker.setVisible(true));

    function attachClickToLoadMarker(googleMarker, loadMarker) {
      googleMarker.addListener("click", function () {
        infoWindow.close();
        infoWindow.setContent(getContent(loadMarker));
        infoWindow.open(map, googleMarker);
        if (!isRouteVisible[loadMarker.tooltip.id]) {
          isRouteVisible = {};
          routeToMap(loadMarker, true);
        } else {
          lastMarker.setMap(null);
          infoWind.close();
          isRouteVisible[loadMarker.tooltip.id] = false;
          infoWindow.close();
          directionsRenderer.setMap(null);
          clearShowRouts();
        }
      });
    }

    function attachClickToOpportunityMarker(googleMarker, loadMarker) {
      googleMarker.addListener("click", function () {
        infoWindow.close();
        infoWindow.setContent(
          getOpportunityContent(
            loadMarker,
            onSendOffer,
            onConfirmOffer,
            onRejectOffer,
            renderDate,
            deleteOffer
          )
        );
        infoWindow.open(map, googleMarker);
        if (!isRouteVisible[loadMarker.tooltip.id]) {
          isRouteVisible = {};
          routeToMap(loadMarker, true);
        } else {
          lastMarker.setMap(null);
          infoWind.close();
          isRouteVisible[loadMarker.tooltip.id] = false;
          infoWindow.close();
          directionsRenderer.setMap(null);
          clearShowRouts();
        }
      });
    }

    function routeToMap(marker, addMarker = false) {
      if (lastMarker) {
        lastMarker.setMap(null);
      }

      currentMarker = marker;
      let {
        startPoint: {
          lat,
          lon
        },
      } = marker;
      let {
        endPoint: {
          lat: endLat,
          lon: endLon
        },
      } = marker;

      directionsService.route({
          origin: {
            lat: parseFloat(lat),
            lng: parseFloat(lon)
          },
          destination: {
            lat: parseFloat(endLat),
            lng: parseFloat(endLon)
          },
          travelMode: google.maps.DirectionsTravelMode.DRIVING,
        },
        function (response, status) {
          isRouteVisible[marker.tooltip.id] = true;
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(response);
            if (addMarker) {
              addFinishMarker(endLat, endLon);
            }
          } else {
            console.error("Directions request failed due to", status);
          }
        }
      );
    }

    transporters.forEach(async (transporter) => {
      unstackMarkers(transporter, transporterMarkers);

      const googleMarker = getTransporterMarker(transporter, map);
      if (googleMarker) {
        attachClickToCarMarker(googleMarker, transporter);
        transporterMarkers.push(googleMarker);
      }
    });

    socket.onmessage = function (evt) {
      const data = JSON.parse(evt.data);
      console.log("[SOCKET IN]", data);
      if (data.SenderId) {
        // data.SenderId = 1537 // TODO TMP
        const indexMarker = transporterMarkers.find((el) => {
          return el.driver_id === data.SenderId;
        });
        if (indexMarker) {
          const new_marker_position = new google.maps.LatLng(
            data.Geo.Lat,
            data.Geo.Lon
          );
          indexMarker.setPosition(new_marker_position);
        }
      }
    };

    function attachClickToCarMarker(googleMarker, marker) {
      googleMarker.addListener("click", function () {
        if (marker.tooltip.id === activeTransporterId) {
          activeTransporterId = null;
          loadMarkers.forEach((anchor) => anchor.setVisible(true));
        } else {
          activeTransporterId = marker.tooltip.id;
          loadMarkers.forEach((loadMarker, index) => {
            loadMarker.setVisible(
              loads[index] &&
              loads[index].tooltip.driver_id === activeTransporterId
            );
          });
        }

        infoWindowTransporter.close();
        infoWindowTransporter.setContent(getContentCar(marker));
        infoWindowTransporter.open(map, googleMarker);

        map.setZoom(15);
        map.panTo(infoWindowTransporter.position);
      });
    }

    google.maps.event.addListener(infoWindowTransporter, "closeclick", () => {
      activeTransporterId = null;
      loadMarkers.forEach((marker) => marker.setVisible(true));
    });

    function computeTotalDistance(result) {
      totalDistance = 0;
      let myroute = result.routes[0];
      for (let i = 0; i < myroute.legs.length; i++) {
        totalDistance += myroute.legs[i].distance.value;
      }
      totalDistance = (totalDistance / 1000 / 1.60934).toFixed(0);
    }

    function clearShowRouts() {
      Object.keys(isRouteVisible).map((key) => (isRouteVisible[key] = false));
    }

    function addFinishMarker(endLat, endLon) {
      if (lastMarker) {
        lastMarker.setMap(null);
      }

      lastMarker = new google.maps.Marker({
        position: {
          lat: parseFloat(endLat),
          lng: parseFloat(endLon)
        },
        map: map,
        icon: {
          url: "../assets/images/map/finish-flag-64.svg",
          size: new google.maps.Size(64, 64),
          anchor: new google.maps.Point(16, 64),
        },
      });
    }

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve more details for that place.
    searchBox.addListener("places_changed", function () {
      var places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      loadMarkers.forEach(function (marker) {
        marker.setMap(null);
      });
      loadMarkers = [];

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        loadMarkers.push(
          // var marker = new SlidingMarker();
          new google.maps.Marker({
            // new SlidingMarker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  };
};