import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';
import './style.css'
import {AvForm, AvField, AvCheckboxGroup, AvCheckbox} from 'availity-reactstrap-validation';
import {Button} from "reactstrap";
import UsersAPI from '../../../api/userApi'
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';


class CustomSignup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      comparePass: false,
      accountError: '',
      passwordPattern: false,
      phoneNumber: '',
    };
  }

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    }
  }

  handleSubmit = async (event, errors, values) => {
    const reg = /^(?=.*\w)(?=.*\d)(?=.*[@$!%*#?&])[A-Z,a-z\d@$!%*#?&]{6,}$/

    if (!reg.test(values.password)) {
      return this.setState({passwordPattern: true})
    } else {
      this.setState({passwordPattern: false})
    }

    const {email, firstName, lastName, companyName, password, confirmPass} = values;
    const {history} = this.props;
    const {phoneNumber} = this.state;

    const data = {
      email: email,
      phone: phoneNumber,
      user_type: 4,
      first_name: firstName,
      last_name: lastName,
      password: password,
      company: {
        id: 0,
        name: companyName
      },
    }

    if (password === confirmPass) {
      this.setState({comparePass: false})
      if (errors.length === 0 && password === confirmPass) {

        const res = await UsersAPI.SignUp(data)
        if (res.status === 200) {
          this.setState({comparePass: false})
          history.push('/confirmEmail');
        } else {
          this.setState({accountError: 'This account already exist'})
        }
      }
    } else {
      this.setState({comparePass: true})
    }
  }

  render() {

    const {comparePass, accountError, passwordPattern, phoneNumber} = this.state

    return (
      <AUX>
        <div className="wrapper-page">
          <div className="custom-login card overflow-hidden account-card mx-3">
            <div className="custom-login-header">
              <div className="logo logo-admin">
                <img src="assets/images/shortLogo.png" alt="logo"/>
              </div>
              <h4>Register</h4>
              <p>Please input your contact informations</p>
            </div>

            <div className="account-card-content">
              <AvForm onSubmit={this.handleSubmit}>
                <AvField
                  maxLength="100"
                  name="email"
                  label="Email*"
                  type="email"
                  errorMessage="Invalid email"
                  placeholder={'Enter your email'}
                  validate={{required: {value: true}, email: {value: true}}}/>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <AvField
                      name="firstName"
                      label="First name*"
                      required
                      errorMessage="Invalid first name"
                      type="text"
                      maxLength="100"
                      placeholder={'Your first name'}
                      validate={{required: {value: true}}}/>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <AvField
                      name="lastName"
                      label="Last name*"
                      required
                      placeholder={'Your last name'}
                      errorMessage="Invalid last name"
                      type="text"
                      maxLength="100"
                      validate={{required: {value: true}}}/>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <PhoneNumberInput
                      value={phoneNumber}
                      onChange={(phone) => { this.setState({ phoneNumber: phone }); }}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <AvField
                      name="companyName"
                      label="Company name*"
                      required
                      errorMessage="Invalid company name"
                      placeholder={'Enter your company name'}
                      type="text"
                      maxLength="100"
                      validate={{required: {value: true}}}/>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <AvField
                      name="password"
                      label="Password*"
                      required
                      errorMessage="Invalid password"
                      placeholder={'Enter password'}
                      type="password"
                      maxLength="100"
                      validate={{required: {value: true}}}/>
                    {comparePass ? (<span className="errorMsg">Passwords do not match</span>) : null}
                    {passwordPattern ?
                      (<span className="errorMsg">Password must contain at least 6 characters, including number and special characters</span>)
                      : null}
                    {accountError.length > 0 ? (<span className="errorMsg">{accountError}</span>) : null}
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <AvField
                      name="confirmPass"
                      label="Confirm password*"
                      placeholder={'Enter password'}
                      required
                      type="password"
                      errorMessage="Invalid confirm password"
                      maxLength="100"
                      validate={{required: {value: true}}}/>
                  </div>


                </div>

                <div className="row">
                  <div className="col-sm-8">
                    <div className="custom-control custom-checkbox" style={{paddingLeft: '0'}}>
                      <AvCheckboxGroup inline name="termsConditions" required>
                        <AvCheckbox customInput value="termsConditions">
                          I agree with <a href="https://goforitapp.com/terms-and-conditions/" className="font-500 text-primary" style={{paddingLeft: '5px'}}>Terms
                          and conditions</a>
                        </AvCheckbox>
                      </AvCheckboxGroup>
                    </div>
                  </div>
                  <div className="col-sm-4 text-right">
                    <Button className="btn btn-primary w-md custom-btn" type="submit">Submit</Button>
                  </div>
                </div>
              </AvForm>
            </div>
          </div>

          <div className="m-t-40 text-center custom-login-text">
            <p>Already have an account? <Link to="login" className="font-500"> Login</Link></p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(CustomSignup);
