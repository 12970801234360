/* TODO

(1) user email verified (is_veryfied)
(2) W9 uploaded
(3) subscription plan active

*/

import { checkSubscription, checkTrial } from "../helpers/CheckSubscription";
import DocumentsApi from "../api/documentsApi";

export const BackgroundCheckStatus = {
  PENDING: 0,
  APPROVED: 1,
  SUSPENDED: 2,
  MISSING_W9: 3,
  INACTIVE_SUBSCRIPTION: 4,
}

export const getBackgroundCheckStatus = async () => {
  const {
    data: { files },
  } = await DocumentsApi.list();

  const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''

  const emailVerified = userData ? userData.is_veryfied === 1 : false;
  const accountActive = userData ? userData.is_active === 1 : false;
  const w9Uploaded = files.length > 0;
  const subscriptionActive = await checkSubscription() && !(await checkTrial());

  console.warn("[getBackgroundCheckStatus] emailVerified = ", emailVerified)
  console.warn("[getBackgroundCheckStatus] accountActive = ", accountActive)
  console.warn("[getBackgroundCheckStatus] w9Uploaded = ", w9Uploaded)
  console.warn("[getBackgroundCheckStatus] subscriptionActive = ", subscriptionActive)

  if ((emailVerified || accountActive) && w9Uploaded && subscriptionActive) {
    console.warn("BackgroundCheckStatus.APPROVED")
    return BackgroundCheckStatus.APPROVED;
  } else if (!(emailVerified || accountActive) || !w9Uploaded || !subscriptionActive) {
    console.warn("BackgroundCheckStatus.PENDING")
    return !subscriptionActive ? BackgroundCheckStatus.INACTIVE_SUBSCRIPTION : ( !w9Uploaded ? BackgroundCheckStatus.MISSING_W9 : BackgroundCheckStatus.PENDING);
  } else {
    console.warn("BackgroundCheckStatus.SUSPENDED")
    return BackgroundCheckStatus.SUSPENDED;
  }
}

export default BackgroundCheckStatus;