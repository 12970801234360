import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import uuidv4 from "uuid";
import NotificationsAPI from '../../api/notificationsAPI'
import {Scrollbars} from 'react-custom-scrollbars';
import Notifications_status from '../../utils/notificationStatus'
import './style.css'
import UsersAPI from "../../api/userApi";
import NotificationCleaner from '../../helpers/NotificationCleaner';

import $ from 'jquery';

const USER_NAME_COLLAPSE_WIDTH = 420;
const UPGRADE_MESSAGE_COLLAPSE_WIDTH = 820;
const UPGRADE_BUTTON_COLLAPSE_WIDTH = 480;

class topbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    }

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);

    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      messageLimit: false,
      licenseStatus: 'trial',
      headerMsgText: '',
      width: $(window).innerWidth(), height: $(window).innerHeight(),
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    let active_transporters = 0
    let total_transporters = 0
    let licenseStatus

    const data = await NotificationsAPI.getNotificationsData()
    const userData = await UsersAPI.GetLicense(this.logout)
    if (userData) {
      active_transporters = userData.data.data ? userData.data.data.current_active : 0
      total_transporters = userData.data.data ? userData.data.data.current_amount : 0
      licenseStatus = userData.data.data ? userData.data.data.status : 'past_due'
    }

    if (data) {
      const headerMsgText = `In case of need, you may add or change the number of \n Transporters by switching to a different subscription plan.`
      const activeNotifications = NotificationCleaner.push(data.data.data)

      this.setState({
        notifications: activeNotifications,
        messageLimit: active_transporters > total_transporters,
        licenseStatus,
        headerMsgText: active_transporters > total_transporters || licenseStatus === 'past_due' ? '' : headerMsgText,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }

  toggleprofile() {
    this.setState(prevState => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile
    }));
  }

  togglebadge() {
    this.setState(prevState => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge
    }));
  }

  togglescreen(e) {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  logout = () => {
    const {history} = this.props;
    localStorage.clear()
    history.push('/login')
  }

  removeNotification = async (id, index) => {
    const {notifications} = this.state
    await NotificationsAPI.PutNotifications({id})
    const newNotificationsList = notifications.filter((item, i) => index !== i ? item : null)
    this.setState({
      notifications: newNotificationsList
    })

  }

  render() {

    const {notifications = [], messageLimit, licenseStatus, headerMsgText} = this.state
    const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
    const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

    return (
      <div className="topbar">

        <div className="topbar-left">
          <Link to="/dashboard" className="logo">
            <span><img src="assets/images/logo.png" alt="logo" height="70"/></span>
            <i><img src="assets/images/shortLogo.png" alt="logo" height="42"/></i>
          </Link>
        </div>

        <nav className="navbar-custom">
          <ul className="navbar-right d-flex list-inline mb-0">
            <li className="dropdown notification-list d-none d-md-block"/>

            <li className="dropdown notification-list">
              <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                <DropdownToggle className="nav-link dropdown-toggle testflag arrow-none waves-effect" tag="a">
                  <i className="mdi mdi-bell-outline noti-icon"/>
                  <span className="badge badge-pill badge-danger noti-icon-badge">
                    {notifications.length}
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                  <h6 className="dropdown-item-text">
                    Notifications ({notifications.length})
                  </h6>
                  <Scrollbars style={{height: "440px"}} className="notification-item-list">

                    {notifications.map((item, i) => {
                      return (
                        <div className="dropdown-item notify-item" key={uuidv4()}>
                          {Notifications_status.invited.includes(item.message_type) && (
                            <div className="notify-icon bg-success">
                              <i className="mdi mdi-highway "/>
                            </div>
                          )}
                          {Notifications_status.load.includes(item.message_type) && (
                            <div className="notify-icon bg-warning">
                              <i className="mdi mdi-view-list"/>
                            </div>
                          )}

                          {Notifications_status.invoice.includes(item.message_type) &&
                          (
                            <div className="notify-icon bg-info">
                              <i className="mdi mdi-calendar-check"/>
                            </div>
                          )}

                          <p className="notify-details">{item.message_title}<span className="text-muted">
                              {item.message_text}
                            </span></p>
                          <span className="dropdown-item-close" onClick={() => this.removeNotification(item.id, i)}/>
                        </div>
                      )
                    })}


                  </Scrollbars>

                  {/*<Link id="ex" to="#" className="dropdown-item text-center text-primary">*/}
                  {/*View all <i className="fi-arrow-right"/>*/}
                  {/*</Link>*/}
                </DropdownMenu>
              </Dropdown>

            </li>
            <li className="dropdown notification-list">
              <div className="dropdown notification-list nav-pro-img">

                <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
                  <DropdownToggle className="dropdown-toggle testflag nav-link arrow-none waves-effect nav-user" tag="a">
                  {this.state.width > USER_NAME_COLLAPSE_WIDTH ? 
                  `${userName} ${lastName}` : 
                  `${userName !== null ? userName.charAt(0).toUpperCase() : ''}${lastName !== null ? lastName.charAt(0).toUpperCase() : ''}`}
                    <span className="dropdown-icon"/>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.logout}><i className="mdi mdi-power text-danger"/> Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </li>
          </ul>


          <ul className="list-inline menu-left mb-0 topbar-list">
            <li>
              <button
                className="button-menu-mobile open-left waves-effect"
                name="sidebar-toggle"
                title="sidebar-toggle"
                aria-label="sidebar-toggle">
                <i className="mdi mdi-menu"/>
              </button>
            </li>

            {(messageLimit || licenseStatus === 'past_due') && this.state.width > UPGRADE_MESSAGE_COLLAPSE_WIDTH ?
              <li className="topbar-messageLimit">
                <span className="fas fa-exclamation-triangle"/>
                You reached license limitations. In order to be able to assign Transporters to your loads,
                please upgrade your payment plan or deactivate some of your current Transporters.
              </li> :

              <li>
                <span style={{'fontSize': '12px', whiteSpace: 'pre-wrap'}}>{this.state.width > UPGRADE_MESSAGE_COLLAPSE_WIDTH ? headerMsgText : ''}</span>
              </li>}

            { this.state.width > UPGRADE_BUTTON_COLLAPSE_WIDTH ? (
              <li>
              <Link to='/subscription' className="btn custom-btn m-l-15 custom-btn-upgrade">Upgrade now</Link>
            </li>
            ) : (null)}
            
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(topbar);
