import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js'
import qs from "qs";

const basicAxios = axios.create({baseURL: config.serverUrl});
const cryptoText = CryptoJS.MD5(config.security_string + config.security_token, config.security_token);
const convertText = cryptoText.toString(CryptoJS.enc.MD5);

export default class DashboardAPI {
  static async GetDashboardData(options) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/dashboard` , {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...options
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
      return {}
    });
  }

  static async GetTransportersStat(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/dashboard/load-stat`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...data
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async GetMyDrivers(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/dashboard/my-driver-stat`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...data
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async CompletedLoadStat(options) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.get(`/dispatchers/dashboard/load-completed`, {
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
        ...options,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

  static async UpdateFee(data) {
    const auth_key = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';

    return basicAxios.put(`${config.serverUrl}/dispatchers/${data.id}/update-fee`, qs.stringify({fee: data.fee}),{
      params: {
        auth_string: config.security_string,
        auth_crc: convertText,
        app_ver: config.app_ver,
        auth_key: auth_key,
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      console.log('error',error)
    });
  }

}


