import React, { Component } from 'react';
import Select from 'react-select';
import "./style.css";

const DEFAULT_DISTANCE_STEP = 10;

class LocationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: props.selectedLocation || { label: props.selectionHint || "", value: "" },
      selectedDistance: props.selectedDistance || 50,
    };
  }

  // Trigger when prop changes to update state
  componentDidUpdate(prevProps) {
    if (prevProps.selectedLocation !== this.props.selectedLocation) {
      const { selectedLocation } = this.props;
      this.setState({ selectedLocation: selectedLocation });
      this.onLocationChange(selectedLocation);
    }

    if (prevProps.selectedDistance !== this.props.selectedDistance) {
      const { selectedDistance } = this.props;
      this.setState({ selectedDistance: selectedDistance });
      this.onDistanceChange({ target: {value : selectedDistance} });
    }
  }

  onLocationChange = (selectedOption) => {
    this.setState({ selectedLocation: selectedOption });
    if (this.props.onLocationChange) {
      this.props.onLocationChange(selectedOption);
    }
  };

  onChangeLocationFilterCriteria = (inputValue) => {
    if (this.props.onChangeLocationFilterCriteria) {
      this.props.onChangeLocationFilterCriteria(inputValue);
    }
  };

  onDistanceChange = (event) => {
    const selectedDistance = this.state.selectedDistance;
    let distance = event.target.value;
    distance = parseInt(distance, 10);
    distance = distance - 1 === selectedDistance
      ? selectedDistance + DEFAULT_DISTANCE_STEP
      : distance + 1 === selectedDistance
        ? selectedDistance - DEFAULT_DISTANCE_STEP
        : distance;

    this.setState({ selectedDistance: distance });
    if (this.props.onDistanceChange) {
      this.props.onDistanceChange(distance);
    }
  };

  handleFocus = () => {
    if (this.state.selectedLocation.value === '') {
      this.setState({ selectedLocation: { label: '', value: '' } });
    }
  };

  handleBlur = () => {
    if (this.state.selectedLocation.value === '') {
      this.setState({ selectedLocation: { label: this.props.selectionHint, value: '' } });
    }
  };

  render() {
    const { locationOptions, placeholder } = this.props;
    const { selectedLocation, selectedDistance } = this.state;

    return (
      <div className="custom-form-control-wrap" style={{ width: '100%' }}>
        <div className="input-group" style={{ width: '100%' }}>
          <div className="input-group-append" style={{ width: '100%' }}>
            <span className="input-group-text" style={{ borderRight: '0px', backgroundColor: '#fff', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}>
              <img src={this.props.imagePath} alt="" />
            </span>
            <div className="custom-form-control-wrap" style={{ width: '100%', borderColor: '#fff' }}>
              <Select
                className="custom-form-control-select-locations"
                styles={{ width: '100%', borderRadius: '0px', borderColor: '#fff' }}
                value={selectedLocation}
                onChange={this.onLocationChange}
                onInputChange={this.onChangeLocationFilterCriteria}
                options={locationOptions}
                placeholder={placeholder || "type transporter's name, city"}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
            </div>
            <input
              type="number"
              min="0"
              className="form-control"
              maxLength="10"
              onChange={this.onDistanceChange}
              value={selectedDistance}
              style={{ width: '80px', height: '100%', minHeight: '39px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LocationSelect;