const parseDate = (dateString) => {
  var dateTime = dateString.split(" ");
  var dateOnly = dateTime[0];
  var timeOnly = dateTime[1];

  var temp = dateOnly + "T" + timeOnly;
  return new Date(temp);
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default parseDate;