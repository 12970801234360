import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';
import './RouteMap.css';

function RouteMap({ origin, destination, routePolyline, apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY, width = 362, height = 362 }) {
  const [mapUrl, setMapUrl] = useState('');

  useEffect(() => {
    const fetchPolyline = async () => {
      if (routePolyline) {
        const url = `https://maps.googleapis.com/maps/api/staticmap?size=${width}x${height}&markers=color:0x8A98AB%7Clabel:O%7C${origin}&markers=color:0x1FA84D%7Clabel:D%7C${destination}&path=weight:3|color:0x0000ff80|enc:${routePolyline}&key=${apiKey}`;
        setMapUrl(url);
      } else {
        const url = `https://maps.googleapis.com/maps/api/staticmap?size=${width}x${height}&markers=color:0x8A98AB%7Clabel:O%7C${origin}&markers=color:0x1FA84D%7Clabel:D%7C${destination}&key=${apiKey}`;
        setMapUrl(url);
      }
    };

    fetchPolyline();
  }, [origin, destination, apiKey]);

  return (
    <div>
      {mapUrl ? <img className="route-map" src={mapUrl} alt="Route Map" style={{ borderRadius: "12px" }} /> : 
        <div className="loading-container">
          <Spinner useBackgroundSpinner={true} />
        </div>}
    </div>
  );
}

export default RouteMap;