import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import uuidv4 from "uuid";
import CarTypes from '../../../utils/carTypes'
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import LoadAPI from "../../../api/loadsAPI";
import { MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MB } from "../../../api/loadsAPI";
import CarAPI from "../../../api/carsAPI";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, Tooltip} from "reactstrap";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import ClientsAPI from "../../../api/clientsAPI";
import TransportersAPI from "../../../api/transportersAPI";
import MapAPI from "../../../api/mapAPI";
import PopupSubscription from "../PopupSubscription/PopupSubscription";
import {checkSubscription} from "../../../helpers/CheckSubscription";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import SubscriptionContext from "../../../components/Layout/SubscriptionContext"
import ReleaseFiles from '../ReleaseFiles/ReleaseFiles';
import LoadsAPI from '../../../api/loadsAPI';
import VehicleAPI from "../../../api/vehicleAPI"

class Load extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadData: {},
      cars: [],
      carsForm: false,
      error: false,
      carsFields: {
        makerOption: '',
        modelOption: '',
        carTypesOption: '',
        vin: '',
        mileage: '',
        year: '',
        keys: '',
        type: '',
        is_inop: 0,
        local_car_id: ''
      },
      carsSelectOptions: [],
      modelsSelectOptions: [],
      carTypesSelectOptions: [],
      pick_up_date: '',
      delivery_date: '',

      clientSelectedValue: '',
      originSelectedValue: '',
      originIsClient: 0,
      destinationSelectedValue: '',
      destinationIsClient: 0,

      optionsSelect: [],
      values: [],
      key: "",
      isLoading: true,
      delayTimer: null,
      enclose: 0,
      myDriver: '',
      validateErrorFields: {
        pick_up_date: false,
        delivery_date: false,
        driver: false,
        origin: false,
        destination: false,
      },
      carValidateErrorFields: {
        carMaker: false,
        model: false,
        vin: false,
        mileage: false,
        year: false,
        keys: false,
      },
      selectedCar: null,
      tooltip_top: false,

      carsFormError: false,

      createLoadBtnSpinner: false,

      defaultModelsSelectOptions: [],
      defaultCarsSelectOptions: [],
      isOpenSubscriptionModal: false,

      subscriptionStatus: '',
      attachedFiles: [],
      releaseFileError: null,
    };
  }

  async componentDidMount() {
    const subscriptionData = await SubscriptionAPI.GetSubscribeData();
    if (subscriptionData.data.status === 500 && subscriptionData.data.data) {
      this.setState({
        subscriptionStatus: subscriptionData.data.data.status,
      });
    }

    const isSubscription = await checkSubscription();

    const {match} = this.props;
    const loadData = await LoadAPI.GetLoad(match.params.id);
    const loadCarsData = await LoadAPI.GetLoadsCars(match.params.id);
    const originData = await ClientsAPI.GetClientsData();
    const myDrivers = await TransportersAPI.GetMyTransportersData()
    const carsData = await CarAPI.GetCarsData();
    const modelsData = await CarAPI.GetModelData();
    const releaseFiles = await LoadAPI.ListReleaseFiles(match.params.id);

    const modelsSelectOptions = modelsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id, car_maker: item.car_maker_id, isTitle: false}
    })

    const carsSelectOptions = carsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id, isTitle: false}
    })

    const optionsSelect = originData.data.data.map((item) => {
      return {value: item.id, label: `${item.name}`, id: item.id, data: item}
    })

    let myDriver

    if (loadData.data.data.driver_data) {
      myDriver = loadData.data.data.driver_data
    } else if (loadData.data.data.driver_id) {
      const data = myDrivers.data.data.filter((item) => {
        return loadData.data.data.driver_id === item.id
      })

      myDriver = data[0]
    } else {
      myDriver = myDrivers.data.data
    }

    if(loadCarsData.data.data) {
      loadCarsData.data.data.forEach((item)=>{
        item.local_car_id = uuidv4()
      })
    }

    this.setState({
      loadData: loadData.data.data,
      cars: loadCarsData.data.data,
      selectedDriver: loadData.data.data.id !== null && loadData.data.data.driver_data !== null ? JSON.stringify(loadData.data.data.driver_data) : false,
      myDrivers: myDrivers.data.data,
      defaultOptionsSelect: optionsSelect,
      myDriver: myDriver,
      clientSelectedValue: loadData.data.data.client_data ? {
        value: loadData.data.data.client_data ? loadData.data.data.client_data.name : '',
        label: loadData.data.data.client_data ? loadData.data.data.client_data.name : '',
        id: loadData.data.data.client_data ? loadData.data.data.client_id : ''
      } : '',
      originIsClient: loadData.data.data.client_data ? (loadData.data.data.client_id === loadData.data.data.origin_id ? 1 : 0) : 0,
      originSelectedValue: {
        value: loadData.data.data.origin_data.name,
        label: loadData.data.data.origin_data.name,
        id: loadData.data.data.origin_id
      },
      destinationIsClient: loadData.data.data.client_data ? (loadData.data.data.client_id === loadData.data.data.destination_id ? 1 : 0) : 0,
      destinationSelectedValue: {
        value: (loadData.data.data.destination_data ? loadData.data.data.destination_data.name : ''),
        label: (loadData.data.data.destination_data ? loadData.data.data.destination_data.name : ''),
        id: loadData.data.data.destination_id
      },
      pick_up_date:
        new Date(loadData.data.data.pick_up_date.replace(/-/g, "/")) < new Date() ? new Date() : new Date(loadData.data.data.pick_up_date.replace(/-/g, "/")),
      delivery_date:
        new Date(loadData.data.data.delivery_date.replace(/-/g, "/")) < new Date() ? new Date() : new Date(loadData.data.data.delivery_date.replace(/-/g, "/")),
      // pick_up_date: "2019-10-04",
      // delivery_date: "2019-10-04",
      carTypesSelectOptions: CarTypes,
      selectedBillingType: loadData.data.data.is_cod.toString(),

      carsSelectOptions,
      modelsSelectOptions,

      defaultModelsSelectOptions: modelsSelectOptions,
      defaultCarsSelectOptions: carsSelectOptions,
      isOpenSubscriptionModal: !isSubscription,

      isLoading: false,
      attachedFiles: releaseFiles.data.files,
      releaseFileError: null,
    })
  }

  checkAlreadyCreatedCar = () => {

    const checkDublicate = (arr) => {
      const set = new Set();
      arr.forEach(item => {
        set.add(item.vin)
      })
      return set.size === arr.length
    }

    const {cars} = this.state;
    const alreadyCreatedCarErrors = []

    cars.forEach((item) => {
      const errorsState = {}

      if (item.makerOption) {
        item.makerOption.id !== null ? errorsState.carMaker = false : errorsState.carMaker = true
      } else {
        item.car_maker_id !== null ? errorsState.carMaker = false : errorsState.carMaker = true
      }

      if (item.modelOption) {
        item.modelOption.id !== null ? errorsState.model = false : errorsState.model = true
      } else {
        item.car_model_id !== null ? errorsState.model = false : errorsState.model = true
      }

      const checkVin = checkDublicate(cars)
      item.vin && item.vin.length >= 8 && checkVin ? errorsState.vin = false : errorsState.vin = true
      // item.mileage ? errorsState.mileage = false : errorsState.mileage = true
      // item.year ? errorsState.year = false : errorsState.year = true
      // item.keys ? errorsState.keys = false : errorsState.keys = true

      alreadyCreatedCarErrors.push(errorsState)
    })

    const result = alreadyCreatedCarErrors.map((item, i) => {
      const errorStatus = Object.values(item).every((item) => item === false)
      cars[i].alreadyCreatedErrors = !errorStatus
      return errorStatus ? false : 'error'
    }).filter(Boolean)

    this.setState({cars})

    return !result.length
  }

  checkCarValidateErrorFields = () => {
    const errorsState = {}
    const {carsFields, cars} = this.state;
    carsFields.makerOption.id ? errorsState.carMaker = false : errorsState.carMaker = true
    carsFields.modelOption.id ? errorsState.model = false : errorsState.model = true

    const checkExistCar = cars.filter((item)=>item.local_car_id !== carsFields.local_car_id)
    const checkVin = checkExistCar.some((item) => item.vin === carsFields.vin)

    carsFields.vin && carsFields.vin.length >= 8 && !checkVin ? errorsState.vin = false : errorsState.vin = true
    // carsFields.mileage ? errorsState.mileage = false : errorsState.mileage = true
    // carsFields.year ? errorsState.year = false : errorsState.year = true
    // carsFields.keys ? errorsState.keys = false : errorsState.keys = true

    const errorStatus = Object.values(errorsState).every((item) => item === false)
    this.setState({carValidateErrorFields: errorsState})

    return errorStatus
  }


  checkValidationError = () => {
    const errorsState = {}

    const {pick_up_date, delivery_date, originSelectedValue, destinationSelectedValue} = this.state;
    pick_up_date ? errorsState.start_date = false : errorsState.pick_up_date = true
    delivery_date ? errorsState.delivery_date = false : errorsState.delivery_date = true
    // selectedDriver ? errorsState.driver = false : errorsState.driver = true
    originSelectedValue ? errorsState.origin = false : errorsState.origin = true
    destinationSelectedValue ? errorsState.destination = false : errorsState.destination = true
    const errorStatus = Object.values(errorsState).every((item) => item === false)
    this.setState({validateErrorFields: errorsState})

    return errorStatus
  }

  checkCarAssign = () => {
    const {cars} = this.state

    if (cars.length > 0) {
      return true
    } else {
      this.setState({
        carsForm: true,
        carsFormError: true,
      })

      return false
    }
  }


  submitLoad = async (event, errors, values) => {
    const {history} = this.props;
    const {
      cars,
      loadData,
      pick_up_date,
      delivery_date,
      clientSelectedValue,
      originSelectedValue,
      destinationSelectedValue,
      selectedDriver,
      selectedBillingType,
      defaultOptionsSelect
    } = this.state;

    const isSubscription = await checkSubscription();
    if (!isSubscription) {
      this.setState({ isOpenSubscriptionModal: true });
      return;
    }
    
    const carAssign = this.checkCarAssign()
    const validationErrors = this.checkValidationError()
    const deliveryDate = new Date(delivery_date || loadData.delivery_date)
    const deliveryDateYear = deliveryDate.getFullYear()
    const deliveryDateMonth = deliveryDate.getMonth() + 1 >= 1 && deliveryDate.getMonth() + 1 < 10 ? `0${deliveryDate.getMonth() + 1}` : deliveryDate.getMonth() + 1;
    const deliveryDateDay = deliveryDate.getDate() >= 1 && deliveryDate.getDate() < 10 ? `0${deliveryDate.getDate()}` : deliveryDate.getDate();

    const pickUpDate = new Date(pick_up_date || loadData.pick_up_date)
    const pickUpDateYear = pickUpDate.getFullYear()
    const pickUpDateMonth = pickUpDate.getMonth() + 1 >= 1 && pickUpDate.getMonth() + 1 < 10 ? `0${pickUpDate.getMonth() + 1}` : pickUpDate.getMonth() + 1;
    const pickUpDateDay = pickUpDate.getDate() >= 1 && pickUpDate.getDate() < 10 ? `0${pickUpDate.getDate()}` : pickUpDate.getDate();

    const checkAlreadyCreatedCar = this.checkAlreadyCreatedCar()

    if (errors.length === 0 && validationErrors && carAssign && checkAlreadyCreatedCar) {

      const checkOrigin = defaultOptionsSelect.filter((item)=>item.label === originSelectedValue.label)
      const checkDestination = defaultOptionsSelect.filter((item)=>item.label === destinationSelectedValue.label)

      this.setState({createLoadBtnSpinner: true})

      // const originCoordinates = await MapAPI.GetCoordinatesByValues({
      //   address: values.originAddress,
      //   city: values.originCity,
      //   state: values.originState,
      //   zip: values.originZip,
      // })
      //
      // const destinationCoordinates = await MapAPI.GetCoordinatesByValues({
      //   address: values.destinationAddress,
      //   city: values.destinationCity,
      //   state: values.destinationState,
      //   zip: values.destinationZip,
      // })

      const milesData = await MapAPI.GetDirections({
        from: `${values.originAddress}`.replace(/ /g, '+'),
        to: `${values.destinationAddress}`.replace(/ /g, '+'),
      })

      const driver_id = selectedDriver ? JSON.parse(selectedDriver).id : '';

      await LoadAPI.UpdateLoad({
        load: {
          id: loadData.id,
          load_id: values.loadNumber,
          pick_up_date: `${pickUpDateYear}-${pickUpDateMonth}-${pickUpDateDay}`,
          delivery_date: `${deliveryDateYear}-${deliveryDateMonth}-${deliveryDateDay}`,
          driver_id: driver_id,
          ...(driver_id && {origin_sign_date: null}),
          hauling: values.driverPrice,
          miles: milesData ? milesData.miles : 0,
          is_cod: +selectedBillingType,
          note_dispatch: values.instructionsDispatch,
          note_release: values.instructionsDelivery,
          note: values.comment,
        },
        client: {
          id: clientSelectedValue && typeof clientSelectedValue.id === "number" ? (clientSelectedValue.id || '') : '',
          name: clientSelectedValue ? clientSelectedValue.label.trim() : '',
          address: clientSelectedValue && clientSelectedValue.data ? clientSelectedValue.data.address.trim() : '',
          phone: clientSelectedValue && clientSelectedValue.data ? clientSelectedValue.data.phone.trim() : '',
        },
        origin: {
          id: originSelectedValue.id,
          name: originSelectedValue.label.trim(),
          address: values.originAddress,
          // city: values.originCity,
          // state: values.originState,
          // zip: values.originZip,
          email: values.originEmail,
          phone: values.originPhone,
          is_private: checkOrigin.length ? 0 : 1 ,
          lon: milesData ? milesData.origin.lon: '',
          lat: milesData ? milesData.origin.lat : '',
        },
        destination: {
          id: destinationSelectedValue.id,
          name: destinationSelectedValue.label.trim(),
          address: values.destinationAddress,
          // city: values.destinationCity,
          // state: values.destinationState,
          // zip: values.destinationZip,
          email: values.destinationEmail,
          phone: values.destinationPhone,
          is_private: checkDestination.length ? 0 : 1 ,
          lon: milesData ? milesData.destination.lon: '',
          lat: milesData ? milesData.destination.lat : '',
        }
      }).then((res) => {
        if (loadData && loadData.id) { // update
          // this.createOrUpdateCars(loadData && loadData.id ? loadData.id : res.data.data.id, cars);
        } else { // create
          this.createOrUpdateCars(loadData && loadData.id ? loadData.id : res.data.data.id, cars);
        }
        
        history.push('/loads');
      }).catch((error) => {
        console.error('[Load] submitLoad', error)
        this.setState({error: true})
      })
    }
  }

  createOrUpdateCars = (loadId, cars) => {
    const promises = cars.map((item) => {

      if (item.id) {
        return (
          new Promise((resolve, reject) => {
            CarAPI.UpdateCars({
              id: item.id,
              load_id: loadId,
              ...item.makerOption && item.makerOption.isTitle
                ? { car_maker: item.makerOption.id }
                : { car_maker_id: item.makerOption ? item.makerOption.id : item.car_maker_id },
              ...item.modelOption && item.modelOption.isTitle
                ? { car_model: item.modelOption.id }
                : { car_model_id: item.modelOption ? item.modelOption.id : item.car_model_id },
              // car_maker_id: item.car_maker_id,
              // car_model_id: item.car_model_id,
              car_type: item.carTypesOption ? item.carTypesOption.value : item.car_type,
              vin: item.vin,
              year: item.year,
              mileage: item.mileage,
              keys: item.keys,
              is_inop: item.is_inop,
            })
          })
        )
      } else {
        return (
          new Promise((resolve, reject) => {
            CarAPI.CreateCars({
              load_id: loadId,
              [item.makerOption.isTitle ? 'car_maker' : 'car_maker_id']: item.makerOption.id,
              [item.modelOption.isTitle ? 'car_model' : 'car_model_id']: item.modelOption.id,
              vin: item.vin,
              car_type: item.carTypesOption.value,
              year: item.year,
              mileage: item.mileage,
              keys: item.keys,
              is_inop: item.is_inop,
            })
          })
        )
      }
    });

    Promise.all(promises);
  }

  showCarsForm = () => this.setState({carsForm: !this.state.carsForm})

  createCar = () => {
    const {loadData, cars, carsFields,defaultCarsSelectOptions,defaultModelsSelectOptions} = this.state;

    const validationErrors = this.checkCarValidateErrorFields()
    if (validationErrors) {
      carsFields.local_car_id = uuidv4();
      cars.push(carsFields);

      this.createOrUpdateCars(loadData.id, cars);

      this.setState({
        cars,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',

          carsSelectOptions: defaultCarsSelectOptions,
          modelsSelectOptions: defaultModelsSelectOptions,

        },
      })
    }
  }

  deleteCar = async (id, index) => {
    const {cars} = this.state;
    const carsArray = cars.filter((item, i) => index !== i ? item : null)

    if (id) {
      await CarAPI.DeleteCars(id)
    }

    this.setState({
      cars: carsArray,
      carsFields: {
        makerOption: '',
        modelOption: '',
        vin: '',
        mileage: '',
        year: '',
        keys: '',
        type: '',
        is_inop: 0,
        carTypesOption: '',
      },
    })
  }

  carFieldsHandler = (e) => {
    if (e.target.name === 'is_inop') {
      this.setState({
        carsFields: {...this.state.carsFields, ...{[e.target.name]: this.state.carsFields.is_inop === 0 ? 1 : 0}}
      })
    } else if (e.target.name === 'year') {
      const string = e.target.value;
      const regexp = string.match(/[^0-9]/gi, '');
      if (regexp === null) {
        this.setState({
          carsFields: {...this.state.carsFields, ...{[e.target.name]: e.target.value}}
        })
      }
    } else if (e.target.name === 'vin') {
      const name = e.target.name
      const string = e.target.value;
      const regexp = string.match(/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm, '');
      
      if (regexp === null) {
        this.setState({
          carsFields: {...this.state.carsFields, ...{[name]: string}}
        }) 
      } else {
        VehicleAPI.GetVINDecoded(string).then((vinsData) => {
          if (vinsData) {
            this.setState({
              carsFields: {
                modelOption: { value: vinsData.model, label: vinsData.model, id: vinsData.model, isTitle: true },
                makerOption: { value: vinsData.make, label: vinsData.make, id: vinsData.make, isTitle: true },
                year: vinsData.year,
                vin: vinsData.vin,
                mileage: this.state.carsFields.mileage,
                carTypesOption: { value: vinsData.body, label: vinsData.body },
                keys: this.state.carsFields.keys,
                type: this.state.carsFields.type,
                is_inop: this.state.carsFields.is_inop,
              }
            })
          } else {
            this.setState({
              carsFields: {...this.state.carsFields, ...{[name]: string}}
            }) 
          }
        })
      }
    } else {
      this.setState({
        carsFields: {...this.state.carsFields, ...{[e.target.name]: e.target.value}}
      })
    }
  }

  encloseHandler = (e) => {
    this.setState({enclose: this.state.enclose === 0 ? 1 : 0})
  };

  onInputChangeMake = inputValue => {
    let {defaultCarsSelectOptions, delayTimer, carsFields} = this.state;
    const {makerOption} = carsFields;

    if (inputValue !== '' && inputValue.length >= 3) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        const checkRegister = defaultCarsSelectOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
        if (!checkRegister.length)
          checkRegister.push({isTitle: true, value: inputValue, label: inputValue, id: inputValue, data:  (makerOption && makerOption.data) || {}})

        this.setState({isLoading: false, carsSelectOptions: checkRegister });
      }, 100);
      this.setState({isLoading: true, delayTimer});
    } else {
      this.setState({isLoading: false, carsSelectOptions: defaultCarsSelectOptions});
    }
  };


  onChangeMake = async makerOption => {
    const {defaultCarsSelectOptions, carsFields} = this.state;

    if (!makerOption || !makerOption.id) {
      this.setState({carsFields: {...carsFields, ...{makerOption: null}}, carsSelectOptions: defaultCarsSelectOptions});
      return;
    }

    const modelsData = await CarAPI.GetModelData(makerOption.id);

    if (modelsData.data.data) {
      const modelsSelectOptions = modelsData.data.data.map((item) => {
        return {value: item.title, label: item.title, id: item.id}
      });

      let checkModelExist = true;

      if (carsFields.modelOption && carsFields.modelOption.id !== null) {
        checkModelExist = modelsSelectOptions.filter((item) => item.label === carsFields.modelOption.label).length;
      }
      this.setState({
        carsFields: {...carsFields, ...{makerOption}, modelOption: checkModelExist ? carsFields.modelOption : {}},
        modelsSelectOptions,
        carsSelectOptions: defaultCarsSelectOptions,
	      modelsCurrentOptions : modelsSelectOptions
      });
    }

    this.setState({carsFields: {...carsFields, ...{makerOption: makerOption}} });
  };

  onInputChangeModel = inputValue => {
    const
      {carsFields, modelsCurrentOptions} = this.state,
      {modelOption} = carsFields
    ;

    console.log('onInputChangeModel.modelOption', modelOption)

    if (inputValue !== '' && inputValue.length >= 3) {
      const checkRegister = modelsCurrentOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
      if (!checkRegister.length) {
        checkRegister.push({isTitle: true, value: inputValue, label: inputValue, id: inputValue, data:  (modelOption && modelOption.data) || {}})
      }

      this.setState({isLoading: false, modelsSelectOptions: checkRegister });
    } else if ( inputValue.length === 0) {
	    this.setState({isLoading: false, modelsSelectOptions: modelsCurrentOptions });
    }
  };

  onChangeModel = async modelOption => {
    let carsSelectOptions;

    console.log('onChangeModel.modelOption', modelOption)

    // if (modelOption && modelOption.car_maker) {
    //   const carsData = await CarAPI.GetCarMakerData(modelOption.car_maker);
    //   carsSelectOptions = carsData.data.data.map((item) => {
    //     return {value: item.title, label: item.title, id: item.id}
    //   })
    // } else if (modelOption) {
    //   modelOption.isTitle = true;
    // }
    
    if (modelOption) {
      this.setState({
        carsFields: {...this.state.carsFields, ...{modelOption}},
        ...carsSelectOptions ? carsSelectOptions : {},
      });
    } else {
      this.setState({
        carsFields: {...this.state.carsFields, ...{modelOption: ''}},
        modelsSelectOptions: this.state.defaultModelsSelectOptions
      });
    }
  };

  changeCarTypes = carTypesOption => {
    this.setState({carsFields: {...this.state.carsFields, ...{carTypesOption}}});
  };

  handlePickupDate = (date) => {
    this.setState({pick_up_date: date});
  }

  handleDeliveryDate = (date) => {
    this.setState({delivery_date: date});
  }

  asOiginHandler = (e) => { 
    this.setState({
      originIsClient: this.state.originIsClient === 0 ? 1 : 0,
      destinationIsClient: this.state.destinationIsClient === 1 && this.state.originIsClient === 0 ? 0 : this.state.destinationIsClient,
    }, () => {
      this.setOriginIsClient(this.state.originSelectedValue);
    })
  };

  asDestinationHandler = (e) => {
    this.setState({
      destinationIsClient: this.state.destinationIsClient === 0 ? 1 : 0,
      originIsClient: this.state.originIsClient === 1 && this.state.destinationIsClient === 0 ? 0 : this.state.originIsClient,
    }, () => {
      this.setDestinationIsClient(this.state.destinationSelectedValue);
    })
  };

  onInputChange = (inputValue, name) => {
    let {defaultOptionsSelect, delayTimer} = this.state

    if (name === 'origin') {
      this.setState({originKey: inputValue});
    } else if (name === 'destination') {
      this.setState({destinationKey: inputValue});
    } else if (name === 'client') {
      this.setState({clientKey: inputValue});
    } else {
      console.warn("Unknown onInputChange field", name);
      return;
    }

    if (inputValue !== "" && inputValue.length >= 3) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        let isSameName = false
        const newOptionsSelect = defaultOptionsSelect.filter(i => {
          // if (!i.cityAdded) { i.label = i.label + ' - ' + i.data.city }
          if (inputValue.toLowerCase() === i.label.toLowerCase()) { isSameName = true }
          i.cityAdded = true
          return i
        })

        const checkRegister = newOptionsSelect.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))

        // if (!checkRegister.length)
        if (!isSameName) {
          checkRegister.push({value: inputValue, label: inputValue, id: inputValue, data: {}, cityAdded: true})
        }


        this.setState({
          optionsSelect: checkRegister,
          isLoading: false
        });

      }, 800);

      this.setState({
        isLoading: true,
        delayTimer
      });
    } else {
      this.setState({
        isLoading: false,
        optionsSelect: []
      });
    }
  }

  onClose = () => {
    this.setState({
      optionsSelect: []
    });
  }

  onChange = (data, name) => {
    if (name === 'origin') {
      this.setState({originSelectedValue: data}, () => this.setOriginIsClient(data));
    } else if (name === 'destination') {
      this.setState({destinationSelectedValue: data}, () => this.setDestinationIsClient(data));
    } else if (name === 'client') {
      this.setState({clientSelectedValue: (data && data.id && isNaN(data.id)) ? '' : data});
    } else {
      console.warn("Unknown onChange filed", name);
    }
  }

  setOriginIsClient = (data) => {
    if (this.state.originIsClient) {
      if (data) {
        this.setState({clientSelectedValue: data});
      } else {
        this.setState({clientSelectedValue: null});
      }
    }
  }

  setDestinationIsClient = (data) => {
    if (this.state.destinationIsClient) {
      if (data) {
        this.setState({clientSelectedValue: data});
      } else {
        this.setState({clientSelectedValue: null});
      }
    }
  }

  selectCar = (carsFields, i) => {
    const {selectedCar, defaultCarsSelectOptions, defaultModelsSelectOptions} = this.state

    if (selectedCar === i) {

      this.setState({
        selectedCar: null,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',
          local_car_id: '',

          carsSelectOptions: defaultCarsSelectOptions,
          modelsSelectOptions: defaultModelsSelectOptions
        },
      })
    } else {
      this.setState({
        carsFields: {
          vin: carsFields.vin ? carsFields.vin : '',
          mileage: carsFields.mileage ? carsFields.mileage : '',
          year: carsFields.year ? carsFields.year : '',
          keys: carsFields.keys ? carsFields.keys : '',
          type: carsFields.type ? carsFields.type : '',
          is_inop: carsFields.is_inop ? carsFields.is_inop : 0,
          local_car_id: carsFields.local_car_id ? carsFields.local_car_id : '',
          ...{
            makerOption: {
              value: carsFields.makerOption ? carsFields.makerOption.value : carsFields.car_maker_name,
              label: carsFields.makerOption ? carsFields.makerOption.value : carsFields.car_maker_name,
              id: carsFields.makerOption ? carsFields.makerOption.id : carsFields.car_maker_id,
              isTitle: carsFields.makerOption ? carsFields.makerOption.isTitle === true : false,
            },
            modelOption: {
              value: carsFields.modelOption ? carsFields.modelOption.value : carsFields.car_model_name,
              label: carsFields.modelOption ? carsFields.modelOption.value : carsFields.car_model_name,
              id: carsFields.modelOption ? carsFields.modelOption.id : carsFields.car_model_id,
              isTitle: carsFields.modelOption ? carsFields.modelOption.isTitle === true : false,
            },
            carTypesOption: {
              value: carsFields.carTypesOption ? carsFields.carTypesOption.value : carsFields.car_type,
              label: carsFields.carTypesOption ? carsFields.carTypesOption.value : carsFields.car_type,
            }
          }
        },
        selectedCar: i,
        carsForm: true,
      }, ()=> {
        this.onChangeMake({
          value: carsFields.makerOption ? carsFields.makerOption.value : carsFields.car_maker_name,
          label: carsFields.makerOption ? carsFields.makerOption.value : carsFields.car_maker_name,
          id: carsFields.makerOption ? carsFields.makerOption.id : carsFields.car_maker_id,
          isTitle: carsFields.makerOption ? carsFields.makerOption.isTitle === true : false,
        })
      })
    }
  }

  updateCar = () => {
    const {loadData, selectedCar, cars, carsFields} = this.state
    const checkCarValidateErrorFields = this.checkCarValidateErrorFields()

    if(checkCarValidateErrorFields) {
      const updatedCars = cars.map((item, i) => {
        if (i === selectedCar) {
          return {...item, ...carsFields}
        } else {
          return item
        }
      })

      this.createOrUpdateCars(loadData.id, updatedCars);

      this.setState({
        cars: updatedCars,
        selectedCar: null,
        carsFields: {
          makerOption: '',
          modelOption: '',
          vin: '',
          mileage: '',
          year: '',
          keys: '',
          type: '',
          is_inop: 0,
          carTypesOption: '',
          local_car_id: ''
        },
      })
    }
  }

  addFile = async (item) => {
    const selectedFile = item.target.files[0];
    const fileName = selectedFile.name ? selectedFile.name : selectedFile.fileName;

    if (selectedFile) {
      const {attachedFiles, loadData} = this.state;

      if (attachedFiles.includes(selectedFile) || attachedFiles.some((s) => s.name === fileName || s.fileName === fileName)) {
        this.setState({ releaseFileError: "File \"" + fileName + "\" already added." });
        return;
      }

      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        this.setState({ releaseFileError: "File \"" + fileName + "\" exceeded maximum allowed file size." });
        return;
      }
      

      var result = await LoadAPI.UploadReleaseFile(loadData.id, selectedFile);
      if (result.status !== 200 && result.status !== 201) {
        this.setState({ releaseFileError: null });
        return;
      }

      this.setState({attachedFiles: [...attachedFiles, selectedFile], releaseFileError: null}, () => {
        console.log("selected file = ", fileName, JSON.stringify(attachedFiles));
      });
    }
  }

  removeFile = async (item, i) => {
    const {attachedFiles, loadData} = this.state;
    const removedFile = attachedFiles.splice(i, 1);
    const fileName = removedFile[0].name ? removedFile[0].name : removedFile[0].fileName;

    var result = await LoadAPI.DeleteReleaseFile(loadData.id, fileName);
    if (result.status !== 200) {
      return;
    }
    
    this.setState({attachedFiles: [...attachedFiles]}, () => {
      console.log("removed file = " + fileName);
    });
  }

  downloadFile = (item, i) => {
    const {attachedFiles, loadData} = this.state;
    const linkedFile = attachedFiles[i];
    const fileName = linkedFile.name ? linkedFile.name : linkedFile.fileName

    console.log("linked file = ", fileName);
    LoadsAPI.OpenReleaseFile(loadData.id, fileName);
  }

  render() {
    const {
      cars,
      carsForm,
      error,
      carsSelectOptions,
      modelsSelectOptions,
      carTypesSelectOptions,
      carsFields = {},
      loadData = {},
      pick_up_date,
      delivery_date,
      myDrivers = [],
      myDriver,
      enclose,
      originIsClient,
      destinationIsClient,
      selectedDriver,
      validateErrorFields,
      carValidateErrorFields,
      selectedCar,
      selectedBillingType,
      carsFormError,
      createLoadBtnSpinner,
      isOpenSubscriptionModal,
      subscriptionStatus,
      isLoading,
      attachedFiles,
      releaseFileError
    } = this.state;

    const {makerOption, modelOption, carTypesOption} = carsFields;
    const {origin_data = {}, destination_data = {}} = loadData
    const pickUpDate = (loadData.pick_up_date + '').split(' ')
    const pickUpDateDivided = pickUpDate[0].split('-')
    const deliveryDate = (loadData.delivery_date + '').split(' ')
    const deliveryDateDivided = deliveryDate[0].split('-')
    const loadStatus = LoadAPI.CalculateLoadStatus(loadData);

    const first_name = selectedDriver ? JSON.parse(selectedDriver).first_name : ''
    const last_name = selectedDriver ? JSON.parse(selectedDriver).last_name : ''

    const myDriversFiltered = myDrivers.filter((item)=> item.id !== selectedDriver.id)

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <PopupSubscription popupOpen={isOpenSubscriptionModal} />
            <SubscriptionContext.Provider value={subscriptionStatus}>
              <CustomSideBar page={'loads'}/>
            </SubscriptionContext.Provider>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Load</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item"><Link to='/loads'>Loads</Link></li>
                      <li className="breadcrumb-item active">Edit load</li>
                    </ol>
                  </div>
                  {/*<div className="main-content-header-right">*/}
                  {/*<button className="btn custom-btn custom-btn-upload">Upload Load Company1</button>*/}
                  {/*<button className="btn custom-btn custom-btn-upload">Upload Load Company2</button>*/}
                  {/*</div>*/}
                </div>

                <div className="dashboard-content">

                  <div className="load">
                    <div className="load-back">
                      <Link to='/loads'><i className="fas fa-arrow-left"/> Back</Link>
                    </div>

                    <AvForm onSubmit={this.submitLoad}>

                      <div className="row">
                        <div className="col-12">
                          <AvField
                            name="loadNumber"
                            label="Load number"
                            required
                            errorMessage="Invalid load number"
                            type="text"
                            value={loadData.load_id}
                            maxLength="100"
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Pick up date</label>

                            <DatePicker
                              className={validateErrorFields.pick_up_date ? 'form-control form-control-error' : 'form-control'}
                              selected={pick_up_date || ''}
                              onChange={this.handlePickupDate}
                              dateFormat="yyyy-MM-dd"
                              minDate={new Date()}
                              placeholderText={`${pickUpDateDivided[0] || ''}-${pickUpDateDivided[1] || ''}-${pickUpDateDivided[2] || ''}`}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Delivery date</label>
                            <DatePicker
                              className={validateErrorFields.delivery_date ? 'form-control form-control-error' : 'form-control'}
                              selected={delivery_date || ''}
                              onChange={this.handleDeliveryDate}
                              dateFormat="yyyy-MM-dd"
                              minDate={pick_up_date || new Date()}
                              placeholderText={`${deliveryDateDivided[0] || ''}-${deliveryDateDivided[1] || ''}-${deliveryDateDivided[2] || ''}`}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Status</label>
                            <div style={{ width: 90 }}>
                              {loadStatus === "new" && (<div className="people-table-status-active">New</div>)}
                              {loadStatus === "assigned" && (<div className="people-table-status-alert">Assigned</div>)}
                              {loadStatus === "picked" && (<div className="people-table-status-picked">Picked</div>)}
                              {loadStatus === "dispatched" && (<div className="people-table-status-dispatched">Dispatched</div>)}
                              {loadStatus === "outdated" && (<div className="people-table-status-outdated">Outdated</div>)}
                              {loadStatus === "unknown" && (<div className="people-table-status-unknown">Unknown</div>)}
                            </div>
                          </div>
                        </div>

                        <div className="col-12">

                          {loadData.driver_data === null && loadData.driver_id === null && (
                            <AvField
                              type="select"
                              name="select"
                              label='Driver'
                              className="load-select-drivers"
                              onChange={(e) => this.setState({selectedDriver: e.target.value})}>
                              <option selected="selected"/>
                              {myDrivers.map((item) => {
                                return <option value={JSON.stringify(item)}>{item.first_name || ''} {item.last_name || ''}</option>
                              })}
                            </AvField>
                          )}

                          {loadData.driver_data !== null && loadData.driver_id !== null && (
                            <AvField
                              type="select"
                              name="select"
                              label='Driver'
                              disabled={true}>
                              <option>{myDriver.first_name} {myDriver.last_name}</option>
                            </AvField>
                          )}

                          {loadData.driver_data !== null && loadData.driver_id === null && (
                            <AvField
                              type="select"
                              name="select"
                              label='Driver'
                              onChange={(e) => this.setState({selectedDriver: e.target.value})}>
                              <option>{first_name} {last_name}</option>
                              {myDriversFiltered.map((item) => {
                                return <option value={JSON.stringify(item)}>{item.first_name || ''} {item.last_name || ''}</option>
                              })}
                            </AvField>
                          )}

                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Driver phone</label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength="100"
                              readOnly={true}
                              value={selectedDriver ? JSON.parse(selectedDriver).phone : ''}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="driverPrice"
                            label="Price ($)"
                            maxLength="100"
                            errorMessage="Invalid price"
                            value={loadData.hauling}
                            type="number"/>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Cars to transport</label>
                            <div className="load-new-model">
                              <div className="load-new-model-header">

                                {cars.map((item, i) => {
                                  return (
                                    <div
                                      key={uuidv4()}
                                      className={selectedCar === i ?
                                        "load-new-model-header-item load-new-model-header-item-active" : "load-new-model-header-item"}>
                                      <div onClick={() => this.selectCar(item, i)}>
                                        {item.makerOption ? item.makerOption.label : item.car_maker_name}
                                      </div>
                                      <span onClick={() => this.deleteCar(item.id, i)}/>
                                    </div>
                                  )
                                })}
                                <div
                                  className="load-new-model-add btn custom-btn"
                                  onClick={this.showCarsForm}
                                >Add new car
                                </div>
                              </div>
                              <div className={carsForm ? "load-new-model-form load-new-model-form-active" : "load-new-model-form "}>
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Make*</label>
                                      <div className="select-wrap">
                                        <Select
                                          className={carValidateErrorFields.carMaker ? 'custom-select-error-wrap form-control-error' : ''}
                                          value={makerOption}
                                          // onChange={this.changeMakerOption}
                                          onInputChange={this.onInputChangeMake}
                                          onChange={this.onChangeMake}
                                          options={carsSelectOptions}
                                          maxLength="100"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Model*</label>
                                      <div className="select-wrap">
                                        <Select
                                          className={carValidateErrorFields.model ? 'custom-select-error-wrap form-control-error' : ''}
                                          value={modelOption}
                                          // onChange={this.changeModelOption}
                                          onInputChange={this.onInputChangeModel}
                                          onChange={this.onChangeModel}
                                          options={modelsSelectOptions}
                                          maxLength="100"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Vin
                                        <span className="custom-info" id="TooltipTop1">i</span>
                                        <Tooltip
                                          placement="top"
                                          isOpen={this.state.tooltip_top}
                                          target="TooltipTop1" toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                                          Enter full VIN or last 8 symbols.
                                          VIN can only consist of numbers and letters.
                                          You can't have 2 or more cars with the same VIN in your load
                                        </Tooltip>
                                      </label>
                                      <input
                                        type="text"
                                        className={carValidateErrorFields.vin || !carsFields.vin ? "form-control form-control-select-error" : "form-control"}
                                        value={carsFields.vin}
                                        maxLength="100"
                                        name="vin"
                                        placeholder="Enter valid VIN number to decode vehicle information..."
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Mileage</label>
                                      <input
                                        className="form-control"
                                        value={carsFields.mileage}
                                        type="number"
                                        name="mileage"
                                        required
                                        maxLength="100"
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Year</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="year"
                                        maxLength="4"
                                        value={carsFields.year}
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Keys</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="keys"
                                        required
                                        maxLength="100"
                                        value={carsFields.keys}
                                        onChange={(e) => this.carFieldsHandler(e)}/>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label htmlFor="">Type</label>

                                      <div className="select-wrap">
                                        <Select
                                          value={carTypesOption}
                                          onChange={this.changeCarTypes}
                                          options={carTypesSelectOptions}
                                          maxLength="100"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="load-new-model-checkbox-line">
                                      <div className="custom-control custom-checkbox">
                                        <span className="custom-checkbox-title">INOP</span>
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          name="is_inop"
                                          onChange={(e) => this.carFieldsHandler(e)}
                                          checked={carsFields.is_inop !== 0}
                                          value={carsFields.is_inop}
                                          id="load-checkbox-1"/>
                                        <label className="custom-control-label" htmlFor="load-checkbox-1"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="load-new-model-form-footer">
                                  {selectedCar === null ?
                                    <div
                                      className="btn custom-btn"
                                      onClick={this.createCar}>
                                      Submit
                                    </div>
                                    :
                                    <div
                                      className="btn custom-btn"
                                      onClick={this.updateCar}>
                                      Update
                                    </div>
                                  }
                                  <p className="load-new-model-form-info">
                                    Please press submit after you fill car fields
                                  </p>
                                  {carsFormError && (
                                    <span className="errorMsg" style={{textAlign: 'left'}}>
                                      You need to attach car before save load
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Enclose only</label>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="enclose"
                                value={enclose}
                                checked={enclose !== 0}
                                onChange={(e) => this.encloseHandler(e)}
                                id="load-checkbox-2"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-2"/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12"><hr/></div>
                                  
                        <div className="col-12">
                          <ReleaseFiles attachedFiles={attachedFiles} addFile={this.addFile} downloadFile={this.downloadFile} removeFile={this.removeFile} />
                          {
                            releaseFileError ? (
                                <span className="errorMsg" style={{textAlign: 'right'}}>{releaseFileError}</span>
                            ) : null
                          }
                          <div className="noteitem" style={{ paddingLeft: 172 }}>Maximum allowed file size is { MAX_FILE_SIZE_MB }Mb.</div>
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Client</label>
                            <div className="select-wrap">
                              <Select
                                key={`client_select_key__${this.state.clientSelectedValue}`}
                                isDisabled={this.state.originIsClient || this.state.destinationIsClient}
                                closeOnSelect={false}
                                options={this.state.optionsSelect}
                                value={this.state.clientSelectedValue}
                                removeSelected={true}
                                readOnly
                                multi={false}
                                autosize={true}
                                isClearable
                                className={''}
                                isLoading={this.state.isLoading}
                                onSelectResetsInput={true}
                                onChange={(e) => this.onChange(e, 'client')}
                                placeholder="Type the client's name to appear the selection list..."
                                onInputChange={(e) => this.onInputChange(e, 'client')}/>
                              <div className="noteitem">This client's information will appear on BOL receipt and invoice if any selected.</div>
                            </div>

                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="comment"
                            label="Comment"
                            errorMessage="Invalid comment"
                            type="textarea"
                            rows="6"
                            value={this.state.loadData.note || ''}
                            maxLength={"3777"}
                          />
                        </div>

                        <div className="col-12"><hr/></div>


                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Origin</label>
                            <div className="select-wrap">
                              <Select
                                closeOnSelect={false}
                                options={this.state.optionsSelect}
                                value={this.state.originSelectedValue || ''}
                                removeSelected={true}
                                multi={false}
                                autosize={true}
                                clearable={true}
                                isLoading={this.state.isLoading}
                                onSelectResetsInput={true}
                                onChange={(e) => this.onChange(e, 'origin')}
                                onInputChange={(e) => this.onInputChange(e, 'origin')}/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            label=" "
                            name="originAddress"
                            required
                            errorMessage="Invalid origin address"
                            maxLength="100"
                            placeholder="address"
                            type="text"
                            value={this.state.originSelectedValue.label !== origin_data.name
                              ? this.state.originSelectedValue.data.address : origin_data.address}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originEmail"
                            label="Origin Email"
                            errorMessage="Invalid origin email"
                            type="email"
                            value={this.state.originSelectedValue.label !== origin_data.name
                              ? this.state.originSelectedValue.data.email : origin_data.email}
                            maxLength="100"
                          />
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originPhone"
                            label="Origin phone number"
                            errorMessage="Invalid phone"
                            type="_tel"
                            value={this.state.originSelectedValue.label !== origin_data.name
                              ? this.state.originSelectedValue.data.phone : origin_data.phone}
                            maxLength="100"
                          />
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Is a Client</label>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="originIsClient"
                                value={originIsClient}
                                checked={originIsClient !== 0}
                                onChange={(e) => this.asOiginHandler(e)}
                                id="load-checkbox-as-origin"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-as-origin"/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="instructionsDispatch"
                            label="Dispatch Instructions"
                            errorMessage="Invalid dispatch instructions"
                            type="textarea"
                            rows="6"
                            value={this.state.loadData.note_dispatch || ''}
                            maxLength={"3777"}
                          />
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Destination</label>
                            <div className="select-wrap">
                              <Select
                                closeOnSelect={false}
                                options={this.state.optionsSelect}
                                value={this.state.destinationSelectedValue || ''}
                                removeSelected={true}
                                multi={false}
                                autosize={true}
                                clearable={true}
                                isLoading={this.state.isLoading}
                                onSelectResetsInput={true}
                                onChange={(e) => this.onChange(e, 'destination')}
                                onInputChange={(e) => this.onInputChange(e, 'destination')}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            label=" "
                            name="destinationAddress"
                            required
                            errorMessage="Invalid destination address"
                            maxLength="100"
                            placeholder="address"
                            type="text"
                            value={destination_data === null ? "" : (this.state.destinationSelectedValue.label !== destination_data.name
                              ? this.state.destinationSelectedValue.data.address : destination_data.address)}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationEmail"
                            label="Destination Email"
                            errorMessage="Invalid destination email"
                            value={destination_data === null ? "" : (this.state.destinationSelectedValue.label !== destination_data.name
                              ? this.state.destinationSelectedValue.data.email : destination_data.email)}
                            maxLength="100"
                            type="email"/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationPhone"
                            label="Destination phone number"
                            className="destinationPhone"
                            errorMessage="Invalid destination phone number"
                            value={destination_data === null ? "" : (this.state.destinationSelectedValue.label !== destination_data.name
                              ? this.state.destinationSelectedValue.data.phone : destination_data.phone)}
                            maxLength="100"
                            type="_tel"/>
                        </div>

                        <div className="col-12">

                          <div className="form-group">
                            <label htmlFor="">Is a Client</label>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="destinationIsClient"
                                value={destinationIsClient}
                                checked={destinationIsClient !== 0}
                                onChange={(e) => this.asDestinationHandler(e)}
                                id="load-checkbox-as-destination"/>
                              <label className="custom-control-label" htmlFor="load-checkbox-as-destination"/>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="instructionsDelivery"
                            label="Delivery Instructions"
                            errorMessage="Invalid delivery instructions"
                            type="textarea"
                            value={this.state.loadData.note_release || ''}
                            maxLength={"3777"}
                            rows="6"
                          />
                        </div>

                        <div className="col-12"><hr/></div>

                        <div className="col-12">
                          <AvField
                            type="select"
                            name="select"
                            label='Payment Term'
                            className="load-select"
                            value={selectedBillingType}
                            onChange={(e) => this.setState({selectedBillingType: e.target.value})}>
                            <option value="1">COD</option>
                            <option value="3">COP</option>
                            <option value="7">Quick Pay</option>
                            <option value="9">Zelle</option>
                            <option value="11">Venmo</option>
                            <option value="13">Cashapp</option>
                            <option value="5">Billing</option>
                          </AvField>
                        </div>


                        {/*<div className="col-12 m-t-40">*/}
                        {/*<AvField*/}
                        {/*name="managerName"*/}
                        {/*label="Manager name"*/}
                        {/*maxLength="100"*/}
                        {/*type="text"/>*/}
                        {/*</div>*/}
                        {/*<div className="col-12">*/}
                        {/*<AvField*/}
                        {/*name="managerPhoneNumber"*/}
                        {/*label="Manager phone number"*/}
                        {/*maxLength="100"*/}
                        {/*type="tel"/>*/}
                        {/*</div>*/}
                        {/*<div className="col-12">*/}
                        {/*<AvField*/}
                        {/*name="managerEmail"*/}
                        {/*label="Manager email"*/}
                        {/*maxLength="100"*/}
                        {/*type="email"/>*/}
                        {/*</div>*/}
                      </div>

                      <div className="row">
                        {
                          error ? (
                            <div className="col-12">
                              <span className="errorMsg" style={{textAlign: 'right'}}>You have uncorrected fields</span>
                            </div>
                          ) : null
                        }
                        <div className="col-12 button-input-pre">
                          <Link to="/loads">
                            <div className="load-create-cancel">Cancel</div>
                          </Link>
                          {createLoadBtnSpinner && (<div className="custom-spinner" role="status"/>)}
                          <Button
                            className={isLoading ? "btn btn-primary w-md custom-btn custom-btn-blocked" : "btn btn-primary w-md custom-btn"}
                            disabled={createLoadBtnSpinner || isLoading}
                            type="submit">Update load
                          </Button>
                        </div>
                      </div>
                    </AvForm>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Load);
