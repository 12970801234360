import parser from 'parse-address';

export const formatAddress = (addressString) => {
  if (!addressString) {
    return '-';
  }

  var addressParsed = parser.parseLocation(addressString);

  if (!addressParsed || !addressParsed.city) {
    let indexOfFirstPart = addressString.indexOf(',');
    addressString = addressString.substring(indexOfFirstPart + 1, addressString.length).trim();
    addressParsed = indexOfFirstPart ? parser.parseLocation(addressString) : null;
  }

  return addressParsed && (addressParsed.city || addressParsed.state) ? [addressParsed.city, addressParsed.state + " " + addressParsed.zip].join(', ') : addressString;
}

export const parseAddress = (addressString) => {
  if (!addressString) {
    return '';
  }
  
  var addressParsed = parser.parseLocation(addressString);
  if (!addressParsed || !addressParsed.city) {
    addressParsed = null
  }

  console.warn("addressParsed = ", addressParsed)

  return addressParsed ? { address: (addressString.replace(addressParsed.state, "").replace(addressParsed.zip, "").replace(addressParsed.city, "").replaceAll(",", "")).trim(),  state: addressParsed.state, zip: addressParsed.zip, city: addressParsed.city } : { address: addressString,  state: '', zip: '', city: '' }
}

export default formatAddress;