import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';

import {Tooltip} from "reactstrap";
import uuidv4 from "uuid";
import {bindActionCreators} from "redux";
import {getDashboardData} from "../MainPage/dashboardAction";
import './style.css'

class TopClientsStat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip_top: false
    };
  }

  render() {

    const {dashboardData = {}} = this.props
    const {top10Client = []} = dashboardData
    
    return (
      <AUX>
        <div className="card">
          <div className="card-body custom-card-body">
            <h5 className="mt-0">TOP 10 clients
              {this.props.refreshing ? 
                <span style={{ marginLeft: '3px', marginRight: '3px' }}><i className="fa fa-spinner fa-spin"/></span>
               : null}
              <span className="custom-info" id="TooltipTop3">i</span>
              <Tooltip placement="top" isOpen={this.state.tooltip_top} target="TooltipTop3"
                       toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                This widget shows the top 10 clients which means the customers where your drivers delivered cars.
                In the columns, you see how many cars you have delivered to this specific client and what is the gross
                amount of all delivered loads.
              </Tooltip></h5>

            <table className="table table-top-clients table-hover mb-0">
              <thead>
              <tr>
                <th/>
                <th>Name</th>
                <th>Amount of <br/> transported cars</th>
                <th>$</th>
              </tr>
              </thead>
              <tbody>
              {top10Client.map((item) => (
                <tr key={uuidv4()}>
                  <td><img src={item.logo || './assets/images/default-user.jpg'} alt="user avatar"/></td>
                  <td><div>{item.name}</div><a href={`/load/${item.id}/client`}>Create Load</a></td>
                  <td>{item.count}</td>
                  <td>${item.sum}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
  };
}


const mapDispatchToProps = dipatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
    },
    dipatch
  ),
});

export default connect(mapStatetoProps, mapDispatchToProps)(TopClientsStat);
