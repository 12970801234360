import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import Map from "../Map";
import LoadsAPI from "../../../api/loadsAPI";
import TransportersAPI from "../../../api/transportersAPI";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import "./style.css";
import OpportunitiesAPI from "../../../api/opportunitiesAPI";
import DocumentsApi from "../../../api/documentsApi";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import SubscriptionContext from "../../../components/Layout/SubscriptionContext";
import { getBackgroundCheckStatus } from '../../../helpers/BackgroundCheck';
import LocationSelect from '../LocationSelect/LocationSelect';
import LoadViewSelector from "../LoadViewSelector/LoadViewSelector";
import LoadCardsContainer from "../LoadCardsContainer/LoadCardsContainer";
import Spinner from "../Spinner/Spinner";

const DEFAULT_PICKUP_DISTANCE = 50;
const DEFAULT_DELIVERY_DISTANCE = 100;
const PAGE_SIZE = 20;

const SELECT_DRIVER_HINT = " Select Driver or Type City, State ";

class Loadboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loads: [],
      page: 1,
      initialPage: 0,
      limit: PAGE_SIZE,
      total: 0,
      status: "active",
      popupOpen: false,
      delayTimer: "",
      commentMode: null,
      commentText: "",
      priority: null,
      transporters: [],
      drivers: [],
      pickupLocations: [],
      deliveryLocations: [],
      defaultSelectValue: { label: SELECT_DRIVER_HINT, value: "" },
      pickupLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''},
      deliveryLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''},
      isLoading: false,
      files: [],
      subscriptionStatus: '',
      pickupDistance: DEFAULT_PICKUP_DISTANCE,
      deliveryDistance: DEFAULT_DELIVERY_DISTANCE,
      pickup_driver_id: null,
      pickup_place_id: null,
      delivery_driver_id: null,
      delivery_place_id: null,
      backgroundCheckStatus: null,
      listViewModeOn: true,
    };
    this.setLoading = this.setLoading.bind(this);
    this.mapRef = React.createRef();
  }

  async getLoadCards(load) {
    const data = await LoadsAPI.GetLoadsCars(load.id)
    const resp = data.data.data
    let carDataText = []
    resp.forEach(el => {
      carDataText.push(el);
    })
    return carDataText;
  }

  getLoads = async () => {
    this.handlePageChangeEndless();
  };

  getLoadsWithFilter = async page => {
    const { limit, status, priority, pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, deliveryDistance, pickupDistance } = this.state;

    const data = await this.getOpportunitiesData(limit, status, priority, deliveryDistance, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id }, page);

    // Filter out data with offers where confirmed < 0
    const filteredData = data.data.data.filter((load) => {
      if (load.offerData === null || (load.offerData && load.offerData.confirmed >= 0)) {
        return true;
      }
      // TODO filter out the rejected offers ???
      // data.data.data_total_count = data.data.data_total_count - 1;
      // return false;
      return true;
    });
    data.data.data = filteredData;

    return data;
  };

  async componentDidMount() {
    const backgroundCheckStatus = await getBackgroundCheckStatus();
    this.setState({backgroundCheckStatus});

    const subscriptionData = await SubscriptionAPI.GetSubscribeData();
    if (subscriptionData.data.status === 500 && subscriptionData.data.data) {
      this.setState({
        subscriptionStatus: subscriptionData.data.data.status,
      });
    }

    this.getLoads();
    const data = await TransportersAPI.GetTransportersStatWithLoad();
    const { data: allDrivers } = await TransportersAPI.GetMyTransportersData();
    const {
      data: { files }
    } = await DocumentsApi.list();
    const transportersStatusFilter = data.data.data.filter(
      item => item.status !== "invited"
    );
    const transporters = transportersStatusFilter.map(item => {
      return { value: item.id, label: `${item.name}`, id: item.id };
    });
    const drivers = allDrivers.data.map(el => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.id
    }));
    // transporters.unshift({ label: SELECT_DRIVER_HINT, value: "" });

    const options = [
      {
        // label: 'Clear',
        options: [
          { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
        ],
      },
      {
        label: 'Drivers',
        options: [
          ...transporters
        ],
      }
    ];

    this.setState({ transporters, drivers, files, pickupLocations: options, deliveryLocations: options });
  }

  handlePageChangeEndless = async () => {
    const { loads, page } = this.state;
    this.setState({ isPaging: true });
    var currentPage = page;
    var hasMore = false;


    try {
      do {
      const data = await this.getLoadsWithFilter(currentPage);

      hasMore = data.data.data_has_more && data.data.data_has_more === true;

      if (data.data.data.length > 0) {
        const newLoads = data.data.data;
        
        this.setState({
          page: currentPage + 1,
          loads: currentPage === 1 ? newLoads : [...loads, ...newLoads], // Only append unique loads
          hasMore
        });
        
      }
      currentPage++;
      } while (this.state.loads.length < PAGE_SIZE && hasMore);
    } finally {
      this.setState({ isPaging: false });
    }
  };
  
  openPopup = id => {
    this.setState({ popupOpen: true, popupItemId: id });
  };

  mapFetchData = async (pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, rush, pickupDistance, deliveryDistance) => {
    const noParams = !pickup_driver_id && !pickup_place_id && !delivery_driver_id && !delivery_place_id && !rush && !pickupDistance && !deliveryDistance;
    const retryLimit = 0; // seconds
    const retryInterval = 1000; // retry every second
    let totalWaitTime = 0;
  
    const attemptFetch = async () => {
      try {
        // console.warn("Attempting map fetch", this.mapRef, this.mapRef.current);
        if (this.mapRef && this.mapRef.current && this.mapRef.current.fetchData) {
          return noParams ? this.mapRef.current.fetchDataDefault() : this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, rush, pickupDistance, deliveryDistance);
        } else {
          throw new Error("Map reference or fetchData function not available.");
        }
      } catch (error) {
        // console.error("[mapFetchData] Fetch error:", error);
        if (totalWaitTime < retryLimit * 1000) {
          totalWaitTime += retryInterval;
          await new Promise(resolve => setTimeout(resolve, retryInterval));
          return attemptFetch(); // Retry
        } else {
          // console.error("[mapFetchData] Max retry limit reached.");
        }
      }
    };
  
    return attemptFetch();
  };
    

  handleRush = async e => {
    const {
      target: { checked }
    } = e;
    const { pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, deliveryDistance, pickupDistance } = this.state;
    this.setState({priority: checked ? "rush" : null, isLoading: true});
    this.setState({initialPage: 0, page: 1, isLoading: false, loads: []}, async () => {
      Promise.all([this.handlePageChangeEndless(), this.mapFetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, checked, pickupDistance, deliveryDistance)]);
    });
  };

  onPickupLocationChange = async (selectedLocation) => {
    const { deliveryDistance, pickupDistance, priority, delivery_driver_id, delivery_place_id } = this.state;
    const driver = (selectedLocation && selectedLocation.id && !isNaN(selectedLocation.id)) ? selectedLocation : { id: null };
    const place = (selectedLocation && selectedLocation.place_id && selectedLocation.place_id.trim() !== "") ? selectedLocation : { place_id: null };
    this.setState({initialPage: 0, page: 1, isLoading: false, loads: [], pickup_driver_id: driver.id || null, pickup_place_id: place.place_id || null, pickupLocationSelectedValue: selectedLocation}, async () => {
      Promise.all([this.handlePageChangeEndless(), this.mapFetchData(driver.id || null, delivery_driver_id, place.place_id || null, delivery_place_id, priority === "rush", pickupDistance, deliveryDistance)]);
    });
  }

  onDeliveryLocationChange = async (selectedLocation) => {
    const { deliveryDistance, pickupDistance, priority, pickup_driver_id, pickup_place_id } = this.state;
    const driver = (selectedLocation && selectedLocation.id && !isNaN(selectedLocation.id)) ? selectedLocation : { id: null };
    const place = (selectedLocation && selectedLocation.place_id && selectedLocation.place_id.trim() !== "") ? selectedLocation : { place_id: null };
    this.setState({initialPage: 0, page: 1, isLoading: false, loads: [], delivery_driver_id: driver.id || null, delivery_place_id: place.place_id || null, deliveryLocationSelectedValue: selectedLocation}, async () => {
      Promise.all([this.handlePageChangeEndless(), this.mapFetchData(pickup_driver_id, driver.id || null, pickup_place_id, place.place_id || null, priority === "rush", pickupDistance, deliveryDistance)]);
    });
  }

  onPickupDistanceChange = async (distance) => {
    const { pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, deliveryDistance, priority } = this.state;
    
    if (pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '')) {
      this.setState({pickupDistance: distance, isLoading: true});
      if (distance === null || distance === '' || isNaN(distance)) {
      } else {
        this.setState({initialPage: 0, page: 1, isLoading: false, loads: []}, async () => {
          Promise.all([this.handlePageChangeEndless(), this.mapFetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", distance, deliveryDistance)]);
        });
      }
    } else {
      this.setState({pickupDistance: distance});
    }
  }

  onDeliveryDistanceChange = async (distance) => {
    const { pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, pickupDistance, priority } = this.state;

    if (delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '')) { // filtering by delivery driver or place
      this.setState({deliveryDistance: distance, isLoading: true});
      if (distance === null || distance === '' || isNaN(distance)) {
      } else {
        this.setState({initialPage: 0, page: 1, isLoading: false, loads: []}, async () => {
          Promise.all([this.handlePageChangeEndless(), this.mapFetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", pickupDistance, distance)]);
        });
      }
    } else {
      this.setState({deliveryDistance: distance});
    }
  }

  handleLocation = async selectedLocation => {
    this.onPickupLocationChange(selectedLocation);
  };


  changePickupFilterCriteria = input => {
    if (input & input.trim() === "") {
      return;
    }

    OpportunitiesAPI.getPlaces(input).then((responseData) => {
      const places = responseData.data.map(item => ({ label: item.description, value: item.description, place_id: item.place_id }));

      const options = [
        {
          // label: 'Clear',
          options: [
            { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
          ],
        },
        {
          label: 'Drivers',
          options: [
            ...this.state.transporters
          ],
        },
        {
          label: 'Places',
          options: [
            ...places 
          ],
        },
      ];
      
      this.setState({pickupLocations: options});
    });
  };

  changeDeliveryFilterCriteria = input => {
    if (input & input.trim() === "") {
      return;
    }

    OpportunitiesAPI.getPlaces(input).then((responseData) => {
      const places = responseData.data.map(item => ({ label: item.description, value: item.description, place_id: item.place_id }));

      const options = [
        {
          // label: 'Clear',
          options: [
            { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
          ],
        },
        {
          label: 'Drivers',
          options: [
            ...this.state.transporters
          ],
        },
        {
          label: 'Places',
          options: [
            ...places 
          ],
        },
      ];
      
      this.setState({deliveryLocations: options});
    });
  };


  GetOfferDataEndless = async (parameters) => {
    let parametersOverridden = {...parameters, endless_mode: true, active: false, limit: 300};

    let response = await OpportunitiesAPI.GetOffersData(parametersOverridden);
    let data = response.data && response.data.data ? response.data.data : [];

    if (data.length === 0 && data.data_has_more) {
      await this.setState(prevState => ({ page: prevState.page + 1 }));
      parametersOverridden = {...parameters, page: this.state.page + 1};
      return this.GetOfferDataEndless(parametersOverridden);
    }

    return response;
  }

  // TODO selectedDriver and selectedPlace has to be replaced with specific pickup and delivery locations
  getOpportunitiesData = async (limit, status, priority, deliveryDistance, pickupDistance, selectedPickupDriver, selectedPickupPlace, selectedDeliveryDriver, selectedDeliveryPlace, page = 1) => {

    const data = await this.GetOfferDataEndless({
      page,
      limit,
      status,
      endless_mode: true,
      ...(priority ? { priority: "rush" } : {}),
      ...(selectedPickupDriver.id ? { pickup_driver_id: selectedPickupDriver.id } : {}),
      ...(selectedPickupPlace.place_id ? { pickup_place_id: selectedPickupPlace.place_id } : {}),
      ...(selectedDeliveryDriver.id ? { delivery_driver_id: selectedDeliveryDriver.id } : {}),
      ...(selectedDeliveryPlace.place_id ? { delivery_place_id: selectedDeliveryPlace.place_id } : {}),
      ...(selectedPickupDriver.id || selectedPickupPlace.place_id || selectedDeliveryDriver.id || selectedDeliveryPlace.place_id ? { pickup_distance: pickupDistance, delivery_distance: deliveryDistance } : {})
    });

    await Promise.all(data.data.data.map(async (load) => {
      load.carData = await this.getLoadCards(load);
      load.offerData = await OpportunitiesAPI.getOffer(load.id);

      if (load.offerData.data && load.offerData.data.length > 0) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        load.offerData = load.offerData.data.find((offer) => (offer.author_id === userInfo.id || offer.dispatcher_id === userInfo.id) && offer.confirmed >= 0);

        // TODO if declined, count how many total declines, if more than 3, don't show the load, if less - do not return the offer
        // load.offerData = null;

      } else {
        load.offerData = null;
      }
    }));

    return data;
  }

  setLoading = value => {
    this.setState({ isLoading: value });
  };

  scrollingComponent = () => {
    return (
      <div style={{minHeight: '100%', textAlign: 'center', padding: '12px', color: 'grey' }}>
                    <button
                      style={{ fontSize: '20px', marginBlockEnd: '5px', textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
                      onClick={this.handlePageChangeEndless}
                      onMouseOver={(e) => e.target.style.textDecoration = 'none'}
                      onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                    >
                      Click here or scroll down to load more
                    </button>
                    <div className="jump-animation">
                      <i className="ti ti-angle-double-down" style={{ fontSize: '32px', fontSmooth: true }}/>
                    </div>
                  </div>
    )
  }

  loadingMoreComponent = () => {
    return (
      <div style={{minHeight: '100%', textAlign: 'center', padding: '12px', color: 'grey' }}>
                    <p style={{ fontSize: '20px', marginBlockEnd: '5px' }}>
                        Loading offers...
                      </p>
                    <Spinner useBackgroundSpinner={true} />
                  </div> 
    )
  }

  render() {
    const {
      loads,
      limit,
      total,
      popupOpen,
      pickupLocations,
      deliveryLocations,
      priority,
      pickup_driver_id,
      pickup_place_id,
      delivery_driver_id,
      delivery_place_id,
      subscriptionStatus,
      pickupDistance,
      deliveryDistance,
      listViewModeOn
    } = this.state;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <SubscriptionContext.Provider value={subscriptionStatus}>
              <CustomSideBar page={"loadboard"} />
            </SubscriptionContext.Provider>
            <CustomTopBar />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">
                      Loadboard
                    </div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Loadboard</li>
                    </ol>
                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="board-table">
                    <div className="board-table-header">
                      <ul className="load-table-header header-first">
                        <li style={{ marginLeft: '15px' }}>
                          <div className="board-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li style={{ minWidth: '114px' }}>
                                <input
                                  className={"filter_checkbox"}
                                  defaultChecked={priority === "rush"}
                                  ref="rush"
                                  type={"checkbox"}
                                  onChange={this.handleRush}
                                />{" "}
                                Rush only
                                <img src="assets/images/rush.svg" alt="" />
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li style={{ /*width: '100%',*/ flexGrow: 1 }}>
                          <div className="board-table-header-filter" style={{ width: '100%' }}>
                            <ul className="load-table-header header-second" style={{ width: '100%' }}>
                              <li style={{ width: '100%' }} >
                                <LocationSelect
                                  imagePath="assets/images/pickup.svg"
                                  locationOptions={pickupLocations}
                                  selectionHint={SELECT_DRIVER_HINT}
                                  selectedDistance={this.state.pickupDistance}
                                  onLocationChange={this.onPickupLocationChange}
                                  onChangeLocationFilterCriteria={this.changePickupFilterCriteria}
                                  onDistanceChange={this.onPickupDistanceChange}
                                  selectedLocation={this.state.pickupLocationSelectedValue}
                                />
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li style={{ height: '38px', display: 'flex', alignItems: 'center', verticalAlign: 'center'}}>
                          <div className="board-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li style={{ minWidth: '24px' }} >
                                <strong onClick={() =>
                                  this.setState({
                                    pickupLocationSelectedValue: this.state.deliveryLocationSelectedValue, 
                                    deliveryLocationSelectedValue: this.state.pickupLocationSelectedValue,
                                    pickup_driver_id: this.state.delivery_driver_id,
                                    pickup_place_id: this.state.delivery_place_id,
                                    delivery_driver_id: this.state.pickup_driver_id,
                                    delivery_place_id: this.state.pickup_place_id,
                                    pickupDistance: this.state.deliveryDistance,
                                    deliveryDistance: this.state.pickupDistance,
                                    initialPage: 0, page: 1, 
                                    // isLoading: true, 
                                    loads: [],
                                  })
                                } className="link_direction">
                                  {/* <i className="mdi mdi-arrow-left-right-bold-outline" style={{ fontSize: '24px' }}/> */}
                                  <img src="assets/images/switch.svg" alt="" />
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li style={{ /*width: '100%', */flexGrow: 1 }}>
                          <div className="board-table-header-filter" style={{ width: '100%' }}>
                            <ul className="load-table-header header-second" style={{ width: '100%' }}>
                              <li style={{ width: '100%' }}>
                                <LocationSelect
                                  imagePath="assets/images/delivery.svg"
                                  locationOptions={deliveryLocations}
                                  selectionHint={SELECT_DRIVER_HINT}
                                  selectedDistance={this.state.deliveryDistance}
                                  onLocationChange={this.onDeliveryLocationChange}
                                  onChangeLocationFilterCriteria={this.changeDeliveryFilterCriteria}
                                  onDistanceChange={this.onDeliveryDistanceChange}
                                  selectedLocation={this.state.deliveryLocationSelectedValue}
                                />
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li style={{ height: '38px', display: 'flex', alignItems: 'center', verticalAlign: 'center'}}>
                          <div className="board-table-header-filter" style={{height: '100%' }}>
                            <ul className="load-table-header header-second"  style={{height: '100%' }}>
                              <li style={{ minWidth: '24px', height: '100%' }} >
                                <LoadViewSelector
                                  mapViewModeOn={false}
                                  onToggleMapMode={(mapModeSelected) => this.setState({ listViewModeOn: !mapModeSelected }, 
                                    async () => {
                                      if (mapModeSelected) {
                                        await this.mapFetchData();
                                      }
                                    })}
                                />
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="board-container" style={{minHeight: '100%' }}>
                    {!listViewModeOn && (
                      <div className="loads-map board-right-column" style={{minHeight: '100%' }}>
                        <Map internalId={"offerMap"} ref={this.mapRef} 
                          pickupDriverId={pickup_driver_id} 
                          pickupPlaceId={pickup_place_id} 
                          deliveryDriverId={delivery_driver_id} 
                          deliveryPlaceId={delivery_place_id} 
                          rushOnly={priority === "rush"} 
                          pickupDistance={pickupDistance} 
                          deliveryDistance={deliveryDistance} 
                          showLoads={false} 
                          showOffers={true} 
                          showOpportunities={true} 
                        />
                      </div>
                    )}
                  </div>

                  <div className="board-container-scale">
                  {listViewModeOn && (
                    <LoadCardsContainer
                      loads={loads}
                      total={total}
                      limit={limit}
                      openPopup={this.openPopup}
                      setLoading={this.setLoading}
                      pickupDistance={pickupDistance}
                      pickupLocationSelected={this.state.pickupLocationSelectedValue.label}
                      deliveryDistance={deliveryDistance}
                      deliveryLocationSelected={this.state.deliveryLocationSelectedValue.label}
                      listViewModeOn={listViewModeOn}
                      rushOnly={priority === "rush"}
                      onLoadMoreItems={this.handlePageChangeEndless}
                    />
                  )}

                  { this.state.isPaging ? 
                    listViewModeOn && this.loadingMoreComponent()
                  : 
                    listViewModeOn && this.scrollingComponent()
                  }

                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)"
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      Delete/Edit load
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                      This will remove driver from the load or completely delete
                      the load if status is NEW. <br />
                      Proceed?
                    </p>
                    <Button
                      className="btn btn-primary w-md custom-btn"
                      onClick={this.deleteLoad}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn w-md"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </AUX>
    );
  }
}

export default Loadboard;
